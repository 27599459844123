import React from 'react'
import { Form } from 'react-bootstrap'

interface Props {
  id: string
  label: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isChecked: boolean
}

const FormCheckbox: React.FC<Props> = ({ id, label, onChange, isChecked }) => {
  return (
    <Form.Check
      type='checkbox'
      id={id}
      label={label}
      onChange={onChange}
      checked={isChecked}
      className='text-muted fs-6'
    />
  )
}

export default FormCheckbox
