export class NewProject {
  id!: number | undefined
  name!: string
  clientId!: string | undefined

  constructor(project?: any) {
    if (project) {
      Object.assign(this, project)
    }
  }
}
