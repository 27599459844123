import React, { useState, useEffect, useRef } from 'react'
import { useCreateTeamMutation, useGetProjectsQuery } from 'services/queries/api'
import { NewTeam } from 'models/newTeam.model'
import { Link } from 'react-router-dom'
import EmployeeSelectionForm from './components/EmployeeSelectionForm'
import EmployeeCard from './components/EmployeeCard'
import { EmployeeSelectHandler } from './components/EmployeeSelectHandler'
import { Assignment } from 'models/assignment.model'

const CreateTeam: React.FC = () => {
  const { data: projects } = useGetProjectsQuery()
  const [createTeam, { isLoading, isSuccess, isError }] = useCreateTeamMutation()
  const [showMessage, setShowMessage] = useState(true)
  const [teamData, setTeamData] = useState<NewTeam>({ name: '' } as NewTeam)
  const [isEmployeeSelectionOpen, setIsEmployeeSelectionOpen] = useState(false)

  useEffect(() => {
    if (projects) {
      teamData.projectId = projects?.at(0)?.id
      teamData.assignments = []
    }
  }, [projects])

  const handleEmployeeSelect: EmployeeSelectHandler = (employee) => {
    setIsEmployeeSelectionOpen(false)
    setTeamData((prevData) => ({
      ...prevData,
      assignments: [...prevData.assignments, employee],
    }))
  }

  const selectedFunctionRef = useRef<EmployeeSelectHandler>(handleEmployeeSelect)

  const handleDirectorSelect: EmployeeSelectHandler = (employee) => {
    setIsEmployeeSelectionOpen(false)
    setTeamData((prevData) => ({
      ...prevData,
      directorId: employee?.id,
    }))
  }

  const handleEEMSelect: EmployeeSelectHandler = (employee) => {
    setIsEmployeeSelectionOpen(false)
    setTeamData((prevData) => ({
      ...prevData,
      eemId: employee?.id,
    }))
  }

  const handleEngineeringManagerSelect: EmployeeSelectHandler = (employee) => {
    setIsEmployeeSelectionOpen(false)
    setTeamData((prevData) => ({
      ...prevData,
      engineeringManagerId: employee?.id,
    }))
  }

  const handleCDMSelect: EmployeeSelectHandler = (employee) => {
    setIsEmployeeSelectionOpen(false)
    setTeamData((prevData) => ({
      ...prevData,
      cdmId: employee?.id,
    }))
  }

  const handleTeamLeadSelect: EmployeeSelectHandler = (employee) => {
    setIsEmployeeSelectionOpen(false)
    setTeamData((prevData) => ({
      ...prevData,
      teamLeadId: employee?.id,
    }))
  }

  const unsubscribeDirector = () => {
    setTeamData((prevData) => ({
      ...prevData,
      directorId: undefined,
    }))
  }

  const unsubscribeEEM = () => {
    setTeamData((prevData) => ({
      ...prevData,
      eemId: undefined,
    }))
  }

  const unsubscribeCDM = () => {
    setTeamData((prevData) => ({
      ...prevData,
      cdmId: undefined,
    }))
  }

  const unsubscribeEngineeringManager = () => {
    setTeamData((prevData) => ({
      ...prevData,
      engineeringManagerId: undefined,
    }))
  }

  const unsubscribeTeamLead = () => {
    setTeamData((prevData) => ({
      ...prevData,
      teamLeadId: undefined,
    }))
  }

  if (isSuccess || isError) {
    setTimeout(() => {
      setShowMessage(false)
    }, 1500)
  }

  const openSelectEmployee = () => {
    selectedFunctionRef.current = handleEmployeeSelect
    setIsEmployeeSelectionOpen(true)
  }

  const openSelectDirector = () => {
    selectedFunctionRef.current = handleDirectorSelect
    setIsEmployeeSelectionOpen(true)
  }

  const openSelectEEM = () => {
    selectedFunctionRef.current = handleEEMSelect
    setIsEmployeeSelectionOpen(true)
  }

  const openSelectCDM = () => {
    selectedFunctionRef.current = handleCDMSelect
    setIsEmployeeSelectionOpen(true)
  }

  const openSelectEngineeringManager = () => {
    selectedFunctionRef.current = handleEngineeringManagerSelect
    setIsEmployeeSelectionOpen(true)
  }

  const openSelectTeamLead = () => {
    selectedFunctionRef.current = handleTeamLeadSelect
    setIsEmployeeSelectionOpen(true)
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    setTeamData((prevData) => ({
      ...prevData,
    }))

    try {
      await createTeam(teamData).unwrap()
      setTimeout(() => {
        window.location.href = '/teams'
      }, 1500)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (e: any) => {
    const { name } = e.target

    const value = e.target.value
    setTeamData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const closeEmployeeSelection = () => {
    setIsEmployeeSelectionOpen(false)
  }

  const handleEmployeeSelectAssign = (assignment: Assignment) => {
    setIsEmployeeSelectionOpen(false)
  }

  return (
    <>
      {isEmployeeSelectionOpen && (
        <EmployeeSelectionForm
          onEmployeeSelect={handleEmployeeSelectAssign}
          onClose={closeEmployeeSelection}
          selectedEmployees={[
            ...(teamData?.assignments.map((a) => a.id) ?? []),
            ...(teamData?.directorId ? [teamData.directorId] : []),
            ...(teamData?.cdmId ? [teamData.cdmId] : []),
            ...(teamData?.engineeringManagerId ? [teamData.engineeringManagerId] : []),
            ...(teamData?.eemId ? [teamData.eemId] : []),
            ...(teamData?.teamLeadId ? [teamData.teamLeadId] : []),
          ]}
          teamRoleId={5}
          showModal={isEmployeeSelectionOpen}
        />
      )}
      <form onSubmit={handleSubmit}>
        <div className='container'>
          <h4>New Team</h4>
          <div className='col-xl-15'>
            <br />
            <div className='card mb-4'>
              <div className='card-header'>Team details</div>
              <div className='card-body'>
                <div className='mb-2'>
                  <label className='small mb-1 mr-5'>
                    Name: <span className='text-danger'>*</span>
                    <input
                      className='info-field bg-light-gray p-2 pl-3'
                      type='text'
                      name='name'
                      onChange={handleChange}
                      required
                    />
                  </label>
                  <label className='small mb-1 ml-5'>
                    Project: <span className='text-danger'>*</span>
                    <select
                      className='d-block form-select form-select-sm form-hub-select bg-light-gray w-100 p-2 pl-3 pr-4'
                      name='projectId'
                      value={teamData?.projectId}
                      onChange={handleChange}
                      required
                    >
                      {projects &&
                        projects.map((project) => (
                          <option key={project.id} value={project.id}>
                            {project.name}
                          </option>
                        ))}
                    </select>
                  </label>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12 mb-4'>
                <div className='card'>
                  <div className='card-body'>
                    <h5 className='card-title text-center mb-3'>{teamData?.name}</h5>
                    <div className='team-members row justify-content-center'></div>
                    <div className='card-title mt-3'>
                      <h6 className='text-center'>
                        Team Members
                        <button
                          className='btn btn-primary btn-sm d-flex ml-auto'
                          type='button'
                          onClick={openSelectEmployee}
                        >
                          Assign
                        </button>
                      </h6>
                    </div>

                    <table className='table table-striped table-hover custom-table'>
                      <tbody>
                        {teamData?.assignments?.map((asgn) => (
                          <tr key={asgn?.id}>
                            <td>{asgn?.nameWithPosition}</td>
                            <td>
                              <button
                                className='btn btn-danger btn-sm square-button'
                                type='button'
                                onClick={() => {
                                  const updatedItems = teamData?.assignments?.filter(
                                    (item) => item.id !== asgn.id,
                                  )
                                  setTeamData((prevData) => ({
                                    ...prevData,
                                    assignments: updatedItems,
                                  }))
                                }}
                              >
                                <i className='fas fa-times'></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Link
                to='/teams'
                className='btn btn-secondary'
                type='button'
                style={{ marginRight: 20 }}
              >
                Cancel
              </Link>
              <button className='btn btn-primary' type='submit' disabled={isLoading}>
                {isLoading ? 'Creating...' : 'Create'}
              </button>
            </div>
            {isSuccess && showMessage && (
              <div className='alert alert-success mt-3' role='alert'>
                Team has been successfully created!
              </div>
            )}
            {isError && showMessage && (
              <div className='alert alert-danger mt-3' role='alert'>
                Error creating team. Please try again.
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  )
}

export default CreateTeam
