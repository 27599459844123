import React, { useState } from 'react';
import { Employee } from 'models/employee.model'
import {
  formatTShirtSize,
  formatDate,
  formatGender,
  formatAddress,
  formatEmergencyContact,
  formatShoeSize
} from 'utils/formatters'

import './PersonalInformation.scss';

export interface PersonalInformationProps {
  profile: Employee;
}

export function PersonalInformation({ profile }: PersonalInformationProps) {

  return (
    <div className="row">
      <div className="col-12">
        <h4 className='header'>Personal Information</h4>
      </div>
      <div className="col">
        <h5>Gender</h5> {formatGender(profile.gender ? profile.gender : 0)}
        <h5>Date of Birth</h5> {formatDate(profile?.dateOfBirth ?? '')}
        <h5>JMBG</h5> {profile.jmbg}
        <h5>Parent&#39;s name</h5> {profile.nameOfOneParent}
        <h5>Address of residence</h5> {formatAddress(profile?.legalResidenceAddressName ?? '', profile?.legalResidenceAddressCity ?? '', profile?.legalResidenceAddressCountry ?? '')}
        <h5>Current address</h5> {formatAddress(profile?.currentAddressName ?? '', profile?.currentAddressCity ?? '', profile?.currentAddressCountry ?? '')}
      </div>
      <div className="col">
        <h5>Personal Contacts</h5> {profile.personalEmailAddress ?? ''}<br></br>{profile.personalPhoneNumber ?? ''}
        <h5>Emergency Contacts</h5> {formatEmergencyContact(profile?.emergencyContactFullName ?? '', profile?.emergencyContactRelationToEmployee ?? '', profile?.emergencyContactPhoneNumber ?? '')}
        <h5>Education</h5> {profile?.levelOfEducation}
        <h5>Other</h5>
        {profile.tShirtSize !== null && (
          <>
            Shirt size: {formatTShirtSize(profile.tShirtSize ? profile.tShirtSize : 0)}<br />
          </>
        )}
        {profile.shoesSize !== null && (
          <>
            Shoe size: {formatShoeSize(profile.shoesSize ? profile.shoesSize : 0)}<br></br>
          </>
        )}
        {(profile.employeeChildren && profile.employeeChildren.length > 0) && (
          <>
            Has {profile.employeeChildren?.length} children
          </>
        )}
      </div>
    </div>
  )
}
