import PropTypes from 'prop-types'

interface Person {
  name: string
  role: string
  imageUrl: string
}

const PersonCard: React.FC<Person> = ({ name, role, imageUrl }) => {
  return (
    <div className='card align-items-center person-card '>
      <img src={imageUrl} className='person-img' alt='employee' />
      <div className='card-body'>
        <h5 className='card-title'>{name}</h5>
        <p className='card-text'>{role}</p>
      </div>
    </div>
  )
}

PersonCard.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
}

export default PersonCard
