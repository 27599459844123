import { useMsal } from '@azure/msal-react'
import OrganizationalChart from './components/OrgChart';
import { useGetEmployeesNodeInfoQuery } from 'services/queries/api';
import { Entity } from './entity.model';
import { EmployeeNode } from 'models/employeeNode.model';

interface Employee {
    id: string
    name: string
    photo: string
    directReportsIds: string[]
}

function HierarchyOrgChart() {
    const { instance } = useMsal()
    const { data: employees } = useGetEmployeesNodeInfoQuery()

    const entitiesArray: Entity[] = []

    employees?.forEach((emp: EmployeeNode) => {
        const newEntity: Entity =
        {
            id: emp?.externalUserId, parentId: emp?.managerExternalUserId, name: emp?.name, position: emp?.position, type: 'client', photo: emp?.photo, employeeId: emp?.id
        };
        entitiesArray.push(newEntity);
    })
    return (
        <>
            <h3 style={{ marginTop: '-2.5rem' }}>Reporting hierarchy</h3>
            <OrganizationalChart data={entitiesArray} />
        </>
    )
} export default HierarchyOrgChart