import React, { useState } from 'react'
import BootstrapButton from 'react-bootstrap/Button'
import {FaArrowsRotate} from 'react-icons/fa6';

export const SyncButton: React.FC = () => {
  const [isDisabled, setIsDisabled] = useState(false)

  const handleSync = () => {
    // Make a request to the backend API
    fetch(`${process.env.REACT_APP_BASE_URL}api/employees/manualadsync`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        const contentDisposition = response.headers.get('Content-Disposition')
      })
      .catch((error) => {
        console.error('Error during sync with azure active directory: ', error)
        setIsDisabled(false)
      })
  }

  return (
    <BootstrapButton
      variant='outline-danger'
      size='sm'
      onClick={handleSync}
    >
      <FaArrowsRotate /> AD Sync
    </BootstrapButton>
  )
}
