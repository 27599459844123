import { FilterComponentProps, FilterValues } from 'interfaces/interfaces'
import React, { useState } from 'react'
import { BsFilter, BsTrash, } from 'react-icons/bs'
import DatePicker from 'react-datepicker'
import { Tooltip as ReactTooltip } from 'react-tooltip';

const FilterComponent: React.FC<FilterComponentProps> = ({ properties, onApplyFilter }) => {
  const [filterValues, setFilterValues] = useState<FilterValues>({})
  const [isChecked, setIsChecked] = useState(false)
  const [isEmployeeEditDoneChecked, setIsEmployeeEditDoneChecked] = useState(false)
  const [startDate, setStartDate] = useState<null | Date>(null)
  const [endDate, setEndDate] = useState<null | Date>(null)

  const handleFilterChange = (propertyName: string, values: string[]) => {
    setFilterValues((prevFilterValues) => {
      const newFilterValues: FilterValues = {
        ...prevFilterValues,
        [propertyName]: values,
      }
      if (!values.every((value) => value.length !== 0)) {
        delete newFilterValues[propertyName]
      }
      return newFilterValues
    })
  }

  const handleApplyFilter = () => {
    onApplyFilter(filterValues)
  }
  const handleClearFilter = () => {
    setIsChecked(false)
    setIsEmployeeEditDoneChecked(false)
    setStartDate(null)
    setEndDate(null)
    setFilterValues({})
    onApplyFilter({})
    setIsAdvancedSearchOpen(false)
  }

  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false)

  const toggleAdvancedSearch = () => {
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen)
  }

  return (
    <>
      <div className='filter-component d-flex flex-row align-items-center ml-2'>
        <div className='row'>
          {properties.map((property) => {
            if (!property.isAdvanced || (!isAdvancedSearchOpen && property.isVisible)) {
              return (
                <div key={property.name} className='form-group p-2 mb-0'>
                  <label className='form-label' htmlFor={property.name}>
                    {property.propertyLabel}
                  </label>
                  {property.useDropdown && (
                    <select
                      id={property.name}
                      className='form-control'
                      autoComplete='true'
                      value={filterValues[property.name] || ''}
                      onChange={(e) => handleFilterChange(property.name, [e.target.value])}
                      {...property.dropdownProps}
                    >
                      <option value=''>All</option>
                      {property.dropdownOptions?.map((option) => (
                        <option key={option?.value?.toString()} value={option?.value?.toString()}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  )
                  }

                  {property.useTextField && (
                    <input
                      type='text'
                      id={property.name}
                      className='form-control'
                      placeholder={`Enter ${property.propertyLabel}`}
                      value={filterValues[property.name] || ''}
                      onChange={(e) => handleFilterChange(property.name, [e.target.value])}
                    />
                  )}
                  {property.useCheckBox && (
                    <input
                      type='checkbox'
                      id={property.name}
                      className='form-control'
                      placeholder={`Enter ${property.name}`}
                      value={filterValues[property.name] || ''}
                      checked={isChecked}
                      onChange={(e) => {
                        setIsChecked(e.target.checked)
                        handleFilterChange(property.name, [e.target.checked.toString()])
                      }}
                    />
                  )}
                  {property.useEmployeeCheckBox && (
                    <input
                      type='checkbox'
                      id={property.name}
                      className='form-control'
                      placeholder={`Enter ${property.name}`}
                      value={filterValues[property.name] || ''}
                      checked={isEmployeeEditDoneChecked}
                      onChange={(e) => {
                        setIsEmployeeEditDoneChecked(e.target.checked)
                        handleFilterChange(property.name, [e.target.checked.toString()])
                      }}
                    />
                  )}
                </div>
              )
            }
          })}

        </div>
      </div>
      <div className='collapse' id='collapseExample'>
        <div className='card card-body'>
          <div className='row'>
            {properties.map((property) =>
              property.isAdvanced ? (
                <div key={property.name} className='form-group p-2 mb-0'>
                  <label className='form-label' htmlFor={property.name}>
                    {property.propertyLabel}
                  </label>
                  {property.useDropdown && (
                    <select
                      id={property.name}
                      className='form-control'
                      autoComplete='true'
                      value={filterValues[property.name] || ''}
                      onChange={(e) => handleFilterChange(property.name, [e.target.value])}
                      {...property.dropdownProps}
                    >
                      <option value=''>All</option>
                      {property.dropdownOptions?.map((option) => (
                        <option key={option?.value?.toString()} value={option?.value?.toString()}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  )}
                  {property.useTextField && (
                    <input
                      type='text'
                      id={property.name}
                      className='form-control'
                      placeholder={`Enter ${property.propertyLabel}`}
                      value={filterValues[property.name] || ''}
                      onChange={(e) => handleFilterChange(property.name, [e.target.value])}
                    />
                  )}
                  {property.useCheckBox && (
                    <input
                      type='checkbox'
                      id={property.name}
                      className='form-control'
                      placeholder={`Enter ${property.propertyLabel}`}
                      value={filterValues[property.name] || ''}
                      checked={isChecked}
                      onChange={(e) => {
                        setIsChecked(e.target.checked)
                        handleFilterChange(property.name, [e.target.checked.toString()])
                      }}
                    />
                  )}
                  {property.useEmployeeCheckBox && (
                    <input
                      type='checkbox'
                      id={property.name}
                      className='form-control'
                      placeholder={`Enter ${property.propertyLabel}`}
                      value={filterValues[property.name] || ''}
                      checked={isEmployeeEditDoneChecked}
                      onChange={(e) => {
                        setIsEmployeeEditDoneChecked(e.target.checked)
                        handleFilterChange(property.name, [e.target.checked.toString()])
                      }}
                    />
                  )}
                  {(property.useDatePicker && property.name == 'EmployeeHireDateFrom') && (
                    <>
                      <br></br>
                      <DatePicker
                        name={property.propertyLabel}
                        id={property.name}
                        selected={startDate}
                        dateFormat='dd/MM/yyyy'
                        className='form-control'
                        onChange={(date: Date | null) => {
                          setStartDate(date)
                          handleFilterChange(property.name, [date != null ? date?.toISOString() : ''])
                        }}
                        isClearable={true}
                      />
                    </>
                  )}
                  {(property.useDatePicker && property.name == 'EmployeeHireDateTo') && (
                    <>
                      <br></br>
                      <DatePicker
                        name={property.propertyLabel}
                        id={property.name}
                        selected={endDate}
                        dateFormat='dd/MM/yyyy'
                        className='form-control'
                        onChange={(date: Date | null) => {
                          setEndDate(date)
                          handleFilterChange(property.name, [date != null ? date?.toISOString() : ''])
                        }}
                        isClearable={true}
                      />
                    </>
                  )}
                </div>
              ) : null,
            )}
          </div>
        </div>
      </div>
      <div className='d-flex align-items-end align-self-stretch p-2 mb-2'>
        <button className='btn btn-outline-primary btn-sm mr-3' onClick={handleApplyFilter}>
          <BsFilter /> Apply Filter
        </button>
        <button className='btn btn-outline-danger btn-sm mr-3' onClick={handleClearFilter}>
          <BsTrash /> Clear Filter
        </button>
        <button
          className="btn btn-primary d-flex align-items-center" style={{ height: '32px', width: '35px' }}
          data-toggle="collapse"
          data-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
          onClick={toggleAdvancedSearch}
          role="button"
          data-tooltip-id='arrow'
        >
          {isAdvancedSearchOpen ?
            <i className="fa fa-angle-double-up"> </i>

            :
            <i className="fa fa-angle-double-down"></i>
          }
        </button>
        <ReactTooltip
          id='arrow'
          place="right"
          content={isAdvancedSearchOpen ? 'Less options' : 'More options'}
        />
      </div>
    </>
  )
}

export default FilterComponent
