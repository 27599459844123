import React from 'react'
import { Controller } from 'react-hook-form'
import { InputGroup, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'

interface Props {
  label: string
  id: string
  control: any
  error?: any
  options: any
  className?: string
  showLabel?: boolean
  onChange?: (value: string | number) => void
  disabled?: boolean
  showWarning?: boolean
  tooltip?: string
  tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left'
}
interface Option {
  value: any
  label: string
}

const FormSelect: React.FC<Props> = ({
  label,
  id,
  control,
  error = null,
  options,
  className = 'col-md-4',
  showLabel = true,
  onChange,
  disabled = false,
  showWarning = false,
  tooltip,
  tooltipPlacement = 'right',
}) => {
  return (
    <div className={className}>
      {showLabel && (
        <label htmlFor={id} className='form-label'>
          {label}
        </label>
      )}

      {tooltip && showWarning ? (
        <Controller
          name={id}
          control={control}
          disabled={disabled}
          render={({ field }) => (
            <InputGroup hasValidation>
              <OverlayTrigger
                key={tooltipPlacement}
                placement={tooltipPlacement}
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip className='ml-2' id={`tooltip-${tooltipPlacement}`}>
                    {tooltip}
                  </Tooltip>
                }
              >
                <Form.Control
                  as='select'
                  isInvalid={showWarning}
                  {...field}
                  disabled={disabled}
                  value={field.value !== null && field.value !== undefined ? field.value : ''}
                  className='custom-input-color'
                  id={id}
                  onChange={(event) => {
                    const value = event.target.value
                    let parsedValue
                    if (!isNaN(Number(value))) {
                      parsedValue = parseInt(value)
                    } else {
                      parsedValue = value
                    }
                    field.onChange(parsedValue)
                    if (onChange) {
                      onChange(parsedValue)
                    }
                  }}
                >
                  <option value='' disabled>
                    Select {label}
                  </option>
                  {options &&
                    options?.map((option: Option, index: number) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                </Form.Control>
              </OverlayTrigger>
            </InputGroup>
          )}
        />
      ) : (
        <Controller
          name={id}
          control={control}
          render={({ field }) => (
            <Form.Control
              as='select'
              isInvalid={showWarning}
              {...field}
              disabled={disabled}
              value={field.value !== null && field.value !== undefined ? field.value : ''}
              className='custom-input-color'
              id={id}
              onChange={(event) => {
                const value = event.target.value
                let parsedValue
                if (!isNaN(Number(value))) {
                  parsedValue = parseInt(value)
                } else {
                  parsedValue = value
                }
                field.onChange(parsedValue)
                if (onChange) {
                  onChange(parsedValue)
                }
              }}
            >
              <option value='' disabled>
                Select {label}
              </option>
              {options &&
                options?.map((option: Option, index: number) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </Form.Control>
          )}
        />
      )}

      {error && <p className='text-danger'>{error.message}</p>}
    </div>
  )
}

export default FormSelect
