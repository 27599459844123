/* eslint-disable react/prop-types */
import React from 'react'
import { FaBuilding } from 'react-icons/fa'
import avatar from 'assets/images/undraw_profile.svg'

const styles = {
  nodeContainer: {
    minHeight: '150px',
    backgroundColor: '#227c9d',
    color: '#227c9d',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '1rem',
  },
  nodeDetails: {
    width: '100%',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  nodeContent: {
    display: 'flex',
    alignItems: 'center',
  },
  nodeTeam: {
    width: '100%',
    textAlign: 'center',
  },
  nodeTeamName: {
    marginBottom: '0.5rem',
    color: '#fef9ef',
    fontSize: '1.5rem',
  },
  nodeInfo: {
    marginLeft: '1.5rem',
    color: '#fef9ef',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  nodeName: {
    paddingBottom: '0.3rem',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  nodeRole: {
    paddingBottom: '0.5rem',
    fontSize: '1.2rem',
  },
  nodeDepartment: {
    padding: '0.5rem',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffcb77',
    borderRadius: '1rem',
    color: '#227c9d',
  },
  icon: {
    marginRight: '0.5rem',
  },
};

const CustomNodeContent = (props) => {
  if (props.data.type === 'employee') {
    const imageUrl = props.data.photo ? 'data:image/png;base64,' + props.data.photo : avatar
    return (
      <div className='person-card card' style={{ borderColor: 'gray', backgroundColor: '#fef9ef' }}>
        <div className='card-body'>
          <div className='d-flex align-items-center'>
            <img src={imageUrl} alt={props.data.name} className='img-profile xs rounded-circle' style={{ width: '120px' }} />
            <div className='text-container mx-3'>
              <div className='mb-0' style={styles.nodeName}>
                {props.data.name}
              </div>
              <p className='text-muted mb-0'>{props.data.positionName}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else {
    var icon = '';

    switch (props.data.type) {
      case 'root':
        icon = 'fa fa-sitemap'
        break
      case 'client':
        icon = 'fa fa-building'
        break
      case 'project':
        icon = 'fa fa-rocket'
        break
      case 'team':
        icon = 'fa fa-users'
        break
    }
    return (
      <div className='person-card card' style={{ borderColor: 'gray' }}>
        <div className='card-body d-flex align-items-center justify-content-center'>
          <div className='d-flex align-items-center'>
            <i className={`fas nav-icon ${icon}`} style={{ 'marginBottom': '5px' }}></i>
            <div className='text-container mx-3'>
              <div className='mb-0' style={styles.nodeName}>
                {props.data.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CustomNodeContent
