import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { EmployeeProjectInfo } from 'models/employeeProjectInfo'
import { FaSave, FaUser } from 'react-icons/fa'
import { useGetProjectsWithTeamsQuery } from 'services/queries/api'

interface Project {
  id: string
  projectName: string
  teams: Team[]
}

interface Team {
  id: string
  teamName: string
}

interface EmployeeBenchModalProps {
  show: boolean
  onClose: () => void
  onSave: (employeeId: string, teamId: string) => Promise<any>
  employee: EmployeeProjectInfo | null
}

const EmployeeBenchModal: React.FC<EmployeeBenchModalProps> = ({
  show,
  onClose,
  onSave,
  employee,
}) => {
  const [selectedProject, setSelectedProject] = useState<Project | null>(null)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)
  const [isConfirming, setIsConfirming] = useState<boolean>(false)
  const { data: projects } = useGetProjectsWithTeamsQuery()

  useEffect(() => {
    if (!show) {
      setSelectedProject(null)
      setSelectedTeam(null)
      setIsConfirming(false)
    }
  }, [show])

  const handleConfirm = () => {
    setIsConfirming(true)
  }

  const handleSave = async () => {
    if (isConfirming && selectedProject !== null && selectedTeam !== null) {
      const result = await onSave(employee?.id || '', selectedTeam.id)
      setSelectedProject(null)
      setSelectedTeam(null)
      setIsConfirming(false)
      return result
    }
  }
  const availableProjects = projects
    ? projects?.filter((project: Project) => !employee?.projectNames.includes(project.projectName))
    : []

  return (
    <Modal show={show} onHide={onClose} size='lg' centered>
      <Modal.Header className='p-3'>
        <h5>
          {isConfirming ? <FaSave className='mr-2' /> : <FaUser className='mr-2' />}
          {isConfirming ? 'Confirm Move' : 'Employee Information'}
        </h5>
      </Modal.Header>
      <Modal.Body>
        {isConfirming && employee ? (
          <div>
            <p>
              Are you sure you want to move {employee?.employeeName} from the following projects:
            </p>
            <ul className='list-unstyled'>
              {employee?.projectNames.map((project, index) => (
                <li key={index}>{project}</li>
              ))}
            </ul>
            <p>
              to the {selectedProject ? selectedProject.projectName : ''} project and assign to the{' '}
              {selectedTeam ? selectedTeam.teamName : ''} team?
            </p>
          </div>
        ) : (
          <>
            {employee && (
              <div>
                <h5>Name: {employee.employeeName}</h5>
                <h5>Projects:</h5>
                <ul className='list-unstyled'>
                  {employee.projectNames &&
                    employee.projectNames.map((project, index) => <li key={index}>{project}</li>)}
                </ul>
              </div>
            )}
            <hr />
            <h5>Assign to Project:</h5>
            <select
              className='form-control mb-3'
              value={selectedProject?.id || ''}
              onChange={(e) =>
                setSelectedProject(projects?.find((p: Project) => p.id === e.target.value) || null)
              }
            >
              <option value='' disabled>
                Assign to Project:
              </option>
              {availableProjects?.map((project: Project) => (
                <option key={project.id} value={project.id}>
                  {project.projectName}
                </option>
              ))}
            </select>
            {selectedProject && (
              <>
                <h5>Select Team:</h5>
                <select
                  className='form-control mb-3'
                  value={selectedTeam?.id || ''}
                  onChange={(e) =>
                    setSelectedTeam(
                      selectedProject?.teams.find((team: Team) => team.id === e.target.value) ||
                        null,
                    )
                  }
                >
                  <option value='' disabled>
                    Select a Team
                  </option>
                  {selectedProject?.teams.map((team: Team) => (
                    <option key={team.id} value={team.id}>
                      {team.teamName}
                    </option>
                  ))}
                </select>
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {isConfirming ? (
          <Button
            variant='outline-primary'
            size='sm'
            onClick={handleSave}
            disabled={!selectedProject || !selectedTeam}
          >
            Confirm
          </Button>
        ) : (
          <Button
            variant='outline-primary'
            size='sm'
            onClick={handleConfirm}
            disabled={!selectedProject || !selectedTeam}
          >
            Save
          </Button>
        )}
        <Button variant='outline-danger' size='sm' onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EmployeeBenchModal
