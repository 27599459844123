import React, { useState } from 'react';
import OrgChart from './orgChart/OrgChart';
import HierarchyOrgChart from './hierarchyChart/HierarchyChart';
import BoardChart from './companyChart/BoardChart';
import { ChartsEnum } from 'enums/ChartsEnum';

const ChartsViewSelector = () => {
    const [activeTab, setActiveTab] = useState<ChartsEnum>(ChartsEnum.Teams);

    const handleTabClick = (tab: ChartsEnum) => {
        setActiveTab(tab);
    };

    return (
        <div>
            <div className="row justify-content-end">
                <div className="col-auto">
                    <div className="btn-group" role="group">
                        <button
                            type="button"
                            className={`btn btn-outline-secondary ${activeTab === ChartsEnum.Teams ? 'active' : ''}`}
                            onClick={() => handleTabClick(ChartsEnum.Teams)}
                        >
                            Teams
                        </button>
                        <button
                            type="button"
                            className={`btn btn-outline-secondary ${activeTab === ChartsEnum.Employees ? 'active' : ''}`}
                            onClick={() => handleTabClick(ChartsEnum.Employees)}
                        >
                            Employees
                        </button>
                        <button
                            type="button"
                            className={`btn btn-outline-secondary ${activeTab === ChartsEnum.Structure ? 'active' : ''}`}
                            onClick={() => handleTabClick(ChartsEnum.Structure)}
                        >
                            Structure
                        </button>
                    </div>
                </div>
            </div>

            <div className="tab-content">
                {activeTab === ChartsEnum.Teams && <OrgChart />}
                {activeTab === ChartsEnum.Employees && <HierarchyOrgChart />}
                {activeTab === ChartsEnum.Structure && <BoardChart />}
            </div>
        </div>


    );
};

export default ChartsViewSelector;