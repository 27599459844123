import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { DropdownItem } from 'interfaces/interfaces'
import 'bootstrap/dist/css/bootstrap.min.css'
import { EmpAccountFormValues } from 'models/empAccountFormValues'
import {
    FormSelect,
} from '../../../components/formComponents'
// --- DevTool test react hooks form ---
import { DevTool } from '@hookform/devtools'
import DefaultButton from 'components/DefaultButton'
// --- Validations yup
// import { yupResolver } from '@hookform/resolvers/yup'
// import userUpdateFormSchema from '../validation/schemas/UserUpdateFormSchema'


interface EmpAccountFormProps {
    employee: EmpAccountFormValues
    onSubmit: (data: any) => void
    roles: DropdownItem[] | undefined
}

const EmpAccountForm: FC<EmpAccountFormProps> = ({ employee, onSubmit, roles }) => {
    // TODO: Update default values when add validations from yup
    const form = useForm<EmpAccountFormValues>({
        defaultValues: {
            id: employee.id,
            roles: employee.roles
        },
        // Use yup validations - userUpdateFormSchema
        // resolver: yupResolver(userUpdateFormSchema),
        mode: 'onBlur',
    })
    const {
        register,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors, isDirty, isValid },
    } = form

    return (
        <div className='row justify-content-left'>
            <div className='col-md-12 mt-3 mb-1 p-3'>
                <h4>EMP account</h4>
            </div>
            <hr className='mb-5 ml-0 mr-0' />
            <form className='col-6 form-max-width' onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormSelect
                    label='EMP role'
                    id='roles'
                    control={control}
                    error={errors.roles}
                    options={roles}
                    className='col-md-5 mb-2'
                />

                <div className='row'>
                    <div className='col-md-3 ml-md-auto'>
                        <DefaultButton
                            type='submit'
                            // disabled={!isDirty || !isValid}
                            color='outline-primary'
                        >
                            Save
                        </DefaultButton>
                    </div>
                </div>
            </form>
            {/* <DevTool control={control} /> */}
        </div>
    )
}
export default EmpAccountForm