export class NewDivision {
    id!: number | undefined
    name!: string
    departmentId!: string | undefined

    constructor(project?: any) {
        if (project) {
            Object.assign(this, project)
        }
    }
}
