import { SignOutButton } from 'components/SignOutButton'
import React from 'react'

const Unauthorized: React.FC = () => {
  return (
    <div className='container'>
      <div className='row justify-content-center mt-5'>
        <div className='col-md-6'>
          <div className='card'>
            <div className='card-header'>Unauthorized Access</div>
            <div className='card-body text-center'>
              <p>Please log in with valid role to access this page.</p>
              <div className='d-flex justify-content-center'>
                <SignOutButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Unauthorized
