import { useState, useEffect, useCallback } from 'react'
import Sidebar from './components/sidebar/Sidebar'
import Content from './components/content/Content'
import Navbar from './components/navbar/Navbar'
import { scheduleLogout } from 'utils/helpers'
import { useSavePathToLocalStorage } from 'hooks/useSavePathToLocalStorage'

function Layout() {
  useSavePathToLocalStorage()
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)

  const onToggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed)
  }

  const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

    const handleResize = useCallback(() => {
      const newIsMobile = window.innerWidth < 768
      setIsMobile(newIsMobile)

      const accordionSidebar = document.getElementById('accordionSidebar')
      if (accordionSidebar) {
        if ((!isMobile && newIsMobile) || (isMobile && !newIsMobile)) {
          accordionSidebar.classList.toggle('toggled')
        }
      }
    }, [isMobile])

    useEffect(() => {
      const resizeListener = () => {
        requestAnimationFrame(handleResize)
      }

      window.addEventListener('resize', resizeListener)

      return () => {
        window.removeEventListener('resize', resizeListener)
      }
    }, [handleResize])

    return isMobile
  }
  const isMobile = useIsMobile()

  useEffect(() => {
    scheduleLogout()
  }, [])

  return (
    <div id='page-top'>
      <div id='wrapper'>
        <Sidebar sidebarCollapsed={sidebarCollapsed} isMobile={isMobile} />
        <div id='content-wrapper' className='d-flex flex-column'>
          <div id='content'>
            <Navbar
              onToggleSidebar={onToggleSidebar}
              sidebarCollapsed={sidebarCollapsed}
              isMobile={isMobile}
            />
            <Content />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
