import React, { useState, useRef } from 'react';
import { Assignment, UpdateAssignment } from 'models/assignment.model';
import { BsTrash, BsPencil } from 'react-icons/bs';
// import ReactTooltip from 'react-tooltip';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { displayTeamName } from '../../../utils/helpers';
import AssignEmployeeToTeamForm from '../../../components/AssignEmployeeToTeamForm';
import { useGetTeamRolesQuery } from 'services/queries/api';

export interface UpdateTeamsOverviewProps {
    assignments?: Assignment[] | undefined;
    handleDelete: (data: Assignment) => void
    handleEditAssignment: (assignment: Assignment) => void
}

export function UpdateTeamsOverview({ assignments, handleDelete, handleEditAssignment }: UpdateTeamsOverviewProps) {
    return (
        <>
            <div className='d-flex mb-3 flex-row align-items-center flex-wrap'>
                <div className='mr-auto p-2'>
                    <div className='page-header'>
                        <h4>Employee teams and assignments</h4>
                    </div>
                </div>
            </div>
            <div style={{ overflowY: 'auto' }}>
                <table className='table table-striped table-hover'>
                    <thead>
                        <tr>
                            <th scope='col'>Team name</th>
                            <th scope='col'>Role in the team</th>
                            <th scope='col'></th>
                            <th scope='col'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {assignments?.map((assignment: Assignment) => (
                            <>
                                <tr key={assignment?.id}>
                                    <td>{displayTeamName(assignment.client, assignment.project, assignment.team)}</td>
                                    <td>{assignment.teamRole.name}</td>
                                    <td>
                                        <button
                                            className='btn btn-outline-primary btn-sm'
                                            type='button'
                                            onClick={() => handleEditAssignment(assignment)}
                                        >
                                            <BsPencil />
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            className='btn btn-outline-danger btn-sm'
                                            onClick={() =>
                                                handleDelete(assignment)
                                            }
                                        >
                                            <BsTrash />
                                        </button>
                                    </td>

                                </tr>
                                <ReactTooltip
                                    id={`${assignment?.id}`}
                                    place="top"
                                    content={`Client=${assignment.client} Project=${assignment.project} Team=${assignment.team}`}
                                />
                            </>
                        ))
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
