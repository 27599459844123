import { BsTrash, BsPencil, BsPlusSquare } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react'
import { useDeleteTeamMutation, useGetTeamsFilterQuery } from 'services/queries/api'
import { RoleEnum } from 'enums/RoleEnum'
import { useRenderComponentWithValidRole } from 'hooks/useRenderComponentWithValidRole'
import { useSelector } from 'react-redux'
import ModalShowMessage from 'components/ModalShowMessage'
import { MessageTypeEnum } from 'enums/MessageTypeEnum'
import TeamsFilter from './components/TeamsFilter'
import CreateTeamModal from './CreateTeamModal'
import { displayTeamName } from 'utils/helpers'

const Teams: React.FC = () => {
  const [selected, setSelected] = useState<{ client: string; project: string }>({
    client: '',
    project: '',
  })

  const { data: teams, refetch } = useGetTeamsFilterQuery({
    selectedClient: selected.client,
    selectedProject: selected.project,
  })

  const [deleteTeam, { isSuccess, isError }] = useDeleteTeamMutation()

  const [showMessage, setShowMessage] = useState(true)
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false)
  const idRef = useRef<string>('')
  const messageRef = useRef<string>()
  const messageTypeRef = useRef<MessageTypeEnum>(MessageTypeEnum.information)
  const [showNewTeamModal, setShowNewTeamModal] = useState(false)
  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (isSuccess || isError) {
      setShowMessage(true)

      const timer = setTimeout(() => {
        setShowMessage(false)
      }, 1500)

      return () => clearTimeout(timer)
    }
  }, [isSuccess, isError])


  const handleDelete = async (id: any) => {
    try {
      messageRef.current = 'Are you sure you want to delete team?'
      idRef.current = id
      messageTypeRef.current = MessageTypeEnum.question
      setIsMessageBoxOpen(true)
    } catch (error) {
      console.log(error)
    }
  }

  const callDelete = async () => {
    try {
      await deleteTeam(idRef.current).unwrap()
      refetch()
    } catch (error) {
      console.log(error)
    }
  }

  const closeMessageBox = () => {
    setIsMessageBoxOpen(false)
  }

  const handleFilteredData = (client: string, project: string) => {
    try {
      setSelected({ client, project })
    } catch (error) {
      console.log('error', error)
    }
  }
  const getAssignmentNameByRole = (assignments: any[], roleId: number) => {
    const assignment = assignments.find((x: any) => x?.teamRole?.id === roleId)
    return assignment ? assignment?.name : ''
  }

  const roles = useSelector((state: any) => state.token.value)
  return (
    <>
      {isMessageBoxOpen && (
        <ModalShowMessage
          onClose={closeMessageBox}
          showModal={isMessageBoxOpen}
          message={messageRef.current}
          onChange={callDelete}
          messageType={messageTypeRef.current}
        />
      )}
      <div>
        {isSuccess && showMessage && (
          <div className='alert alert-success mt-3' role='alert'>
            Team has been successfully deleted!
          </div>
        )}
        {isError && showMessage && (
          <div className='alert alert-danger mt-3' role='alert'>
            Error deleting team. Please try again.
          </div>
        )}
      </div>
      <div className='d-flex mb-3 flex-row align-items-center flex-wrap'>
        <div className='mr-auto p-2'>
          <div className='page-header'>
            <h3>Teams</h3>
          </div>
        </div>
        <div>
          {useRenderComponentWithValidRole({
            roles,
            allowedRoles: [RoleEnum.Admin],
            children: (
              <div>
                <Link
                  to='/clients'
                  className='btn btn-outline-secondary btn-sm mr-2'
                  style={{ marginRight: '20px' }}
                >
                  Clients
                </Link>
                <Link
                  to='/projects'
                  className='btn btn-outline-secondary btn-sm mr-2'
                  style={{ marginRight: '20px' }}
                >
                  Projects
                </Link>
                <button
                  className='btn btn-outline-primary btn-sm mr-2'
                  onClick={() => setShowNewTeamModal(true)}
                >
                  Create Team
                </button>
              </div>
            ),
          })}
          {showNewTeamModal && (
            <CreateTeamModal onClose={() => setShowNewTeamModal(false)} refetch={refetch} />
          )}
        </div>
      </div>

      <TeamsFilter onFilterChange={handleFilteredData} />

      <div style={{ overflowY: 'auto' }}>
        <table className='table table-striped table-hover'>
          <thead>
            <tr>
              <th scope='col'>#</th>
              <th scope='col'> Team name</th>
              <th scope='col'>Team size</th>
              <th scope='col'>CDM</th>
              <th scope='col'>EM/Director</th>
              {useRenderComponentWithValidRole({
                roles,
                allowedRoles: [RoleEnum.Admin],
                children: (
                  <>
                    <th scope='col'></th>
                  </>
                ),
              })}
            </tr>
          </thead>
          <tbody>
            {teams?.length === 0 ? (
              <tr>
                <td colSpan={10} className='text-center'>
                  No data available.
                </td>
              </tr>
            ) : (
              teams?.map((team: any, index: any) => (
                <tr key={index + 1}>
                  <th scope='row'>{index + 1}</th>
                  <td>
                    <Link to={`/teams/${team.id}`}>
                      {`${displayTeamName(team?.project?.client?.name, team?.project?.name, team?.name)}`}
                    </Link>{' '}
                  </td>
                  <td>{team?.numOfEmployees}</td>
                  <td>{getAssignmentNameByRole(team?.assignments, 2)}</td>
                  <td>{getAssignmentNameByRole(team?.assignments, 3) ?? getAssignmentNameByRole(team?.assignments, 1)}</td>
                  {useRenderComponentWithValidRole({
                    roles,
                    allowedRoles: [RoleEnum.Admin],
                    children: (
                      <>
                        <td className='d-flex justify-content-end'>
                          <Link to={`/teams/${team.id}`}>
                            <button className='btn btn-outline-primary mr-2'>
                              <BsPencil />
                            </button>
                          </Link>
                          <button
                            className='btn btn-outline-danger'
                            onClick={() => handleDelete(team.id)}
                          >
                            <BsTrash />
                          </button>
                        </td>
                      </>
                    ),
                  })}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Teams
