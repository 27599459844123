import { useState } from 'react'
import { useGetBenchEmployeesQuery, useUpdateBenchAssignmentsMutation } from 'services/queries/api'
import { EmployeeProjectInfo } from 'models/employeeProjectInfo'
import EmployeeBenchModal from './EmployeeBenchModal'
import { ToastContainer, toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'
import BenchFilter from './components/BenchFilter'

const Bench: React.FC = () => {
  const [updateAssignments] = useUpdateBenchAssignmentsMutation()
  const [selected, setSelected] = useState<{ project: string }>({
    project: '',
  })

  const { data: emp, refetch } = useGetBenchEmployeesQuery({
    selectedProject: selected.project,
  })
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeProjectInfo | null>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const handleFilteredData = (project: string) => {
    try {
      setSelected({ project })
      refetch()
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleOpenModal = (employee: EmployeeProjectInfo) => {
    setSelectedEmployee(employee)
    setIsModalOpen(true)
  }
  const handleCloseModal = () => {
    setSelectedEmployee(null)
    setIsModalOpen(false)
  }

  const handleSave = async (employeeId: string, teamId: string) => {
    try {
      const mutationResponse = await updateAssignments({
        employeeId,
        teamId,
      })
      if (mutationResponse) {
        toast.success('Your changes have been successfully saved!')
        refetch()
      }
    } catch (error) {
      toast.error('Save error')
    } finally {
      setIsModalOpen(false)
    }
  }

  return (
    <>
      <div className='d-flex mb-3 flex-row align-items-center flex-wrap'>
        <div className='mr-auto p-2'>
          <div className='page-header'>
            <h3>Bench Employees</h3>
          </div>
        </div>
      </div>
      <BenchFilter onFilterChange={handleFilteredData} />
      <div style={{ overflowY: 'auto' }}>
        <table className='table table-striped table-hover'>
          <thead>
            <tr>
              <th scope='col'>#</th>
              <th scope='col'>Full Name</th>
              <th scope='col'>Location</th>
              <th scope='col'>Department</th>
              <th scope='col'>Job title</th>
              <th scope='col'>Projects</th>
              <th scope='col'></th>
              <th scope='col'></th>
            </tr>
          </thead>
          <tbody>
            {emp?.map((item: EmployeeProjectInfo, index: any) => (
              <tr key={index}>
                <th scope='row'>{index + 1}</th>
                <td>
                  <Link to={`/singleEmployee/${item.id}`}>{item.employeeName}</Link>{' '}
                </td>
                <td>{item?.location}</td>
                <td>{item?.department}</td>
                <td>{item?.jobTitle}</td>
                <td>
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {item.projectNames.map((project, projectIndex) => (
                      <li key={projectIndex}>{project}</li>
                    ))}
                  </ul>
                </td>
                <td>
                  <Link to={`/employees/update/${item?.id.toString()}`}>
                    <button
                      className='btn btn-outline-primary btn-sm'
                      data-toggle='tooltip'
                      title='Update Employee'
                    >
                      <BsPencil />
                    </button>
                  </Link>
                </td>
                <td>
                  <button
                    className='btn btn-outline-primary btn-sm'
                    onClick={() => handleOpenModal(item)}
                  >
                    Assign to Project
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='text-right p-3'>
          {emp && (
            <p>
              Total Number Of Employees on Bench <strong>{emp?.length}</strong>, with{' '}
              <strong>
                {
                  emp.filter(
                    (emp: any) =>
                      emp.projectNames &&
                      emp.projectNames.length > 0 &&
                      !emp.projectNames.includes('BENCH-Unassigned'),
                  ).length
                }
              </strong>{' '}
              {emp.filter(
                (emp: any) =>
                  emp.projectNames &&
                  emp.projectNames.length > 0 &&
                  !emp.projectNames.includes('BENCH-Unassigned'),
              ).length === 1
                ? 'employee'
                : 'employees'}{' '}
              on internal projects and{' '}
              <strong>
                {
                  emp.filter(
                    (emp: any) => emp.projectNames && emp.projectNames.includes('BENCH-Unassigned'),
                  ).length
                }
              </strong>{' '}
              {emp.filter(
                (emp: any) => emp.projectNames && emp.projectNames.includes('BENCH-Unassigned'),
              ).length === 1
                ? 'employee'
                : 'employees'}{' '}
              unassigned
              {emp.filter(
                (emp: any) =>
                  emp.projectNames &&
                  emp.projectNames.includes('BENCH-Unassigned') &&
                  emp.projectNames.some((project: string) => project !== 'BENCH-Unassigned'),
              ).length > 0 ? (
                <>
                  , out of which{' '}
                  <strong>
                    {
                      emp.filter(
                        (emp: any) =>
                          emp.projectNames &&
                          emp.projectNames.includes('BENCH-Unassigned') &&
                          emp.projectNames.some(
                            (project: string) => project !== 'BENCH-Unassigned',
                          ),
                      ).length
                    }
                  </strong>{' '}
                  {emp.filter(
                    (emp: any) =>
                      emp.projectNames &&
                      emp.projectNames.includes('BENCH-Unassigned') &&
                      emp.projectNames.some((project: string) => project !== 'BENCH-Unassigned'),
                  ).length === 1
                    ? 'employee'
                    : 'employees'}{' '}
                  are also on internal projects.
                </>
              ) : null}
            </p>
          )}
        </div>
      </div>
      <EmployeeBenchModal
        show={isModalOpen}
        onSave={handleSave}
        onClose={handleCloseModal}
        employee={selectedEmployee}
      />
      <ToastContainer autoClose={2000} />
    </>
  )
}

export default Bench
