import { BsTrash, BsPencil, BsPlusSquare } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react'
import { useRenderComponentWithValidRole } from 'hooks/useRenderComponentWithValidRole'
import { RoleEnum } from 'enums/RoleEnum'
import { useSelector } from 'react-redux'
import { useDeleteClientMutation, useGetClientsQuery } from 'services/queries/api'
import ModalShowMessage from 'components/ModalShowMessage'
import { MessageTypeEnum } from 'enums/MessageTypeEnum'
import { Button } from 'react-bootstrap'
import NewClientModal from './NewClientModal'

const Clients: React.FC = () => {
  const { data: clients, refetch } = useGetClientsQuery()
  const [deleteClient, { isSuccess, isError }] = useDeleteClientMutation()
  const [showMessage, setShowMessage] = useState(true)
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false)
  const idRef = useRef<string>('')
  const messageRef = useRef<string>()
  const messageTypeRef = useRef<MessageTypeEnum>(MessageTypeEnum.information)
  const [showNewClientModal, setShowNewClientModal] = useState(false)
  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (isSuccess || isError) {
      setShowMessage(true)

      const timer = setTimeout(() => {
        setShowMessage(false)
      }, 1500)

      return () => clearTimeout(timer)
    }
  }, [isSuccess, isError])

  const callDelete = async () => {
    try {
      await deleteClient(idRef.current).unwrap()
      refetch()
    } catch (error: any) {
      messageRef.current = error.data.message
      messageTypeRef.current = MessageTypeEnum.information
      setIsMessageBoxOpen(true)
      console.log(error)
    }
  }

  const handleDelete = (id: any) => {
    messageRef.current = 'Are you sure you want to delete client?'
    idRef.current = id
    messageTypeRef.current = MessageTypeEnum.question
    setIsMessageBoxOpen(true)
  }

  const closeMessageBox = () => {
    setIsMessageBoxOpen(false)
  }

  const roles = useSelector((state: any) => state.token.value)
  return (
    <>
      {isMessageBoxOpen && (
        <ModalShowMessage
          onClose={closeMessageBox}
          showModal={isMessageBoxOpen}
          message={messageRef.current}
          onChange={callDelete}
          messageType={messageTypeRef.current}
        />
      )}
      <div>
        {isSuccess && showMessage && (
          <div className='alert alert-success mt-3' role='alert'>
            Client has been successfully deleted!
          </div>
        )}
        {isError && showMessage && (
          <div className='alert alert-danger mt-3' role='alert'>
            Error deleting client. Please try again.
          </div>
        )}
        <div className='d-flex mb-3 flex-row align-items-center flex-wrap'>
          <div className='mr-auto p-2'>
            <div className='page-header'>
              <h3>Clients</h3>
            </div>
          </div>
          {useRenderComponentWithValidRole({
            roles,
            allowedRoles: [RoleEnum.Admin],
            children: (
              <div>
                <button
                  className='btn btn-outline-primary btn-sm mr-2'
                  onClick={() => setShowNewClientModal(true)}
                >
                  Create Client
                </button>
              </div>
            ),
          })}
          {showNewClientModal && (
            <NewClientModal onClose={() => setShowNewClientModal(false)} refetch={refetch} />
          )}
        </div>
        <div style={{ overflowY: 'auto' }}>
          <table className='table table-striped table-hover'>
            <thead>
              <tr>
                <th scope='col'>#</th>
                <th scope='col'>Client name</th>
                <th scope='col'>Number of employees</th>
                {useRenderComponentWithValidRole({
                  roles,
                  allowedRoles: [RoleEnum.Admin],
                  children: (
                    <>
                      <th scope='col'></th>
                      <th scope='col'></th>
                    </>
                  ),
                })}
              </tr>
            </thead>
            <tbody>
              {clients?.map((client, index) => (
                <tr key={index}>
                  <th scope='row'>{index + 1}</th>
                  <td>{client?.name}</td>
                  <td>{client?.numberOfEmployees}</td>
                  {useRenderComponentWithValidRole({
                    roles,
                    allowedRoles: [RoleEnum.Admin],
                    children: (
                      <>
                        <td>
                          <Link to={`/clients/${client.id}`}>
                            <button className='btn btn-outline-primary btn-sm'>
                              <BsPencil />
                            </button>
                          </Link>
                        </td>
                        <td>
                          <button
                            className='btn btn-outline-danger btn-sm'
                            onClick={() => handleDelete(client.id)}
                          >
                            <BsTrash />
                          </button>
                        </td>
                      </>
                    ),
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default Clients
