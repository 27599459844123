import { SidebarLogoProps } from 'interfaces/interfaces'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import logo from 'assets/images/klika-logo.svg'
import logoYellowA from 'assets/images/klika-logo-yellow-A.svg'

const SidebarBrandLogo: React.FC<SidebarLogoProps> = ({ sidebarCollapsed, isMobile }) => {
  const imageSrc: string = !sidebarCollapsed && !isMobile ? logo : logoYellowA

  return (
    <Link to='/' className='sidebar-brand d-flex align-items-center justify-content-center '>
      <img id='brand-logo' src={imageSrc} alt='klika logo' className='sidebar-brand-icon' />
      {!sidebarCollapsed && !isMobile && (
        <span className='text-lowercase font-weight-light brand-text'>emp</span>
      )}
    </Link>
  )
}
SidebarBrandLogo.propTypes = {
  sidebarCollapsed: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export default SidebarBrandLogo
