import { RoleEnum } from 'enums/RoleEnum'
import React from 'react'

interface Props {
  allowedRoles?: RoleEnum[]
  children: React.ReactNode
  roles: any
}

export const useRenderComponentWithValidRole = ({ roles, allowedRoles, children }: Props) => {
  const hasValidRole = allowedRoles?.some((element) => roles.includes(element))
  return hasValidRole ? children : null
}
