import React, { useState, useMemo } from 'react'
import { Form } from 'react-bootstrap'
import { useGetBenchProjectsQuery } from 'services/queries/api'
import Select from 'react-select'

interface BenchFilterProps {
  onFilterChange: (selectedProject: string) => void
}

const BenchFilter: React.FC<BenchFilterProps> = ({ onFilterChange }) => {
  const { data: projects = [], refetch: refetchBenchProjects } = useGetBenchProjectsQuery()
  const [selectedFilter, setSelectedFilter] = useState<{ project: string }>({ project: '' })

  const handleProjectChange = (selectedOption: any) => {
    const newProject = selectedOption.value === 'all' ? '' : selectedOption.value
    setSelectedFilter((prevState) => ({
      ...prevState,
      project: newProject,
    }))
    onFilterChange(newProject)
  }

  const projectOptions = useMemo(() => {
    const projectItems = projects?.map((project) => ({
      value: project.id,
      label: project.name,
    }))

    return [{ value: '', label: 'All Projects' }, ...projectItems]
  }, [projects])

  return (
    <div className='container mt-5 mb-5' style={{ marginLeft: '0' }}>
      <div className='row'>
        <div className='col-md-4'>
          <Form.Group controlId='projectSelect'>
            <Form.Label>Projects</Form.Label>
            <div onMouseDown={() => refetchBenchProjects()}>
              <Select
                options={projectOptions}
                value={projectOptions.find((option) => option.value === selectedFilter.project)}
                onChange={handleProjectChange}
                placeholder='Select a project'
              />
            </div>
          </Form.Group>
        </div>
      </div>
    </div>
  )
}

export default BenchFilter
