export class Department {
    id!: number
    name!: string
    type:'department'
    rules!: {
        viewDetails: boolean
        cud: boolean
    }

    constructor(department?: any) {
        if (department) {
            Object.assign(this, department)
        }
        this.type = 'department';
    }
}
