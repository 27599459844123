import { EmployeeBasic } from './employeeBasic.model'

export class NewTeam {
  id!: string | undefined
  name!: string
  projectId!: string | undefined
  directorId!: string | undefined
  engineeringManagerId!: string | undefined
  eemId!: string | undefined
  cdmId!: string | undefined
  teamLeadId!: string | undefined
  assignments!: EmployeeBasic[]

  constructor(team?: any) {
    if (team) {
      Object.assign(this, team)
    }
  }
}
