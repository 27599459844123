import SidebarLogo from './SidebarLogo'
import SidebarItem from './SidebarItem'
import PropTypes from 'prop-types'
import { SidebarItemProps, SidebarProps } from 'interfaces/interfaces'
import { RoleEnum } from 'enums/RoleEnum'
import { useSelector } from 'react-redux'
import { group } from 'console'

const Sidebar: React.FC<SidebarProps> = ({ sidebarCollapsed, isMobile }) => {
  const sidebarClass =
    (!sidebarCollapsed && !isMobile) || (sidebarCollapsed && isMobile) ? '' : 'toggled'
  const userRoles = useSelector((state: any) => state.token.value)
  const sidebarItems: SidebarItemProps[] = [
    {
      name: 'Organization',
      link: '/charts',
      icon: 'fa fa-sitemap',
      roles: [RoleEnum.User, RoleEnum.Admin, RoleEnum.EEM, RoleEnum.Marketing],
    },
    // {
    //   name: 'Clients',
    //   link: '/clients',
    //   icon: 'fa fa-building',
    //   roles: [RoleEnum.User, RoleEnum.Admin, RoleEnum.EEM, RoleEnum.Marketing],
    // },
    // {
    //   name: 'Projects',
    //   link: '/projects',
    //   icon: 'fa fa-rocket',
    //   roles: [RoleEnum.User, RoleEnum.Admin, RoleEnum.EEM, RoleEnum.Marketing],
    // },
    {
      name: 'Teams',
      link: '/teams',
      icon: 'fa fa-users',
      roles: [RoleEnum.User, RoleEnum.Admin, RoleEnum.EEM, RoleEnum.Marketing],
    },
    {
      name: 'Employees',
      link: '/employees',
      icon: 'fa fa-user-tie',
      roles: [RoleEnum.User, RoleEnum.Admin, RoleEnum.EEM, RoleEnum.Marketing],
    },
    {
      name: 'Departments',
      link: '/departments',
      icon: 'fa fa-users',
      roles: [RoleEnum.User, RoleEnum.Admin, RoleEnum.EEM, RoleEnum.Marketing],
    },
    {
      name: 'Bench',
      link: '/bench',
      icon: 'fa fa-couch',
      roles: [RoleEnum.Admin],
    }
  ];

  return (
    <ul
      className={`navbar-nav sidebar-dark sidebar accordion ${sidebarClass} `}
      id='accordionSidebar'
    >
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} isMobile={isMobile} />
      <hr className='sidebar-divider my-0' />
      {sidebarItems.map((item, index) => {
        if (item && item.roles?.some((element) => userRoles?.includes(element))) {
          return <SidebarItem key={index} name={item.name} link={item.link} icon={item.icon} />
        }
      })}
      <hr className='sidebar-divider d-none d-md-block' />
    </ul>
  )
}

Sidebar.propTypes = {
  sidebarCollapsed: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
}
export default Sidebar
