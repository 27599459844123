import React, { useState, useEffect } from 'react'
import { useGetEmployeesNamePositionQuery, useGetTeamRolesQuery } from 'services/queries/api'
import { EmployeeBasic } from 'models/employeeBasic.model'
import { PropertyFilter } from 'interfaces/interfaces'
import { EmployeeSelectHandler } from './EmployeeSelectHandler'
import Select from 'react-select'
import { Modal, Button } from 'react-bootstrap'
import { BsX } from 'react-icons/bs'
import { Assignment } from 'models/assignment.model'
import { TeamRole } from 'models/teamRole.model'
import DatePicker from 'react-datepicker'

export interface EmployeeSelectionFormProps {
  onClose: () => void
  onEmployeeSelect: (asgn: Assignment) => void
  selectedEmployees: (string | undefined)[]
  teamRoleId: number
  showModal: boolean
}

const EmployeeSelectionForm: React.FC<EmployeeSelectionFormProps> = ({
  onEmployeeSelect,
  onClose,
  selectedEmployees,
  teamRoleId,
  showModal,
}) => {
  const { data: employees } = useGetEmployeesNamePositionQuery()
  const [filteredOptions, setFilteredOptions] = useState<EmployeeBasic[]>([])
  const { data: teamRoles } = useGetTeamRolesQuery()
  const [assignData, setAssignData] = useState<Assignment>({ id: '' } as Assignment)

  useEffect(() => {
    if (employees) {
      setFilteredOptions(
        employees.filter(
          (employee: EmployeeBasic) =>
            !selectedEmployees?.some(
              (selectedEmployee: string | undefined) => selectedEmployee === employee.id,
            ),
        ),
      )
    }
  }, [employees])

  useEffect(() => {
    if (teamRoles) {
      const updatedData: Assignment = {
        employeeId: undefined,
        teamId: undefined,
        isDeleted: false,
        startDate: new Date(),
        teamRole: new TeamRole(
          teamRoleId,
          teamRoles?.find((role) => role.value == teamRoleId)?.label,
        ),
        rules: undefined,
      }
      setAssignData(updatedData)
    }
  }, [teamRoles])

  const handleChangeOfEmployee = (emp: any) => {
    setAssignData((prevData) => ({
      ...prevData,
      employeeId: emp.value,
      name: emp.label,
    }))
  }

  const handleDateChange = (fieldName: string, date: Date | null) => {
    setAssignData((prevData) => ({
      ...prevData,
      [fieldName]: date,
    }))
  }

  const handleTeamRoleChange = (e: any) => {
    setAssignData((prevData) => ({
      ...prevData,
      teamRole: new TeamRole(e.value, e.label),
    }))
  }

  const handleSubmitOfEmployee = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      onEmployeeSelect(assignData)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Modal
        backdrop='static'
        keyboard={false}
        show={showModal}
        onHide={onClose}
        dialogClassName='modal-xl d-flex align-items-center justify-content-center'
      >
        <Modal.Header>
          <Modal.Title>Assign Employee to the team</Modal.Title>
          <Button variant='link' className='close' onClick={onClose}>
            <BsX />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-4'>
            <label>Select Employee:</label>
            <Select
              onChange={handleChangeOfEmployee}
              name='employeeId'
              options={filteredOptions?.map((emp) => ({
                value: emp.id,
                label: emp.nameWithPosition,
              }))}
              required
              placeholder='Employees...'
            />
          </div>
          <div className='mb-4'>
            <label>Team role:</label>
            <Select
              onChange={handleTeamRoleChange}
              name='teamRole'
              value={teamRoles?.find((role) => role.value == assignData?.teamRole?.id)}
              options={teamRoles?.map((role) => ({
                value: role.value,
                label: role.label,
              }))}
              required
              isDisabled={false}
            />
          </div>
          <div className='row gx-3 mb-2 pt-1'>
            <div className='col-md-6 w-100'>
              <label>Start date</label>
              <DatePicker
                placeholderText='Start date'
                className='info-field bg-light-gray p-2 pl-3'
                selected={assignData?.startDate}
                onChange={(date: Date | null) => handleDateChange('startDate', date)}
                dateFormat='dd/MM/yyyy'
              />
            </div>
            <div className='col-md-6 w-100'>
              <label>End date</label>
              <DatePicker
                placeholderText='End date'
                className='info-field bg-light-gray p-2 pl-3'
                selected={assignData?.endDate}
                onChange={(date: Date | null) => handleDateChange('endDate', date)}
                dateFormat='dd/MM/yyyy'
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              className='btn btn-secondary'
              type='button'
              style={{ marginRight: 20 }}
              onClick={onClose}
            >
              Close
            </button>
            <button
              className='btn btn-primary'
              type='button'
              onClick={handleSubmitOfEmployee}
              disabled={assignData?.employeeId == undefined}
            >
              Assign
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EmployeeSelectionForm
