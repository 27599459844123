import React, { useState, useMemo, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { api, useGetClientsWithProjectsQuery } from 'services/queries/api'
import Select from 'react-select'

interface TeamsFilterProps {
  onFilterChange: (selectedClient: string, selectedProject: string) => void
}

const TeamsFilter: React.FC<TeamsFilterProps> = ({ onFilterChange }) => {
  const { data: clients = [], refetch: refetchClients } = useGetClientsWithProjectsQuery()
  const projects = clients
    ? clients.flatMap((client: any) =>
      Array.isArray(client.projects)
        ? client.projects.map((proj: any) =>
          proj
            ? {
              id: proj.id,
              name: proj.name,
              numberOfEmployees: proj.numberOfEmployees,
              teams: proj.teams,
              totalNumberOfEmployees: proj.totalNumberOfEmployees,
            }
            : null
        )
        : client.projects
          ? [
            {
              id: client.projects.id,
              name: client.projects.name,
              numberOfEmployees: client.projects.numberOfEmployees,
              teams: client.projects.teams,
              totalNumberOfEmployees: client.projects.totalNumberOfEmployees,
            },
          ]
          : []
    )
    : [];
  const [selectedFilter, setSelectedFilter] = useState<{
    client: string
    project: string
  }>({
    client: '',
    project: '',
  })
  const handleClientChange = (selectedOption: any) => {
    const newClient = selectedOption.value === 'all' ? '' : selectedOption.value
    setSelectedFilter((prevState) => ({
      ...prevState,
      client: newClient,
      project: '',
    }))
    onFilterChange(newClient, '')
  }

  const handleProjectChange = (selectedOption: any) => {
    const newProject = selectedOption.value === 'all' ? '' : selectedOption.value
    setSelectedFilter((prevState) => ({
      ...prevState,
      project: newProject,
    }))
    onFilterChange(selectedFilter.client, newProject)
  }

  const clientOptions = useMemo(
    () => [
      { value: '', label: 'All Clients' },
      ...clients.map((client) => ({
        value: client.id,
        label: client.name,
      })),
    ],
    [clients],
  );
  const commonItem = { value: '', label: 'All Projects' }

  const projectOptions = useMemo(() => {
    // if (selectedFilter.client === '') return [commonItem]

    if (selectedFilter.client !== '') {
      const clientProjects =
        clients.find((client: any) => client?.id === selectedFilter.client)?.projects || []
      const projectItems = clientProjects.map((project) => ({
        value: project.id,
        label: project.name,
      }))
      return [commonItem, ...projectItems]
    }
    const projectItems = projects.map((project) => ({
      value: project.id,
      label: project.name,
    }))

    return [commonItem, ...projectItems]
  }, [clients, selectedFilter.client])

  return (
    <div className='container mt-5 mb-5' style={{ marginLeft: '0' }}>
      <div className='row'>
        <div className='col-md-4'>
          <Form.Group controlId='clientSelect'>
            <div onMouseDown={() => refetchClients()}>
              <Select
                options={clientOptions}
                value={clientOptions.find((option) => option.value === selectedFilter.client)}
                onChange={handleClientChange}
                placeholder='Select a team'
              />
            </div>
          </Form.Group>
        </div>
        <div className='col-md-4'>
          <Form.Group controlId='projectSelect'>
            <Select
              options={projectOptions}
              value={projectOptions.find((option) => option.value === selectedFilter.project)}
              onChange={handleProjectChange}
              placeholder='Select a project'
            />
          </Form.Group>
        </div>
      </div>
    </div>
  )
}

export default TeamsFilter
