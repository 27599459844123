import { Link } from 'react-router-dom'
import avatar from 'assets/images/avatar.png'
import { useMsal } from '@azure/msal-react'
import { useGetEmployeePhotoByAzureIdQuery } from 'services/queries/api'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useGetAuthQuery } from 'services/queries/apiAuth'
import { SignOutButton } from 'components/SignOutButton'

const SidebarUser: React.FC = () => {
  const roles = useSelector((state: any) => state.token.value)

  const { instance } = useMsal()
  const accountId = instance.getAllAccounts()[0]?.homeAccountId.split('.')[0]
  const { isSuccess } = useGetAuthQuery()
  const { data: employee, refetch } = useGetEmployeePhotoByAzureIdQuery(accountId, {
    skip: !isSuccess,
  })
  const imageUrl = employee?.photo ? 'data:image/png;base64,' + employee.photo : avatar
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event: MouseEvent) => {

    if (event.target instanceof Node && !document.getElementById('signOutPnl')?.contains(event.target)) {
      setIsPanelOpen(false);
    }
  };

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  useEffect(() => {
    if (roles) {
      refetch()
    }
  }, [roles])

  return (
    <div id='signOutPnl' className='mr-2'>
      <img className='img-profile xs rounded-circle' src={imageUrl} style={{ maxWidth: '30px', height: 'auto' }} />
      <span className='ml-2'>{employee?.name}</span>
      <span
        className='arrow'
        onClick={togglePanel}
        style={{ cursor: 'pointer', marginLeft: '5px' }}
      >
        {isPanelOpen ? '▲' : '▼'}
      </span>

      {isPanelOpen && (
        <div className='card' style={{ width: '12rem', position: 'absolute', top: '100%', borderRadius: '0.5rem', }}>
          <div className='card-body'>
            <div className='mb-2'>
              <Link className='mb-0' to={`/singleEmployee/${employee?.id}`} style={{ color: 'dimgray' }}>
                My profile
              </Link>
            </div>
            <div className='border-top'>
              <SignOutButton />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SidebarUser