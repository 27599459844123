import Loader from './Loader';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function SpinnerComponent() {
    const navigate = useNavigate()

    const path = localStorage.getItem('path')

    useEffect(() => {
        setTimeout(() => {
            navigate(path == null || path == '/' ? '/singleEmployee/' : path)
        }, 500)
    }, [])

    return (
        <Loader></Loader>
    )
}

export default SpinnerComponent
