import React, { useState, useRef } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { BsX } from 'react-icons/bs'
import { MessageTypeEnum } from 'enums/MessageTypeEnum'

interface Props {
  showModal: boolean
  onClose: () => void
  message: string | undefined
  onChange: (newValue: boolean) => void
  messageType: MessageTypeEnum
}
const ModalShowMessage: React.FC<Props> = ({
  showModal,
  onClose,
  message,
  onChange,
  messageType,
}) => {
  const styleRef = useRef<React.CSSProperties>({ color: 'black' })

  const warningStyle: React.CSSProperties = {
    color: 'red',
  }

  const infoStyle: React.CSSProperties = {
    color: 'DarkTurquoise',
  }

  const questionStyle: React.CSSProperties = {
    color: 'red',
  }

  switch (messageType) {
    case MessageTypeEnum.warning:
      styleRef.current = warningStyle
      break
    case MessageTypeEnum.information:
      styleRef.current = infoStyle
      break
    case MessageTypeEnum.question:
      styleRef.current = questionStyle
      break
  }

  const handleYesClick = () => {
    onChange(true)
    onClose()
  }

  return (
    <>
      <Modal
        backdrop='static'
        keyboard={false}
        show={showModal}
        onHide={onClose}
        dialogClassName='modal-m'
        style={{ top: '40%', right: '40%' }}
      >
        <Modal.Header>
          <Modal.Title>
            <div style={styleRef.current}>{messageType}</div>
          </Modal.Title>
          <Button variant='link' className='close' onClick={onClose}>
            <BsX />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-4'>{message}</div>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          {messageType == MessageTypeEnum.question && (
            <>
              <Button variant='outline-danger' onClick={handleYesClick}>
                Yes
              </Button>
              <Button variant='outline-danger' onClick={onClose}>
                No
              </Button>
            </>
          )}

          {messageType == MessageTypeEnum.information && (
            <>
              <Button variant='outline-info' onClick={onClose}>
                OK
              </Button>
            </>
          )}

          {messageType == MessageTypeEnum.warning && (
            <>
              <Button variant='outline-info' onClick={onClose}>
                OK
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalShowMessage
