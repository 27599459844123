import { SignOutButton } from 'components/SignOutButton'
import PropTypes from 'prop-types'
import SidebarUser from './SidebarUser'

interface NavbarProps {
  onToggleSidebar: () => void
  sidebarCollapsed: boolean
  isMobile: boolean
}

const Navbar: React.FC<NavbarProps> = ({ onToggleSidebar, sidebarCollapsed, isMobile }) => {
  const navSidebarOpenClass = !sidebarCollapsed && !isMobile ? 'mr-nav-open' : ''
  const navMobileClass = !sidebarCollapsed && isMobile ? 'mr-nav-mobile' : ''
  const navSidebarCollapsedClass =
    (sidebarCollapsed && !isMobile) || (sidebarCollapsed && isMobile) ? 'mr-nav-collapsed' : ''
  const navClassNames = `sign-out-button ml-auto ${navSidebarOpenClass} ${navMobileClass} ${navSidebarCollapsedClass}`
  return (
    <nav className='navbar navbar-expand navbar-light bg-white mb-4 shadow position-fixed w-100'>
      <button className=' border-0' id='sidebarToggle' onClick={onToggleSidebar}>
        <i className='fas fa-bars'></i>
      </button>
      <div className={navClassNames}>
        <SidebarUser />
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  onToggleSidebar: PropTypes.func.isRequired,
  sidebarCollapsed: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export default Navbar
