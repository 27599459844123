import { SidebarItemProps } from 'interfaces/interfaces'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const SidebarItem: React.FC<SidebarItemProps> = ({ link, icon, name }) => {
  return (
    <li className='nav-item active'>
      <div className='d-flex align-items-center'>
        <Link to={link} className='nav-link'>
          <i className={`fas nav-icon ${icon}`}></i>
          <span className='nav-link-text'>{name}</span>
        </Link>
      </div>
    </li>
  )
}

SidebarItem.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default SidebarItem
