export class Client {
  id!: string
  name!: string
  numberOfEmployees!: number

  rules!: {
    viewDetails: boolean
    cud: boolean
  }

  constructor(client?: any) {
    if (client) {
      Object.assign(this, client)
    }
  }
}
