import { BsTrash, BsPencil, BsPlusSquare } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react'
import { useRenderComponentWithValidRole } from 'hooks/useRenderComponentWithValidRole'
import { RoleEnum } from 'enums/RoleEnum'
import { useSelector } from 'react-redux'
import { useDeleteProjectMutation, useGetProjectsQuery } from 'services/queries/api'
import ModalShowMessage from 'components/ModalShowMessage'
import { MessageTypeEnum } from 'enums/MessageTypeEnum'
import CreateProjectModal from './NewProjectModal'

const Projects: React.FC = () => {
  const { data: projects, refetch } = useGetProjectsQuery()
  const [deleteProject, { isSuccess, isError }] = useDeleteProjectMutation()
  const [showMessage, setShowMessage] = useState(true)
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false)
  const idRef = useRef<string>('')
  const messageRef = useRef<string>()
  const messageTypeRef = useRef<MessageTypeEnum>(MessageTypeEnum.information)
  const [showNewProjectModal, setShowNewProjectModal] = useState(false)
  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (isSuccess || isError) {
      setShowMessage(true)
      const timer = setTimeout(() => {
        setShowMessage(false)
      }, 1500)
      return () => clearTimeout(timer)
    }
  }, [isSuccess, isError])

  const callDelete = async () => {
    try {
      await deleteProject(idRef.current).unwrap()
      refetch()
    } catch (error: any) {
      messageRef.current = error.data.message
      messageTypeRef.current = MessageTypeEnum.information
      setIsMessageBoxOpen(true)
      console.log(error)
    }
  }

  const handleDelete = (id: any) => {
    messageRef.current = 'Are you sure you want to delete project?'
    idRef.current = id
    messageTypeRef.current = MessageTypeEnum.question
    setIsMessageBoxOpen(true)
  }

  const closeMessageBox = () => {
    setIsMessageBoxOpen(false)
  }

  const roles = useSelector((state: any) => state.token.value)
  return (
    <>
      {isMessageBoxOpen && (
        <ModalShowMessage
          onClose={closeMessageBox}
          showModal={isMessageBoxOpen}
          message={messageRef.current}
          onChange={callDelete}
          messageType={messageTypeRef.current}
        />
      )}
      <div>
        {isSuccess && showMessage && (
          <div className='alert alert-success mt-3' role='alert'>
            The project has been successfully deleted!
          </div>
        )}
        {isError && showMessage && (
          <div className='alert alert-danger mt-3' role='alert'>
            Error deleting project. Please try again.
          </div>
        )}
      </div>
      <div className='d-flex mb-3 flex-row align-items-center flex-wrap'>
        <div className='mr-auto p-2'>
          <div className='page-header'>
            <h3>Projects</h3>
          </div>
        </div>
        {useRenderComponentWithValidRole({
          roles,
          allowedRoles: [RoleEnum.Admin],
          children: (
            <div>
              <button
                className='btn btn-outline-primary btn-sm mr-2'
                onClick={() => setShowNewProjectModal(true)}
              >
                Create Project
              </button>
            </div>
          ),
        })}
        {showNewProjectModal && (
          <CreateProjectModal onClose={() => setShowNewProjectModal(false)} refetch={refetch} />
        )}
      </div>
      <div style={{ overflowY: 'auto' }}>
        <table className='table table-striped table-hover'>
          <thead>
            <tr>
              <th scope='col'>#</th>
              <th scope='col'>Name</th>
              <th scope='col'>Client</th>
              {useRenderComponentWithValidRole({
                roles,
                allowedRoles: [RoleEnum.Admin],
                children: (
                  <>
                    <th scope='col'></th>
                    <th scope='col'></th>
                  </>
                ),
              })}
            </tr>
          </thead>
          <tbody>
            {projects?.map((project, index) => (
              <tr key={index + 1}>
                <th scope='row'>{index + 1}</th>
                <td>{project?.name}</td>
                <td>{project?.client?.name}</td>
                {useRenderComponentWithValidRole({
                  roles,
                  allowedRoles: [RoleEnum.Admin],
                  children: (
                    <>
                      <td>
                        <Link to={`/projects/${project.id}`}>
                          <button className='btn btn-outline-primary btn-sm'>
                            <BsPencil />
                          </button>
                        </Link>
                      </td>
                      <td>
                        <button
                          className='btn btn-outline-danger btn-sm'
                          onClick={() => handleDelete(project.id)}
                        >
                          <BsTrash />
                        </button>
                      </td>
                    </>
                  ),
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
export default Projects
