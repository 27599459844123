import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { DropdownItem } from 'interfaces/interfaces'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
    FormDatePicker,
    FormSelect,
} from './formComponents'
import DefaultButton from 'components/DefaultButton'
import { UpdateAssignment } from 'models/assignment.model'
import { Modal, Button } from 'react-bootstrap'
import { BsX } from 'react-icons/bs'
import { useCreateAssignmentMutation, useGetEmployeesNamePositionQuery, useGetTeamsQuery, useUpdateAssignmentMutation } from 'services/queries/api'
import { Action } from 'enums/ActionEnum'
import { ToastContainer, toast } from 'react-toastify'
import { isEndDateValid } from 'utils/helpers'
interface AssignmentFormProps {
    action: Action
    assignment?: UpdateAssignment
    employeeId?: string | undefined
    teamId?: string | undefined
    onClose: () => void
    showModal: boolean
    teamRoles: DropdownItem[] | undefined
}

const AssignEmployeeForm: FC<AssignmentFormProps> = ({ action, assignment, employeeId, teamId, onClose, showModal, teamRoles }) => {
    const [createAssignment] = useCreateAssignmentMutation()
    const [updateAssignment] = useUpdateAssignmentMutation()
    const form = useForm<UpdateAssignment>({
        defaultValues: assignment,
        mode: 'onBlur',
    })

    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { errors, isDirty, isValid },
    } = form

    const [
        team,
        employee,
        teamRole
    ] = watch([
        'teamId',
        'employeeId',
        'teamRoleId'
    ])

    useEffect(() => {
        if (showModal) {
            reset(assignment)
        }
    }, [reset, assignment, showModal])

    const { data: employees } = useGetEmployeesNamePositionQuery()
    const { data: teams } = useGetTeamsQuery()

    const onSubmit = async (assignment: UpdateAssignment) => {
        try {
            if (action == Action.CREATE) {
                await createAssignment(assignment).unwrap()
            }
            else {
                await updateAssignment(assignment).unwrap()
            }
            toast.success('Your changes have been successfully updated!')
            onClose()
        }
        catch (error) {
            console.log(error)
            toast.error('Save error')
        }
    }

    return (
        <>
            <Modal
                backdrop='static'
                keyboard={false}
                show={showModal}
                onHide={onClose}
                dialogClassName='modal-l modal-dialog-centered d-flex align-items-center justify-content-center'
            >
                <form className='col-6 form-max-width' onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Modal.Header>
                        <Modal.Title>Assign Employee to Team</Modal.Title>
                        <Button variant='link' className='close' onClick={onClose}>
                            <BsX />
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-4'>
                            <FormSelect
                                label='Employee'
                                id='employeeId'
                                control={control}
                                error={errors.employeeId}
                                options={employees?.map((employee) => {
                                    const dropdowns: DropdownItem = {
                                        value: employee.id,
                                        label: employee.nameWithPosition,
                                    };
                                    return dropdowns;
                                })}
                                className='col-md-12 mb-2'
                                disabled={employeeId != undefined || action == Action.UPDATE}
                            />
                        </div>

                        <div className='mb-4'>
                            <FormSelect
                                label='Team'
                                id='teamId'
                                control={control}
                                error={errors.teamId}
                                options={teams?.map((team) => {
                                    const dropdowns: DropdownItem = {
                                        value: team.id,
                                        label: team.name,
                                    };
                                    return dropdowns;
                                })}
                                className='col-md-12 mb-2'
                                disabled={teamId != undefined || action == Action.UPDATE}
                            />
                        </div>

                        <div className='mb-4'>
                            <FormSelect
                                label='Team role'
                                id='teamRoleId'
                                control={control}
                                error={errors.teamRoleId}
                                options={teamRoles}
                                className='col-md-12 mb-2'
                            />
                        </div>

                        <div className='row gx-12 mb-3 pt-1'>
                            <div className='col-md-6'>
                                <FormDatePicker
                                    label='Start date'
                                    className='col-md-12'
                                    id='startDate'
                                    control={control}
                                    error={errors.startDate}
                                    dateFormat='dd/MM/yyyy'
                                />
                            </div>
                            <div className='col-md-6'>
                                <FormDatePicker
                                    label='End date'
                                    className='col-md-12'
                                    id='endDate'
                                    control={control}
                                    rules={{
                                        validate: (endDate: Date) =>
                                            isEndDateValid(form.getValues('startDate') as Date, endDate) ||
                                            'End date must be after the start date.',
                                    }}
                                    error={errors.endDate}
                                    dateFormat='dd/MM/yyyy'
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='d-flex justify-content-end mr-3'>
                            <div className='mr-4'>
                                <DefaultButton
                                    type='button'
                                    color='outline-secondary'
                                    onClick={onClose}
                                >
                                    Close
                                </DefaultButton>
                            </div>
                            <DefaultButton
                                type='submit'
                                color='outline-primary'
                                disabled={team == undefined || employee == undefined || teamRole == undefined}
                            >
                                Save
                            </DefaultButton>
                        </div>
                    </Modal.Footer>
                </form >
                <ToastContainer autoClose={2000} className='mt-5' />
            </Modal>
        </>
    )
}
export default AssignEmployeeForm