import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig, loginApiRequest } from 'config/authConfig'
import { setToken } from 'services/slices/tokenSlice'
import { parseJwt } from 'utils/helpers'

const BASE_URL = process.env.REACT_APP_BASE_URL as string

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}api/auth/`,
    mode: 'cors',
    credentials: 'include',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    prepareHeaders: async (headers: any) => {
      const msalInstance = new PublicClientApplication(msalConfig)
      const account = msalInstance.getAllAccounts()[0]
      const msalResponse = await msalInstance.acquireTokenSilent({
        ...loginApiRequest,
        account: account,
      })
      if (msalResponse.idToken) {
        headers.set('Authorization', `Bearer ${msalResponse.idToken}`)
      }
      headers.set('Content-Type', 'application/json')
      return headers
    },
  }),
  endpoints: (builder) => ({
    getAuth: builder.query<any, void>({
      query() {
        return {
          url: 'signin',
        }
      },
      transformResponse: (result: { data: { access_token: any } }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const token = data.access_token
          const payload = parseJwt(token)
          const getRoleValue = (payload: any) =>
            payload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
          const role = getRoleValue(payload)
          dispatch(setToken(role))
        } catch (error) {
          console.log('error', error)
        }
      },
    }),
    logoutUser: builder.mutation<void, void>({
      query() {
        return {
          url: 'signOut',
          credentials: 'include',
        }
      },
    }),
  }),
})

export const { useGetAuthQuery, useLogoutUserMutation } = authApi
