import React, { useState } from 'react'
import { InputGroup, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'

interface Props {
  label: string
  id: string
  type?: 'text' | 'number' | 'tel' | 'email'
  register: any
  error?: any
  required?: boolean
  className?: string
  disabled?: boolean
  showWarning?: boolean
  tooltip?: string;
  tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left'
  placeholder?: string
}

const FormInput: React.FC<Props> = ({
  label,
  id,
  type = 'text',
  register,
  error = null,
  required = false,
  className = 'col-md-12 mb-2',
  disabled = false,
  showWarning = false,
  tooltip,
  tooltipPlacement = 'right',
  placeholder

}) => {

  return (
    <div className={className}>
      <label htmlFor={id} className='form-label'>
        {label} {required && <span className='text-danger'>*</span>}
      </label>
      <InputGroup hasValidation>
        {tooltip && showWarning ? (
          <OverlayTrigger
            key={tooltipPlacement}
            placement={tooltipPlacement}
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip className='ml-2' id={`tooltip-${tooltipPlacement}`}>{tooltip}</Tooltip>}
          >
            <Form.Control
              type={type}
              isInvalid={showWarning}
              className='custom-input-color'
              disabled={disabled}
              id={id}
              {...register(id, { required })}
              placeholder={placeholder}
            />
          </OverlayTrigger>
        ) : (
          <Form.Control
            type={type}
            isInvalid={showWarning}
            className='custom-input-color'
            disabled={disabled}
            id={id}
            {...register(id, { required })}
            placeholder={placeholder}
          />
        )}
      </InputGroup>
      {error && <p className='text-danger'>{error.message}</p>}
    </div>
  )
}

export default FormInput
