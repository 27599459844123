import React, { FC, useState, useEffect } from 'react'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'bootstrap/dist/css/bootstrap.min.css'
import { EmployeeFormValues } from 'models/employeeFormValues.model'
import {
  FormInput,
  FormSelect,
  FormDatePicker,
  FormCheckbox,
} from '../../../components/formComponents'
import DefaultButton from 'components/DefaultButton'
import { PersonalDropdownData } from 'models/dropdownData.model'
import { ADWarning } from 'enums/ADWarning'
import ImageInput from 'components/formComponents/FileInput'
// --- DevTool test react hooks form ---
// import { DevTool } from '@hookform/devtools'
// --- Validations yup
// import { yupResolver } from '@hookform/resolvers/yup'
// import userUpdateFormSchema from '../validation/schemas/UserUpdateFormSchema'

interface EmployeeFormProps {
  employee: EmployeeFormValues
  onSubmit: (data: any) => void
  dropdownData: PersonalDropdownData | undefined
}

const PersonalInformationForm: FC<EmployeeFormProps> = ({ employee, onSubmit, dropdownData }) => {
  // TODO: Update default values when add validations from yup
  const form = useForm<EmployeeFormValues>({
    defaultValues: {
      id: employee.id,
      firstName: employee.firstName,
      lastName: employee.lastName,
      nameOfOneParent: employee.nameOfOneParent,
      jmbg: employee.jmbg,
      dateOfBirth: employee.dateOfBirth,
      gender: employee.gender,
      personalEmailAddress: employee.personalEmailAddress,
      personalPhoneNumber: employee.personalPhoneNumber,
      currentAddressName: employee.currentAddressName,
      currentAddressCity: employee.currentAddressCity,
      currentAddressCountry: employee.currentAddressCountry,
      legalResidenceAddressName: employee.legalResidenceAddressName,
      legalResidenceAddressCity: employee.legalResidenceAddressCity,
      legalResidenceAddressCountry: employee.legalResidenceAddressCountry,
      emergencyContactFullName: employee.emergencyContactFullName,
      emergencyContactRelationToEmployee: employee.emergencyContactRelationToEmployee,
      emergencyContactPhoneNumber: employee.emergencyContactPhoneNumber,
      levelOfEducation: employee.levelOfEducation,
      professionalTitle: employee.professionalTitle,
      tShirtSize: employee.tShirtSize,
      shoesSize: employee.shoesSize,
      profileImage: employee.profileImage,
      externalUserId: employee.externalUserId,
      employeeChildren: employee.employeeChildren ?? [
        {
          gender: 1,
          birthYear: '2023',
        },
      ],
    },
    // Use yup validations - userUpdateFormSchema
    // resolver: yupResolver(userUpdateFormSchema),
    mode: 'onBlur',
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty, isValid, dirtyFields },
  } = form
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'employeeChildren',
  })

  const onSubmitForm = (data: EmployeeFormValues) => {
    if (data.dateOfBirth !== null) {
      data.dateOfBirth = moment(data.dateOfBirth).format('YYYY-MM-DD')
    }
    onSubmit(data)
  }

  const [isCheckedAddress, setIsCheckedAddress] = useState(true)

  const [
    currentAddressName,
    currentAddressCity,
    currentAddressCountry,
    legalResidenceAddressName,
    legalResidenceAddressCity,
    legalResidenceAddressCountry,
  ] = watch([
    'currentAddressName',
    'currentAddressCity',
    'currentAddressCountry',
    'legalResidenceAddressName',
    'legalResidenceAddressCity',
    'legalResidenceAddressCountry',
  ])

  const [initialAddress, setInitialAddress] = useState({
    address: '',
    city: '',
    country: '',
  })
  useEffect(() => {
    if (!isCheckedAddress) {
      setInitialAddress({
        address: currentAddressName ?? '',
        city: legalResidenceAddressCity ?? '',
        country: currentAddressCountry ?? '',
      })

      setValue('currentAddressName', legalResidenceAddressName)
      setValue('currentAddressCity', legalResidenceAddressCity)
      setValue('currentAddressCountry', legalResidenceAddressCountry)
    }
  }, [
    legalResidenceAddressName,
    legalResidenceAddressCity,
    legalResidenceAddressCountry,
    setValue,
    isCheckedAddress,
    watch,
  ])
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedAddress(event.target.checked)
    if (event.target.checked) {
      setValue('currentAddressName', '')
      setValue('currentAddressCity', '')
      setValue('currentAddressCountry', '')
    }
  }
  useEffect(() => {
    if (
        currentAddressName === legalResidenceAddressName &&
        currentAddressCity === legalResidenceAddressCity &&
        currentAddressCountry === legalResidenceAddressCountry
    ) {
      setIsCheckedAddress(false)
    }
  }, [])

  return (
      <div className='row justify-content-left'>
        <div className='col-md-12 mt-3 mb-1 p-3'>
          <h4>Personal Information</h4>
        </div>
        <hr className='mb-5 ml-0 mr-0' />
        <form
            className='col-6 form-max-width'
            onSubmit={handleSubmit(onSubmitForm)}
            noValidate
            autoComplete='off'
        >
          {
            // #region Personal Information
          }
          <div className='row pl-3 pr-3 mb-2'>
            <FormInput
                label='First name'
                id='firstName'
                register={register}
                error={errors.firstName}
                showWarning={dirtyFields.firstName}
                tooltip={ADWarning}
                className='col-md-4'
            />
            <FormInput
                label="Parent's first name"
                id='nameOfOneParent'
                register={register}
                error={errors.nameOfOneParent}
                className='col-md-4'
            />
            <FormInput
                label='Last name'
                id='lastName'
                register={register}
                error={errors.lastName}
                showWarning={dirtyFields.lastName}
                tooltip={ADWarning}
                className='col-md-4'
            />
          </div>

          <div className='row pl-3 pr-3 mb-2'>
            <FormDatePicker
                label='Date of birth'
                id='dateOfBirth'
                control={control}
                error={errors.dateOfBirth}
                dateFormat='dd/MM/yyyy'
                className='col-md-4'
            />
            <FormInput
                label='JMBG'
                id='jmbg'
                register={register}
                error={errors.jmbg}
                className='col-md-4'
            />
          </div>
          <FormSelect
              label='Gender'
              id='gender'
              control={control}
              error={errors.gender}
              options={dropdownData?.genders}
              className='col-md-4 mb-2'
          />
          {<ImageInput label='Image' id='profileImage' setValue={setValue} photoUrl={employee?.photoUrl}/>}
          {
            // #endregion
          }

          {
            // #region Employee address
          }

          <div className='col-md-12 row justify-content-left mt-5 mb-2 ml-0'>
            <h5>Employee address</h5>
          </div>
          <div className='col-md-12 row justify-content-left mt-0 mb-2 ml-0'>
            <h6>Legally registered address of residence</h6>
          </div>

          <FormInput
              label='Street name and number'
              id='legalResidenceAddressName'
              register={register}
              error={errors.legalResidenceAddressName}
          />

          <div className='row pl-3 pr-3 mb-2'>
            <FormInput
                label='City'
                id='legalResidenceAddressCity'
                register={register}
                error={errors.legalResidenceAddressCity}
                className='col-md-6'
            />
            <FormInput
                label='Country'
                id='legalResidenceAddressCountry'
                register={register}
                error={errors.legalResidenceAddressCountry}
                className='col-md-6'
            />
          </div>

          <div>
            <div>{isCheckedAddress}</div>
            <div>
              <FormCheckbox
                  id='addressCheck'
                  label='Current living address is different than the legally registered one'
                  onChange={handleCheckboxChange}
                  isChecked={isCheckedAddress}
              />
            </div>
          </div>
          {isCheckedAddress && (
              <>
                <div className='col-md-12 row justify-content-left mt-5 mb-2 ml-0'>
                  <h6>Current address</h6>
                </div>
                <FormInput
                    label='Street name and number'
                    id='currentAddressName'
                    register={register}
                    error={errors.currentAddressName}
                />
                <div className='row pl-3 pr-3 mb-2'>
                  <FormInput
                      label='City'
                      id='currentAddressCity'
                      register={register}
                      error={errors.currentAddressCity}
                      className='col-md-6'
                  />
                  <FormInput
                      label='Country'
                      id='currentAddressCountry'
                      register={register}
                      error={errors.currentAddressCountry}
                      className='col-md-6'
                  />
                </div>
              </>
          )}
          {
            // #endregion
          }

          {
            // #region Personal contact information
          }

          <div className='col-md-12 row justify-content-left mt-5 ml-0  mb-2'>
            <h5>Personal contact information</h5>
          </div>
          <FormInput
              label='Email address'
              id='personalEmailAddress'
              register={register}
              error={errors.personalEmailAddress}
              type='email'
              className='col-md-8'
              placeholder='name@domain.com'
          />
          <FormInput
              label='Phone number'
              id='personalPhoneNumber'
              register={register}
              error={errors.personalPhoneNumber}
              type='tel'
              className='col-md-8'
              placeholder='+38765123456'
          />

          {
            // #endregion
          }

          {
            // #region Emergency contact information
          }

          <div className='col-md-12 row justify-content-left mt-5  mb-2 ml-0'>
            <h5>Emergency contact information</h5>
          </div>
          <div className='row pl-3 pr-3 mb-2'>
            <FormInput
                label='Full name'
                id='emergencyContactFullName'
                register={register}
                error={errors.currentAddressName}
                className='col-md-8'
            />
            <FormInput
                label='Relation to employee'
                id='emergencyContactRelationToEmployee'
                register={register}
                error={errors.emergencyContactRelationToEmployee}
                className='col-md-4'
            />
          </div>
          <FormInput
              label='Phone number'
              id='emergencyContactPhoneNumber'
              register={register}
              error={errors.emergencyContactPhoneNumber}
              type='tel'
              className='col-md-8'
              placeholder='+38765123456'
          />

          {
            // #endregion
          }

          {
            // #region Education
          }

          <div className='col-md-12 row justify-content-left mt-5  mb-2 ml-0'>
            <h5>Education</h5>
          </div>
          <div className='row pl-3 pr-3 mb-2'>
            <FormInput
                label='Level of education'
                id='levelOfEducation'
                register={register}
                error={errors.levelOfEducation}
                className='col-md-4'
            />
            <FormInput
                label='Professional title'
                id='professionalTitle'
                register={register}
                error={errors.professionalTitle}
                className='col-md-8'
            />
          </div>

          {
            // #endregion
          }

          {
            // #region Miscelenious
          }

          <div className='col-md-12 row justify-content-left mt-5  mb-2 ml-0'>
            <h5>Merch sizes</h5>
            <div className='col-md-12 row justify-content-left mb-2'>
              <h6 style={{ color: 'gray' }}>This information is used for promotional merchendise.</h6>
            </div>
          </div>
          <div className='row pl-3 pr-3 mb-2'>
            <FormSelect
                label='Shirt size'
                id='tShirtSize'
                control={control}
                error={errors.tShirtSize}
                options={dropdownData?.tShirtSizes}
                className='col-md-4'
            />
            <FormSelect
                label='Shoes size'
                id='shoesSize'
                control={control}
                error={errors.shoesSize}
                options={dropdownData?.shoeSizes}
                className='col-md-4'
            />
          </div>

          <div className='row justify-content-left mb-2 ml-0'>
            <div className='col-md-12 row justify-content-left mt-5  mb-2 ml-0'>
              <h5>Children information</h5>
              <div className='col-md-12 row justify-content-left mb-2'>
                <h6 style={{ color: 'gray' }}>
                  This information is used for organizing events for children.
                </h6>
              </div>
            </div>
            {fields.length !== 0 ? (
                fields.map((field, index) => {
                  return (
                      <section className='row col-md-12 mb-2' key={field.id}>
                        <FormSelect
                            label='Gender'
                            id={`employeeChildren.${index}.gender`}
                            control={control}
                            options={dropdownData?.genders}
                            className='col-md-4 justify-content-left mb-2 ml-0'
                            showLabel={index === 0 ? true : false}
                        />
                        <div className='col-md-4 pr-0'>
                          {index === 0 && <label className='form-label'>Birth year</label>}
                          <Controller
                              control={control}
                              name={`employeeChildren.${index}.birthYear`}
                              render={({ field }) => (
                                  <DatePicker
                                      className='form-control'
                                      selected={field.value ? new Date(field.value) : null}
                                      onChange={(date) => field.onChange(moment(date).format('YYYY'))}
                                      showYearPicker
                                      dateFormat='yyyy'
                                  />
                              )}
                          />
                        </div>
                        <div className='d-flex align-items-end'>
                          <div className='col-md-3 mb-2'>
                            <DefaultButton onClick={() => remove(index)} tooltip='Remove'>
                              <i className='fa fa-trash'></i>
                            </DefaultButton>
                          </div>
                        </div>
                      </section>
                  )
                })
            ) : (
                <div className='col-md-4'>
                  <input type='text' value='No children' disabled className='form-control' />
                </div>
            )}
          </div>

          <div className='col-md-12 row justify-content-left mb-2 ml-0'>
            <DefaultButton
                color='outline-primary'
                onClick={() => {
                  append({
                    gender: 1,
                    birthYear: moment().format('YYYY'),
                  })
                }}
            >
              <i className='fa fa-plus'></i> Add Child
            </DefaultButton>
          </div>

          {
            // #endregion
          }

          <div className='row'>
            <div className='col-md-3 ml-md-auto'>
              <button
                  className='btn btn-primary btn-ml d-flex ml-auto'
                  type='submit'
                  // disabled={!isDirty || !isValid}
                  color='outline-primary'
              >
                Save
              </button>
            </div>
          </div>
        </form>
        {/* <DevTool control={control} /> */}
      </div>
  )
}

export default PersonalInformationForm
