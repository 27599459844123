/* eslint-disable react/prop-types */
import React from 'react'
import { MdClose } from 'react-icons/md'
import { useGetEmployeeQuery, useGetTeamQuery } from '../../../../services/queries/api';
import { Link } from 'react-router-dom';
import avatar from 'assets/images/undraw_profile.svg'
import { getFullName } from 'models/employee.model';

const styles = {
  card: {
    position: 'absolute',
    top: '60px',
    left: '0',
    width: '25%',
    height: '55%',
    padding: '2rem',
    margin: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    border: '1px solid #d3d3d3',
    overflowY: 'scroll',
  },
  cardCloseBtn: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    width: '30px',
    height: '30px',
    color: '#227c9d',
    backgroundColor: '#fef9ef',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #227c9d',
    cursor: 'pointer',
  },
  // card::-webkit-scrollbar: {
  //   display: none;
  // },
  cardHeader: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
  cardImg: {
    width: '14px',
    borderWidth: '1rem',
    margin: '0.5rem 0.5rem 0.8rem',
  },
  cardAvatar: {
    width: '120px',
    borderRadius: '1rem',
  },
  cardName: {
    marginTop: '1rem',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  cardRole: {
    margin: '1rem 0',
    fontSize: '1.2rem',
  },
  cardBody: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },
  cardBodyTeamMembers: {
    marginTop: '1rem',
    height: '26vh',
    overflowY: 'scroll',
  },
  cardItem: {
    width: '100%',
    margin: '0.5rem 0',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '0.9rem',
  },
  cardItemLabel: {
    margin: '0.5rem 0',
    fontWeight: 'bold',
  },
  cardItemValue: {
    margin: '0.5rem 0',
    textAlign: 'justify',
  },
  cardItemTeam: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  cardItemImg: {
    width: '50px',
    height: '50px',
    margin: '0.2rem',
    borderRadius: '50%',
  },
  cardItemName: {
    marginLeft: '0.5rem',
  },
  cardItemRole: {
    fontSize: '0.8rem',
    marginLeft: '0.5rem',
  },
}

const EntityDetailsCard = (props) => {
  const { data: team } = useGetTeamQuery(props.entity.id)
  const { data: employee } = useGetEmployeeQuery(props.entity.id)
  const imageUrl = props.entity.photo ? 'data:image/png;base64,' + props.entity.photo : avatar

  if (props.entity.type === 'team')
    return (
      <div style={styles.card}>
        <button style={styles.cardCloseBtn} onClick={props.handleClose}>
          <MdClose />
        </button>
        <div>
          <div style={styles.cardHeader}>
            <h2>{team?.name}</h2>
          </div>
          <h4>Team Members:</h4>
          <div style={styles.cardBodyTeamMembers}>
            {team?.assignments
              .map((assignment) => (
                <div style={styles.cardItemTeam} key={assignment?.employeeId}>
                  <p style={styles.cardItemName}><b>{assignment?.name}</b>  {assignment?.teamRole.name}
                    <Link to={`/singleEmployee/${assignment?.employeeId}`}><img style={styles.cardImg} src={'https://cdn.pixabay.com/photo/2012/04/23/16/12/click-38743_1280.png'} /></Link>
                  </p>
                </div>
              ))}
          </div>
        </div>
        <div style={styles.cardItem}>
          <div>
            <p><Link to={`/teams/${team?.id}`}>Quick {team?.name} overview!</Link></p>
          </div>
        </div>
      </div>
    )
  else if (props.entity.type === 'employee') {
    return (
      <div style={styles.card}>
        <button style={styles.cardCloseBtn} onClick={props.handleClose}>
          <MdClose />
        </button>
        <div>
          <div style={styles.cardHeader}>
            <img style={styles.cardAvatar} src={imageUrl} alt='Profile' />
            <h2 style={styles.cardName}><Link to={`/singleEmployee/${employee?.id}`}>{getFullName(employee?.firstName, employee?.lastName)}</Link></h2>
            <p style={styles.cardRole}>{employee?.position}</p>
          </div>
          <div style={styles.cardBody}>
            <div style={styles.cardItem}>
              <p style={styles.cardItemLabel}>Phone:</p>
              <p style={styles.cardItemValue}>{employee?.personalPhoneNumber}</p>
            </div>
            <div style={styles.cardItem}>
              <p style={styles.cardItemLabel}>Email:</p>
              <p style={styles.cardItemValue}>{employee?.personalEmailAddress}</p>
            </div>
            <div style={styles.cardItem}>
              <p style={styles.cardItemLabel}>Hub:</p>
              <p style={styles.cardItemValue}>{employee?.klikaOffice}</p>
            </div>
            <div style={styles.cardItem}>
              <p style={styles.cardItemLabel}>Department:</p>
              <p style={styles.cardItemValue}>{employee?.department}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
  else
    return null
}

export default EntityDetailsCard
