import React, { useState } from 'react'
import { useCreateClientMutation } from 'services/queries/api'
import { Client } from 'models/client.model'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

interface NewClientModalProps {
  onClose: () => void
  refetch: () => void
}

const NewClientModal: React.FC<NewClientModalProps> = ({ onClose, refetch }) => {
  const [clientData, setClientData] = useState<Client>({ name: '' } as Client)
  const [createClient, { isLoading, isSuccess, isError }] = useCreateClientMutation()
  const [showMessage, setShowMessage] = useState(true)

  if (isSuccess || isError) {
    setTimeout(() => {
      setShowMessage(false)
    }, 1500)
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    setClientData((prevData) => ({
      ...prevData,
    }))

    try {
      await createClient(clientData).unwrap()
      onClose()
      refetch()
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target

    const value = e.target.value
    setClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  return (
    <Modal show={true} onHide={onClose} centered className="centered-modal">
      <Modal.Header>
        <Modal.Title>Create a new Client</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='p-1'>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <input
              style={{width:'100%', borderRadius:'5px'}}
              className='info-field bg-light-gray p-2 pl-3 mb-3'
              type='text'
              name='name'
              placeholder='Client name'
              onChange={handleChange}
              required
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              className='btn btn-primary'
              type='submit'
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? 'Creating...' : 'Create'}
            </button>
            <Link
              to='/clients'
              className='btn btn-secondary'
              style={{ marginLeft: 20 }}
              onClick={onClose}
            >
              Cancel
            </Link>
          </div>
          {isSuccess && showMessage && (
            <div className='alert alert-success mt-3' role='alert'>
              Client has been successfully created!
            </div>
          )}
          {isError && showMessage && (
            <div className='alert alert-danger mt-3' role='alert'>
              Error creating client. Please try again.
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NewClientModal
