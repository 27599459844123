import { useMsal } from '@azure/msal-react';
import { Entity } from './entity.model';
import { useGetDepartmentsQuery, useGetDivisionsQuery, useGetEmployeesFilteredQuery, useGetEmployeesNamePositionQuery } from 'services/queries/api';
import OrganizationalChart from './components/OrgChart';
import { EmployeeBasic } from 'models/employeeBasic.model';
import { useEffect, useState } from 'react';
import { PropertyFilter } from 'interfaces/interfaces';
import { getFullName } from 'models/employee.model';

interface DepartmentData {
    entityId: string,
    parentId: string,
    department: string,
    position: string,
    type: string
    employeeId?: string | undefined
}

function generateDepartmentEntity(departmentData: DepartmentData, departmentHead?: EmployeeBasic) {
    if (!departmentHead) {
        const graphNode: Entity = {
            id: departmentData.entityId,
            parentId: departmentData.parentId,
            name: departmentData.department,
            positionName: departmentData.position,
            type: departmentData.type
        }
        return graphNode
    }
    else {
        const graphNode: Entity = {
            id: departmentHead?.id ?? '',
            parentId: departmentData.entityId,
            name: departmentHead?.nameWithPosition,
            positionName: departmentData.position,
            type: 'employee',
            employeeId: departmentHead.id
        }
        return graphNode;
    }
}


function BoardChart() {
    const { instance } = useMsal();
    const { data: departments } = useGetDepartmentsQuery()

    const [renderChart, setRenderChart] = useState(false);

    useEffect(() => {
        // Add a delay (in ms) before rendering the chart
        const timeoutId = setTimeout(() => {
            setRenderChart(true);
        }, 1200);

        // Cleanup the timeout when the component unmounts
        return () => clearTimeout(timeoutId);
    }, []);

    let departmentNodes: DepartmentData[] = []
    const rootId = 10000;
    const entitiesArray: Entity[] =
        [
            {
                id: rootId.toString(), parentId: '', name: 'Klika Board', positionName: 'Zaharije Pasalic, Maja Mameledzija, Edin Deljkic, Samir Eljazovic', type: 'root'
            }
        ]
    if (departments) {
        departmentNodes = departments.map(departmentData => ({
            entityId: (departmentData.id).toString(),
            parentId: rootId.toString(),
            department: departmentData.name,
            position: '',
            type: 'department',
            employeeId: ''
        }));
    }
    if (departmentNodes) {
        departmentNodes.forEach(deparmentData => { // Generate Base nodes for departments
            const newEntity = generateDepartmentEntity(deparmentData)
            if (newEntity) {
                entitiesArray.push(newEntity);
            }
        })
    }

    // remove department heads for now

    /* if (departmentNodes) {
        departmentNodes.forEach(departmentData => { // Add Heads of departments
            const departmentHead = employeesWithPositions?.find(employee =>
                employee.nameWithPosition.toLowerCase().includes('head of ' + departmentData.department.toLowerCase())
            );
            if (departmentHead) {
                const departmentEntity = generateDepartmentEntity(departmentData, departmentHead);
                if (departmentEntity) {
                    entitiesArray.push(departmentEntity);
                }
            }
        })
    }; */

    const { data: divisions } = useGetDivisionsQuery()
    const indexShift = 100;
    divisions?.forEach(division => {
        const newEntity: Entity =
        {
            id: (Number(division?.id) + indexShift).toString(), name: division?.name, parentId: (division?.departmentActual?.id)?.toString(), positionName: '', type: 'division'
        }
        entitiesArray.push(newEntity);
    })

    const [propertyFilter, setPropertyFilter] = useState<PropertyFilter>({
        filter: {},
        pageNumber: 1,
        pageSize: 300,
        sortBy: 'Name',
        sortAscending: true,
    })
    const { data: employees } = useGetEmployeesFilteredQuery(propertyFilter)
    employees?.filteredItems.forEach(employee => {
        if (employee.divisionActualId === null) {
            const newEntity: Entity =
            {
                id: (employee.id).toString(), name: employee.fullName, parentId: (employee.departmentActualId)?.toString(), positionName: employee.position, type: 'employee'
            }
            entitiesArray.push(newEntity);
        }
        else {
            const newEntity: Entity =
            {
                id: (employee.id).toString(), name: employee.fullName, parentId: (Number(employee?.divisionActualId) + indexShift).toString(), positionName: employee.position, type: 'employee'
            }
            entitiesArray.push(newEntity);
        }
    })

    return (
        <>
            <h3 style={{ marginTop: '-2.5rem' }}>Company structure</h3>
            {renderChart && <OrganizationalChart data={entitiesArray} />}
        </>
    )
};
export default BoardChart;
