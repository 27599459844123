import React, { useRef, useImperativeHandle, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  useGetEmployeeQuery,
} from '../../services/queries/api';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { EmployeeChild } from 'models/employeeChild.model';
import {
  ProfileCard,
  PersonalInformation,
  EmploymentInformation,
  TeamsOverview
} from './components';
import { Employee } from 'models/employee.model';
import { useParams } from 'react-router-dom';
import './singleEmployeePage.scss';

const PrintableContent = React.forwardRef(({ profile }: { profile: Employee }, ref) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  PrintableContent.displayName = 'PrintableContent';

  useImperativeHandle(ref, () => ({
    getContent: () => contentRef.current,

  }));

  return (
    <div ref={contentRef} style={{ marginLeft: 10, marginTop: 10 }}>
      <ProfileCard profile={profile} showEditButton={true} /> <br></br>
      <PersonalInformation profile={profile} /> <br></br>
      <EmploymentInformation profile={profile} /><br></br>
      <TeamsOverview assignments={profile.assignments} />
    </div>
  );
});
import Loader from 'components/Loader'

const SingleEmployeePage: React.FC = () => {
  const { employeeId } = useParams()
  const { data: employee, isLoading } = useGetEmployeeQuery(employeeId == undefined ? '' : employeeId)
  const imageUrl = 'data:image/png;base64,' + employee?.photo;

  const roles = useSelector((state: any) => state.token.value);

  const componentRef = useRef<{ getContent: () => HTMLDivElement | null } | null>(null);

  const handlePrint = useReactToPrint({
    content: () => (componentRef.current?.getContent() as HTMLDivElement | null),
    documentTitle: employee?.firstName + ' ' + employee?.lastName
  });

  if (isLoading) {
    return <Loader></Loader>
  }

  const profile = new Employee(employee);

  return (
    <div>
      <div className='container'>
        <PrintableContent profile={profile} ref={componentRef} />
        <button className='btn btn-outline-primary btn-sm' onClick={handlePrint}>Print/Save PDF</button>
      </div>
    </div>
  );
};

export default SingleEmployeePage;
