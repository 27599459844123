import { setCurrentPage, setItemsPerPage, setTotalPages } from 'services/slices/paginationSlice'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

interface PaginationProps {
  totalItems: number
  itemsPerPage: number
  onApplyFilterRefetch: () => void
}

const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  onApplyFilterRefetch,
}) => {
  const dispatch = useDispatch()
  const { currentPage, totalPages } = useSelector((state: any) => state.pagination)

  useEffect(() => {
    const calculatedTotalPages = Math.ceil(totalItems / itemsPerPage)
    dispatch(setTotalPages(calculatedTotalPages))
    if (currentPage > calculatedTotalPages) {
      dispatch(setCurrentPage(calculatedTotalPages))
    }
  }, [dispatch, totalItems, itemsPerPage, currentPage])

  const handlePageChange = (pageNumber: number) => {
    dispatch(setCurrentPage(pageNumber))
    onApplyFilterRefetch()
  }

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = Number(e.target.value)

    dispatch(setItemsPerPage(newItemsPerPage))
    dispatch(setCurrentPage(1))
  }

  const renderPaginationButtons = () => {
    const buttons = []
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
          <button
            className='page-link'
            onClick={() => handlePageChange(i)}
            disabled={i === currentPage}
          >
            {i}
          </button>
        </li>,
      )
    }
    return buttons
  }

  return (
    <nav aria-label='Page navigation' className='d-flex justify-content-between flex-wrap '>
      <div className='d-flex flex-row justify-content-sm-between'>
        <label className='d-flex align-items-end align-self-stretch p-2' htmlFor='itemsPerPage'>
          Items per page:{' '}
        </label>
        <div className='items-per-page p-2'>
          <select
            id='itemsPerPage'
            className='form-control p-0 btn-sm w-auto mt-2'
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            {/* <option value='10'>10</option> */}
            <option value='20'>20</option>
            {/* <option value='50'>50</option> */}
          </select>
        </div>
        <label
          className='d-flex align-items-end align-self-stretch p-2 ml-2'
          htmlFor='itemsPerPage'
        >
          Total Items: {totalItems}
        </label>
      </div>
      <ul className='pagination pagination-sm p-2 mt-2 flex-wrap'>
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button
            className='page-link'
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
        </li>
        {renderPaginationButtons()}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button
            className='page-link'
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination
