import React from 'react'

const Loader: React.FC = () => {
  return (
    <div className='loader-container'>
      <div className='d-flex justify-content-center align-items-center'>
        <div className='spinner-grow' role='status'>
          <span className='visually-hidden'></span>
        </div>
      </div>
    </div>
  )
}

export default Loader
