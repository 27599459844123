import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import './startupPage.scss'
import { useMsal } from '@azure/msal-react'
import klikaLogo from '../../assets/images/klika-logo-bl.svg'

const StartupPage: React.FC = () => {
  const { instance } = useMsal()
  localStorage.setItem('path', '/')

  const handleLogin = () => {
    instance.loginRedirect().catch((e) => {
      console.log(e)
    })
  }
  return (
    <div className='background'>
      <div className='container-fluid'>
        <div className='row' style={{ height: '100vh', marginLeft: '10vh' }}>
          <div className='col-6 d-flex flex-column'>
            <div className='d-flex justify-content-start align-items-start mt-4'>
              <img src={klikaLogo} className='klika-logo'></img>
            </div>
            <div className='d-flex align-items-center klika-text'>
              <div className='split-title'>
                <h1>
                  <span className='d-block'>Welcome to</span>
                  <span className='d-block'>Klika EMP!</span>
                </h1>
                <div className='split-subtitle'>
                  <h4>
                    <span className='d-block'>In order to use Klika EMP,</span>
                    <span className='d-block'>please use your Klika credentials</span>
                  </h4>
                </div>
                <button className='btn btn-primary centered-button' onClick={handleLogin}>
                  Login with Office365
                </button>
              </div>
            </div>
          </div>
          <div className='col-1' style={{ backgroundColor: '#FCDF5E' }}></div>
          <div className='col-5 yellow-part'></div>
        </div>
      </div>
    </div>
  )
}

export default StartupPage
