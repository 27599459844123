import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const RequireUser = ({ allowedRoles }: { allowedRoles: string[] }) => {
  const location = useLocation()
  const roles = useSelector((state: any) => state.token.value)

  return allowedRoles.some((element) => roles.includes(element)) ? (
    <Outlet />
  ) : roles ? (
    <Navigate to='/unauthorized' state={{ from: location }} replace />
  ) : (
    <Navigate to='/' state={{ from: location }} replace />
  )
}

export default RequireUser
