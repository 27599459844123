import './profilePage.scss'
import logo from 'assets/images/undraw_profile.svg'
import PersonCard from './components/PersonCard'
import { PersonInfo } from 'interfaces/interfaces'
import { useMsal } from '@azure/msal-react'
import { each } from 'jquery'
import { Team } from '../../models/team.model'

function ProfilePage() {
  const { instance } = useMsal()

  const persons: PersonInfo[] = [
    { name: 'Person 1', team: 'team lead', teamName: 'Team1' },
    { name: 'Person 2', team: 'Senior developer', teamName: 'Team1' },
    { name: 'Person 3', team: 'Senior developer', teamName: 'Team1' },
    { name: 'Person 4', team: 'Senior developer', teamName: 'Team2' },
    { name: 'Person 5', team: 'Senior developer', teamName: 'Team1' },
    { name: 'Person 9', team: 'Senior developer', teamName: 'Team2' },
    { name: 'Person 6', team: 'Quality assurance engineer', teamName: 'Team1' },
    { name: 'Person 7', team: 'Quality assurance engineer', teamName: 'Team1' },
    { name: 'Person 8', team: 'Quality assurance engineer', teamName: 'Team2' },
    { name: 'Person 10', team: 'Junior developer', teamName: 'Team1' },
    { name: 'Person 11', team: 'Junior developer', teamName: 'Team1' },
    { name: 'Person 12', team: 'Junior developer', teamName: 'Team2' },
    { name: 'Person 13', team: 'team lead', teamName: 'Team2' },
  ]

  interface Team {
    name: string
    role: string
    eem: string
    em: string
    cdm: string
    director: string
  }
  const teams: Team[] = [
    {
      name: 'Team1',
      role: 'Role in team 1',
      eem: 'John Doe EEM',
      em: 'John Doe EM',
      cdm: 'John Doe CDM',
      director: 'John Doe Dir',
    },
    {
      name: 'Team2',
      role: 'Role in team 2',
      eem: 'John Doe EEM',
      em: 'John Doe EM',
      cdm: 'John Doe CDM',
      director: 'John Doe Dir',
    },
  ]

  return (
    <div className='container'>
      <div className='row'>
        <div className='col card m-1'>
          <div className='dashboard-greeting'>
            <h4>Welcome {instance.getAllAccounts()[0].name}!</h4>
          </div>
        </div>
      </div>

      <h5 className='mt-4'>Teams</h5>
      <div className='row'>
        {teams.map((team, index) => (
          <div className='col card m-1 d-flex justify-content-around' key={index}>
            <h5 className='card-title text-center m-0 pt-2'>{team.name}</h5>
            <p className='card-text m-1'>{team.role}</p>
            <ul>
              <li>EEM: {team.eem}</li>
              <li>EM: {team.em}</li>
              <li>CDM: {team.cdm}</li>
              <li>Director: {team.director}</li>
            </ul>
          </div>
        ))}
      </div>

      <h5 className='mt-4'>Organisation</h5>
      <div className='row'>
        {teams.map((team, index) => (
          <div className='col-12 mb-4' key={index}>
            <div className='card'>
              <div className='card-body'>
                <h5 className='card-title text-center mb-3'>{team.name}</h5>
                <div className='team-members row justify-content-center'>
                  <div className='col-lg-3 col-md-6 mb-3'>
                    <PersonCard
                      name={team.director}
                      role='Director of Engineering'
                      imageUrl={logo}
                    />
                  </div>
                  <div className='col-lg-3 col-md-6 mb-3'>
                    <PersonCard name={team.em} role='Engineering Manager' imageUrl={logo} />
                  </div>
                  <div className='col-lg-3 col-md-6 mb-3'>
                    <PersonCard name={team.cdm} role='Client Delivery Manager' imageUrl={logo} />
                  </div>
                  <div className='col-lg-3 col-md-6 mb-3'>
                    <PersonCard
                      name={team.eem}
                      role='Employee Experience Manager'
                      imageUrl={logo}
                    />
                  </div>
                </div>
                <div className='card-title mt-3'>
                  <h6 className='text-center'>Team Members</h6>
                </div>
                <div className='team-members row row-cols-1 row-cols-md-4 g-0'>
                  <div className='col-lg-3 col-md-3 col-sm-3 p-1'>
                    <div className='team-lead pl-1 pr-1'>
                      <PersonCard name={persons[0].name} role={persons[0].team} imageUrl={logo} />
                    </div>
                  </div>
                  {persons
                    .filter(
                      (person) => person.teamName === team.name && person.name !== persons[0].name,
                    )
                    .map((person, personIndex) => (
                      <div className='col-lg-3 col-md-6 mb-3' key={personIndex}>
                        <PersonCard name={person.name} role={person.team} imageUrl={logo} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProfilePage
