import React, { useState } from 'react';
import { Modal, TextField, Button, Tabs, Tab } from '@mui/material';
import { useUpdateDepartmentMutation, useUpdateDivisionMutation, useGetDivisionQuery } from '../../services/queries/api';
import { Department } from '../../models/department.model';

interface UpdateDepartmentModalProps {
    onClose: () => void;
    itemId: number;
    isDepartment: boolean;
}

const UpdateDepartmentModal: React.FC<UpdateDepartmentModalProps> = ({ onClose, itemId, isDepartment }) => {
    const [newName, setNewName] = useState('');
    const [updateDepartment] = useUpdateDepartmentMutation();
    const [updateDivision] = useUpdateDivisionMutation();
    const divisionToUpdate = useGetDivisionQuery(itemId);

    const handleUpdate = () => {
        if (isDepartment) {
            const updatedDepartment = { id: itemId, name: newName };
            updateDepartment(updatedDepartment);
        } else {
            const updatedDivision = { id: itemId, name: newName, departmentActual: divisionToUpdate.data.departmentActual };
            updateDivision(updatedDivision);
        }
        onClose();
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', width: '400px' }}>
                <h2 id="modal-title" style={{ fontSize: '20px', fontFamily: 'Arial', marginBottom: '20px' }}>Update {isDepartment ? 'Department' : 'Division'}</h2>
                <TextField
                    fullWidth
                    id="newName"
                    label="New Name"
                    variant="outlined"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    style={{ marginBottom: '20px'}}
                    autoComplete='off'
                />
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Button variant="contained" onClick={handleUpdate} style={{ marginRight: '10px', backgroundColor: '#007bff', color: '#fff', borderColor: '#007bff', fontSize: '14px', fontFamily: 'Arial', padding: '8px 16px' }}>Update</Button>
                    <Button variant="contained" onClick={onClose} style={{ backgroundColor: '#6c757d', color: '#fff', borderColor: '#6c757d', fontSize: '14px', fontFamily: 'Arial', padding: '8px 16px' }}>Cancel</Button>
                </div>
            </div>
        </Modal>
    );
};

export default UpdateDepartmentModal;
