import { useMsal } from '@azure/msal-react'
import { useState } from 'react'
import { useLogoutUserMutation } from 'services/queries/apiAuth'

export const SignOutButton = () => {
  const { instance } = useMsal()
  const [isDisabled, setIsDisabled] = useState(false)
  const handleLogout = async () => {
    try {
      setIsDisabled(true)
      window.location.href = '/'
      localStorage.clear()
    } catch (error) {
      setIsDisabled(false)
      console.error('Error during logout:', error)
    }
  }
  return (
    <div>
      <a href="#" onClick={handleLogout} style={{ color: 'dimgray' }}>
        <i className="fas fa-sign-out-alt"></i> Sign Out
      </a>
    </div>
  )
}
