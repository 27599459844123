import React, { useState } from 'react';

import { Table } from 'react-bootstrap';
import { Assignment } from 'models/assignment.model';
import {displayTeamName} from '../../../../utils/helpers';
import {Tooltip as ReactTooltip} from 'react-tooltip';

export interface TeamsOverviewProps {
  assignments?: Assignment[] | undefined;
}


export function TeamsOverview({ assignments }: TeamsOverviewProps) {
  return (
    <div className="row">
      <div className="col-12">
        <h4 className='header'>Teams</h4>
      </div>
      <div className="col-12">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Team name</th>
              <th>Role in the team</th>
            </tr>
          </thead>
          <tbody>
            {assignments?.map((assignment: any) => (
              <tr key={assignment.id}>
                <td data-tooltip-id={`${assignment.teamId}`}>{displayTeamName(assignment.client, assignment.project, assignment.team)}</td>
                <td>{assignment.teamRole.name}</td>
                  <ReactTooltip
                      id={`${assignment.teamId}`}
                      place="top"
                      content={`Client=${assignment.client} Project=${assignment.project} Team=${assignment.team}`}
                  />
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
