import React, { FC, ReactNode } from 'react'
import { Button, Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap'

interface DefaultButtonProps {
  onClick?: () => void
  children: ReactNode
  size?: 'sm' | 'lg'
  color?: string
  tooltip?: string
  disabled?: boolean
  isLoading?: boolean
  type?: 'button' | 'submit' | 'reset'
}

const DefaultButton: FC<DefaultButtonProps> = ({
  onClick,
  children,
  size = 'sm',
  color = 'outline-danger',
  tooltip,
  disabled = false,
  isLoading = false,
  type = 'button',
}) =>
  tooltip ? (
    <OverlayTrigger
      key='bottom'
      placement='bottom'
      overlay={<Tooltip id={'tooltip-bottom'}>{tooltip}</Tooltip>}
    >
      <Button
        size={size}
        variant={color}
        onClick={onClick}
        disabled={disabled || isLoading}
        type={type}
      >
        {isLoading ? <Spinner animation='border' size='sm' /> : children}
      </Button>
    </OverlayTrigger>
  ) : (
    <Button
      size={size}
      variant={color}
      onClick={onClick}
      disabled={disabled || isLoading}
      type={type}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : children}
    </Button>
  )

export default DefaultButton
