import React, { ChangeEvent, useState} from 'react'
import {
         useCreateDepartmentMutation,
         useCreateDivisionMutation,
         useGetDepartmentsQuery,
         useGetDivisionsQuery
       } from 'services/queries/api'
import { Link } from 'react-router-dom'
import { Modal} from 'react-bootstrap'
import { TextField,Tabs, Tab, DialogContent, MenuItem, Select, FormControl, InputLabel } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select';
import { Department} from '../../models/department.model';
import { NewDivision} from '../../models/newDivision.model';

interface CreateDepartmentModalProps {
    onClose: () => void
}

const CreateDepartmentModal: React.FC<CreateDepartmentModalProps> = ({ onClose }) => {
    const { data: departments } = useGetDepartmentsQuery()
    const { data: divisions } = useGetDivisionsQuery()
    const [createDepartment, { isLoading, isSuccess, isError }] = useCreateDepartmentMutation()
    const [createDivision] = useCreateDivisionMutation()
    const [showMessage, setShowMessage] = useState(true)
    const [departmentData, setDepartmentData] = useState<Department>({ name: '' } as Department)
    const [divisionData, setDivisionData] = useState<NewDivision>({ name: '' } as NewDivision)
    const [selectedDepartment, setSelectedDepartment] = useState<Department | null>(null)

    const handleSubmitDepartment = async (event: React.FormEvent) => {
        event.preventDefault()
        try {
            let createdDepartment = new Department()
            createdDepartment = await createDepartment(departmentData).unwrap()
            onClose()
            await new Promise((resolve) => setTimeout(resolve, 500))
        } catch (error) {
            console.log(error)
        }
    }
    const handleSubmitDivision = async (event: React.FormEvent) => {
        event.preventDefault()
        try {
            let createdDivision = new NewDivision()
            divisionData.departmentId = selectedDepartment?.id.toString()
            createdDivision = await  createDivision(divisionData).unwrap()
            onClose()
            await new Promise((resolve) => setTimeout(resolve, 500))
        } catch (error) {
            console.log(error)
        }
    }

    const handleDepartmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setDepartmentData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    }
    const handleDivisionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setDivisionData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    }
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: ChangeEvent<object>, newValue: number) => {
        setTabValue(newValue);
    };
    const handleDepartmentDropdownChange = (e: SelectChangeEvent) => {
        const selectedDepartmentId = e.target.value as string
        const selectedDepartment =
            departments?.find((department) => department.id.toString() === selectedDepartmentId) || null
        setSelectedDepartment(selectedDepartment)
    }
    return (
        <Modal show={true} onHide={onClose} centered className="centered-modal">
            <Modal.Header style={{ display: 'flex', justifyContent: 'center' }}>
                <Modal.Title style={{ textAlign: 'center' }}>Create new</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ textAlign: 'center' }}>
                <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Tabs value={tabValue} onChange={handleTabChange}>
                            <Tab label="Department" />
                            <Tab label="Division" />
                        </Tabs>
                    </div>
                    {tabValue === 0 && (
                        <div>
                            <div className='mb-1' style={{ display: 'flex', justifyContent: 'center', paddingTop:'10px' }}>
                                <TextField
                                    className='info-field bg-light-gray mb-3'
                                    label='Department name'
                                    size='small'
                                    type='text'
                                    sx={{ width: 450 }}
                                    name='name'
                                    onChange={handleDepartmentChange}
                                    required
                                    autoComplete='off'
                                />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Link to={'/departments'} onClick={handleSubmitDepartment} className='btn btn-primary' type='submit'>
                                    {isLoading ? 'Creating...' : 'Create'}
                                </Link>
                                <Link
                                    to='/departments'
                                    className='btn btn-secondary'
                                    style={{ marginLeft: 20 }}
                                    onClick={onClose}
                                >
                                    Cancel
                                </Link>
                            </div>
                            {isSuccess && showMessage && (
                                <div className='alert alert-success mt-3' role='alert'>
                                    Department has been successfully created!
                                </div>
                            )}
                            {isError && showMessage && (
                                <div className='alert alert-danger mt-3' role='alert'>
                                    Error creating department. Please try again.
                                </div>
                            )}
                        </div>
                    )}
                    {tabValue === 1 && (
                        <div className='mb-1'>
                            <div className='mb-1'>
                                <div className='mb-3' style={{ display: 'flex', justifyContent: 'center', paddingTop:'10px'}}>
                                    <FormControl style={{ display: 'flex', justifyContent: 'center', paddingTop:'10px', width:'450px'}}>
                                         <InputLabel>
                                            Select department
                                         </InputLabel>
                                        <Select
                                            labelId="departmentName-label"
                                            id="departmentName"
                                            name='departmentName'
                                            value={selectedDepartment?.id.toString() || ''}
                                            onChange={(e) => handleDepartmentDropdownChange(e)}
                                            required
                                            sx={{
                                                paddingTop: '10px',
                                                borderRadius: '5px',
                                                height: '40px'
                                            }}
                                        >
                                            <MenuItem value="" disabled>
                                                Select department
                                            </MenuItem>
                                            {departments?.map((department) => (
                                                <MenuItem key={department.id} value={department.id}>
                                                    {department.name}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>
                                </div>
                                <div className='mb-1' style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                                    <TextField
                                        className='info-field bg-light-gray mb-3'
                                        label='Division name'
                                        size='small'
                                        type='text'
                                        sx={{ width: 450 }}
                                        name='name'
                                        onChange={handleDivisionChange}
                                        required
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Link to={'/departments'} onClick={handleSubmitDivision} className='btn btn-primary' type='submit'>
                                    {isLoading ? 'Creating...' : 'Create'}
                                </Link>
                                <Link
                                    to='/departments'
                                    className='btn btn-secondary'
                                    style={{ marginLeft: 20 }}
                                    onClick={onClose}
                                >
                                    Cancel
                                </Link>
                            </div>
                            {isSuccess && showMessage && (
                                <div className='alert alert-success mt-3' role='alert'>
                                    Division has been successfully created!
                                </div>
                            )}
                            {isError && showMessage && (
                                <div className='alert alert-danger mt-3' role='alert'>
                                    Error creating division. Please try again.
                                </div>
                            )}
                        </div>
                    )}
                </DialogContent>
            </Modal.Body>
        </Modal>
    );
}

export default CreateDepartmentModal
