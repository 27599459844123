/* eslint-disable react/prop-types */
import React from 'react'
import { MdClose } from 'react-icons/md'
import { useGetEmployeeQuery, useGetEmployeesNamePositionQuery } from '../../../../services/queries/api';
import { Link } from 'react-router-dom';
import { EmployeeBasic } from 'models/employeeBasic.model';
import avatar from 'assets/images/undraw_profile.svg'
import { getFullName } from 'models/employee.model'

const styles = {
  card: {
    position: 'absolute',
    top: '60px',
    left: '0',
    width: '25%',
    height: '55%',
    padding: '2rem',
    margin: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    border: '1px solid #d3d3d3',
    overflowY: 'scroll',
  },
  cardCloseBtn: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    width: '30px',
    height: '30px',
    color: '#227c9d',
    backgroundColor: '#fef9ef',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #227c9d',
    cursor: 'pointer',
  },
  cardHeader: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
  cardAvatar: {
    width: '120px',
    borderRadius: '1rem',
  },
  cardName: {
    marginTop: '1rem',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  cardRole: {
    margin: '1rem 0',
    fontSize: '1.2rem',
  },
  cardBody: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },
  cardItem: {
    width: '100%',
    margin: '0.5rem 0',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '0.9rem',
  },
  cardItemLabel: {
    margin: '0.5rem 0',
    fontWeight: 'bold',
  },
  cardItemValue: {
    margin: '0.5rem 0',
    textAlign: 'justify',
  },
  cardImg: {
    width: '100px',
    height: '100px',
    borderRadius: '40px'
  },
}

const EntityDetailsCard = (props) => {
  if (props.entity.type === 'employee') {
    const { data: employee } = useGetEmployeeQuery(props.entity.id)
    const imageUrl = props.entity.photo ? 'data:image/png;base64,' + props.entity.photo : avatar
    return (
      <div style={styles.card}>
        <button style={styles.cardCloseBtn} onClick={props.handleClose}>
          <MdClose />
        </button>
        <div>
          <div style={styles.cardHeader}>
            <img style={styles.cardAvatar} src={imageUrl} alt='Profile' />
            <h2 style={styles.cardName}><Link to={`/singleEmployee/${employee?.id}`}>{getFullName(employee?.firstName, employee?.lastName)}</Link></h2>
            <p style={styles.cardRole}>{employee?.position}</p>
          </div>
          <div style={styles.cardBody}>
            <div style={styles.cardItem}>
              <p style={styles.cardItemLabel}>Phone:</p>
              <p style={styles.cardItemValue}>{employee?.personalPhoneNumber}</p>
            </div>
            <div style={styles.cardItem}>
              <p style={styles.cardItemLabel}>Email:</p>
              <p style={styles.cardItemValue}>{employee?.personalEmailAddress}</p>
            </div>
            <div style={styles.cardItem}>
              <p style={styles.cardItemLabel}>Hub:</p>
              <p style={styles.cardItemValue}>{employee?.klikaOffice}</p>
            </div>
            <div style={styles.cardItem}>
              <p style={styles.cardItemLabel}>Department:</p>
              <p style={styles.cardItemValue}>{employee?.department}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
  else

    return null
}

export default EntityDetailsCard
