import React, { useEffect, useState } from 'react'
import {
  useCreateTeamMutation,
  useGetProjectsQuery,
  useGetClientsQuery,
  useCreateClientMutation,
  useCreateProjectMutation,
} from 'services/queries/api'
import { NewTeam } from 'models/newTeam.model'
import { Link, useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { Client } from 'models/client.model'
import { Project } from 'models/project.model'
import AutocompleteInput from 'utils/autocompleteInput'
import { NewProject } from 'models/newProject.model'
import { Input, TextField } from '@mui/material'

interface CreateTeamModalProps {
  onClose: () => void
  refetch: () => void
}

const CreateTeamModal: React.FC<CreateTeamModalProps> = ({ onClose, refetch }) => {
  const { data: projects } = useGetProjectsQuery()
  const { data: clients } = useGetClientsQuery()
  const [createTeam, { isLoading, isSuccess, isError }] = useCreateTeamMutation()
  const [createClient] = useCreateClientMutation()
  const [createProject] = useCreateProjectMutation()
  const [showMessage, setShowMessage] = useState(true)
  const [teamData, setTeamData] = useState<NewTeam>({ name: '' } as NewTeam)
  const [selectedClient, setSelectedClient] = useState<any | null>(null)
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [createdTeamId, setCreatedTeamId] = useState<string | null>(null)
  const [projectsList, setProjectsList] = useState<DropdownOptions[]>([]);
  const [isLinkDisabled, setIsLinkDisabled] = useState(true)
  const newClient = new Client
  const newProject = new NewProject
  interface DropdownOptions {
    label: string;
    id: string;
    object: any;
  }
  const clientsList = clients?.map((client) => ({
    label: client.name,
    id: client.id,
    object: client
  })) || [];

  useEffect(() => {
    if (teamData.name && selectedClient && selectedProject) {
      setIsLinkDisabled(false)
    } else {
      setIsLinkDisabled(true)
    }
  }, [teamData.name, selectedClient, selectedProject])

  if (isSuccess || isError) {
    setTimeout(() => {
      setShowMessage(false)
    }, 1500)
  }
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    try {
      let updatedTeamData = new NewTeam
      let createdTeam = new NewTeam
      let projectId = ''
      let createdClient = new Client
      let createdProject = new NewProject
      if ((projects && selectedProject && !projects.find((project) => project.id === selectedProject.id)) && ((clients && selectedClient && !clients.find((client) => client.id === selectedClient.id)))) {
        newClient.name = selectedClient.label && selectedClient.label ? selectedClient.label : '';
        createdClient = await createClient(newClient).unwrap()
        newProject.name = selectedProject.label && selectedProject.label ? selectedProject.label : '';
        newProject.clientId = createdClient.id
        createdProject = await createProject(newProject).unwrap()
        projectId = createdProject?.id?.toString() || ''
      }
      else {
        projectId = selectedProject?.id || ''
      }
      updatedTeamData = {
        ...teamData,
        projectId: projectId || '',
        assignments: [],
      }
      createdTeam = await createTeam(updatedTeamData).unwrap()
      refetch()
      onClose()
      await new Promise((resolve) => setTimeout(resolve, 500))
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setTeamData((prevData) => ({
      ...prevData,
      [name]: value,
      projectId: selectedProject?.id || '',
    }))
  }
  useEffect(() => {
    if (selectedClient && projects) {
      const updatedProjectsList = projects
        .filter(project => project.client.id === selectedClient.id)
        .map(project => ({
          label: project.name,
          id: project.id,
          object: project
        }));
      setProjectsList(updatedProjectsList);
    } else {
      const allProjectsList = (projects || []).map(project => ({
        label: project.name,
        id: project.id,
        object: project
      }));
      setProjectsList(allProjectsList);
    }
  }, [selectedClient, projects]);


  return (
    <Modal show={true} onHide={onClose} centered className="centered-modal">
      <Modal.Header>
        <Modal.Title>Create a new Team</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='p-1'>
          <div className='mb-3'>
            <AutocompleteInput
              items={clientsList}
              selectedItem={selectedClient}
              onItemSelect={setSelectedClient}
              inputFieldName='Client'
              id='autoselect-client'
            />
          </div>
          <div className='mb-3'>
            <AutocompleteInput
              items={projectsList}
              selectedItem={selectedProject}
              onItemSelect={setSelectedProject}
              inputFieldName='Project'
              id='autoselect-project'
            />
          </div>
          <div className='mb-1'>
            <TextField
              className='info-field bg-light-gray mb-3'
              label='Team name'
              size='small'
              type='text'
              sx={{ width: 450 }}
              name='name'
              onChange={handleChange}
              required
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Link to={`/teams/${createdTeamId}`} onClick={handleSubmit} className='btn btn-primary' type='submit'>
              {isLoading ? 'Creating...' : 'Create'}
            </Link>
            <Link
              to='/teams'
              className='btn btn-secondary'
              style={{ marginLeft: 20 }}
              onClick={onClose}
            >
              Cancel
            </Link>
          </div>
          {isSuccess && showMessage && (
            <div className='alert alert-success mt-3' role='alert'>
              Team has been successfully created!
            </div>
          )}
          {isError && showMessage && (
            <div className='alert alert-danger mt-3' role='alert'>
              Error creating team. Please try again.
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CreateTeamModal
