import { useParams } from 'react-router-dom'
import { useGetEmployeeQuery } from 'services/queries/api'

const Employee: React.FC = () => {
  const { employeeId } = useParams()
  const { data: employee } = useGetEmployeeQuery(employeeId)

  return (
    <table className='table'>
      <thead>
        <tr>
          <th scope='col'>#</th>
          <th scope='col'>Full Name</th>
          <th scope='col'>Title</th>
          <th scope='col'>Team</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope='row'></th>
          <td>{employee?.name}</td>
          <td>{employee?.position}</td>
          <td>{employee?.project}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default Employee
