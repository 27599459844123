import './sbadmin'
import './assets/styles/custom.scss'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Layout from './pages/home/Layout'
import Clients from './pages/clients/Clients'
import Teams from './pages/teams/Teams'
import Employees from './pages/employees/Employees'
import Projects from './pages/projects/Projects'
import UpdateProject from 'pages/projects/UpdateProject'
import Employee from './pages/employees/Employee'
import ProfilePage from './pages/profilePage/ProfilePage'
import StartupPage from './pages/startupPage/StartupPage'
import SingleEmpolyeePage from './pages/singleEmployeePage/SingleEmpolyeePage'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import React from 'react'
import EditClient from 'pages/clients/EditClient'
import UpdateTeam from 'pages/teams/UpdateTeam'
import CreateTeam from 'pages/teams/CreateTeam'
import { Provider } from 'react-redux'
import { store } from 'services/store'
import RequireUser from 'components/RequireUser'
import { RoleEnum } from 'enums/RoleEnum'
import Unauthorized from 'pages/Unauthorized'
import Bench from 'pages/bench/Bench'
import SpinnerComponent from 'components/SpinnerComponent'
import ChartsViewSelector from 'pages/allCharts/ChartsViewSelector'
import UpdateEmployee from 'pages/employee/UpdateEmployee'
import Departments from './pages/departments/Departments';
// import Departments from './pages/departments/Departments';

function App() {
  const { accounts } = useMsal()
  return (
    <>
      {accounts.length > 0 ? (
        <AuthenticatedTemplate>
          <Provider store={store}>
            <Router>
              <Routes>
                <Route path='/' element={<Layout />}>
                  <Route path='/' element={<SpinnerComponent />}></Route>
                  <Route path='/unauthorized' element={<Unauthorized />} />
                  <Route
                    element={
                      <RequireUser
                        allowedRoles={[
                          RoleEnum.User,
                          RoleEnum.Admin,
                          RoleEnum.EEM,
                          RoleEnum.Marketing,
                        ]}
                      />
                    }
                  >
                    <Route path='/profile' element={<ProfilePage />} />
                    <Route path='/charts' element={<ChartsViewSelector />} />
                    {/* EMPLOYEES */}
                    <Route path='/singleEmployee/:employeeId?' element={<SingleEmpolyeePage />} />
                    <Route path='/employees' element={<Employees />} />
                    {/* CLIENTS */}
                    <Route path='/clients' element={<Clients />} />
                    {/* PROJECTS */}
                    <Route path='/projects' element={<Projects />} />
                    {/* TEAMS */}
                    <Route path='/teams' element={<Teams />} />
                    {/* DEPARTMENTS */}
                    <Route path='/departments' element={<Departments />} />
                  </Route>
                  <Route
                    element={
                      <RequireUser
                        allowedRoles={[RoleEnum.Admin, RoleEnum.EEM, RoleEnum.Marketing]}
                      />
                    }
                  >
                    {/* EMPLOYEES */}
                    <Route path='/employees/:employeeId' element={<Employee />} />
                    <Route path='/employees/update/:employeeId' element={<UpdateEmployee />} />
                  </Route>
                  <Route element={<RequireUser allowedRoles={[RoleEnum.Admin]} />}>
                    {/* TEAMS */}
                    <Route path='/teams/new' element={<CreateTeam />} />
                    <Route path='/teams/:teamId' element={<UpdateTeam />} />
                    {/* PROJECTS */}
                    <Route path='/projects/:projectId' element={<UpdateProject />} />
                    {/* CLIENTS */}
                    <Route path='/clients/:clientId' element={<EditClient />} />
                    {/* BENCH */}
                    <Route path='/bench' element={<Bench />} />
                  </Route>
                </Route>
              </Routes>
            </Router>
          </Provider>
        </AuthenticatedTemplate>
      ) : (
        <UnauthenticatedTemplate>
          <StartupPage />
        </UnauthenticatedTemplate>
      )}
    </>
  )
}

export default App
