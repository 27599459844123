import React, { useState, useEffect } from 'react'
import {
  useGetProjectQuery,
  useUpdateProjectMutation,
  useGetClientsQuery,
} from 'services/queries/api'
import { useParams, Link } from 'react-router-dom'
import { NewProject } from 'models/newProject.model'

const UpdateProject: React.FC = () => {
  const { projectId } = useParams()
  const { data: project } = useGetProjectQuery(projectId)
  const { data: clients } = useGetClientsQuery()
  const [updateProject, { isLoading, isSuccess, isError }] = useUpdateProjectMutation()
  const [projectData, setProjectData] = useState<NewProject>({ name: '' } as NewProject)
  const [showMessage, setShowMessage] = useState(true)

  useEffect(() => {
    if (project) {
      projectData.id = project.id
      projectData.clientId = project.client.id
      projectData.name = project.name
    }
  }, [project])

  if (isSuccess || isError) {
    setTimeout(() => {
      setShowMessage(false)
    }, 1500)
  }
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setProjectData((prevData) => ({
      ...prevData,
    }))
    try {
      await updateProject(projectData).unwrap()
      setTimeout(() => {
        window.location.href = '/projects'
      }, 1500)
    } catch (error) {
      console.log(error)
    }
  }
  const handleChange = (e: any) => {
    const { name } = e.target
    const value = e.target.value
    setProjectData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div className='container'>
          <h4>Edit Project</h4>
          <div className='col-xl-8'>
            <br />
            <div className='card mb-4'>
              <div className='card-header'>Project details</div>
              <div className='card-body'>
                <div className='mb-2'>
                  <label className='small mb-1'>
                    Name: <span className='text-danger'>*</span>
                    <input
                      name='name'
                      className='info-field bg-light-gray p-2 pl-3'
                      type='text'
                      defaultValue={project?.name}
                      onChange={handleChange}
                    />
                  </label>
                  <br />
                </div>
                <div className='mb-2'>
                  <label className='small mb-1'>
                    Client: <span className='text-danger'>*</span>
                    <select
                      className='d-block form-select form-select-sm form-hub-select bg-light-gray w-100 p-2 pl-3 pr-4'
                      name='clientId'
                      value={projectData?.clientId} // Set the value of the select element to the selected project
                      onChange={handleChange} // Call handleChange when the selection changes
                      required
                    >
                      {clients &&
                        clients.map((client) => (
                          <option key={client.id} value={client.id}>
                            {client.name}
                          </option>
                        ))}
                    </select>
                  </label>
                  <br />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Link to='/projects' className='btn btn-secondary' style={{ marginRight: 20 }}>
                Cancel
              </Link>
              <button
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                className='btn btn-primary'
                type='submit'
                disabled={isLoading}
              >
                {isLoading ? 'Applying...' : 'Apply'}
              </button>
            </div>
            {isSuccess && showMessage && (
              <div className='alert alert-success mt-3' role='alert'>
                The project has been successfully edited!
              </div>
            )}
            {isError && showMessage && (
              <div className='alert alert-danger mt-3' role='alert'>
                Error editing project. Please try again.
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}
export default UpdateProject
