import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil, BsArrowsAngleContract } from 'react-icons/bs'
import { PropertyFilter } from 'interfaces/interfaces'
import EmployeesFilter from 'components/filters/EmployeesFilter'
import Pagination from 'components/filters/Pagination'
import { useSelector } from 'react-redux'
import Loader from 'components/Loader'
import { useGetEmployeesFilteredQuery, useLazyGetEmployeesNameQuery } from 'services/queries/api'
import { FaPlus } from 'react-icons/fa'
import { ExportButton } from 'components/ExportButton'
import { ExportTypeEnum } from 'enums/ExportTypeEnum'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './employees.scss'
import { RoleEnum } from 'enums/RoleEnum'
import { useRenderComponentWithValidRole } from 'hooks/useRenderComponentWithValidRole'
import { SyncButton } from '../../components/SyncButton';

const Employees: React.FC = () => {
  const { currentPage } = useSelector((state: any) => state.pagination);
  const roles = useSelector((state: any) => state.token.value);

  const [propertyFilter, setPropertyFilter] = useState<PropertyFilter>({
    filter: {},
    pageNumber: 1,
    pageSize: 20,
    sortBy: 'Name',
    sortAscending: true,
  });

  const [propertyFilterAll, setPropertyFilterAll] = useState<PropertyFilter>({
    filter: {},
    pageNumber: 1,
    pageSize: 10000,
    sortBy: 'Name',
    sortAscending: true,
  });

  const { data, isFetching, refetch } = useGetEmployeesFilteredQuery(propertyFilter);
  const { data: allItemsData, isFetching: isFetchingAll, refetch: refetchAll } = useGetEmployeesFilteredQuery(propertyFilterAll);

  useEffect(() => {
    setPropertyFilterAll((prevFilter) => ({
      ...prevFilter,
      filter: propertyFilter.filter,
      sortBy: propertyFilter.sortBy,
      sortAscending: propertyFilter.sortAscending,
    }));
  }, [propertyFilter]);

  useEffect(() => {
    setPropertyFilter((prevFilter) => ({
      ...prevFilter,
      pageNumber: currentPage,
    }));
  }, [currentPage]);

  const onApplyFilterRefetch = () => {
    const timer = setTimeout(() => {
      if (!isFetching && !isFetchingAll) {
        refetch();
        refetchAll();
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  };
  const [showModal, setShowModal] = useState(false)

  const [getEmployeesName, { data: employeesName }] = useLazyGetEmployeesNameQuery()

  const handleOpenModal = () => {
    employeesName || getEmployeesName()
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }
  return (
    <>
      {isFetching && <Loader />}
      <div className='d-flex flex-row align-items-center mb-3 flex-wrap'>
        <div className='mr-auto p-2 col-md-4'>
          <div className='page-header'>
            <h3>Employees</h3>
          </div>
        </div>

        <div className='d-flex flex-wrap flex-nowrap-sm'>
          {useRenderComponentWithValidRole({
            roles,
            allowedRoles: [RoleEnum.Admin],
            children: (
              <>
                {/* <div className='p-2'>*/}
                {/*   <Link to='/employees/new' className='btn btn-outline-primary btn-sm mr-2'>*/}
                {/*    <FaPlus /> Create*/}
                {/*   </Link>*/}
                {/* </div>*/}
                <div className='p-2'>
                  <SyncButton />
                </div>
                <div className='p-2'>
                  <button
                    className='btn btn-outline-info btn-sm'
                    onClick={handleOpenModal}
                    style={{ display: 'none' }}
                  >
                    <BsArrowsAngleContract /> Compare Employees
                  </button>
                </div>
              </>
            ),
          })}
          {useRenderComponentWithValidRole({
            roles,
            allowedRoles: [RoleEnum.Admin, RoleEnum.EEM, RoleEnum.Marketing],
            children: (
              <div className='p-2'>
                <ExportButton
                  exportType={ExportTypeEnum.EmployeesSelectionExport}
                  ids={allItemsData?.filteredItems.map((e) => e.id)}
                />
              </div>
            ),
          })}
        </div>
      </div>

      <div className='d-flex flex-row'>
        <EmployeesFilter
          setPropertyFilter={setPropertyFilter}
          propertyFilter={propertyFilter}
          onApplyFilterRefetch={onApplyFilterRefetch}
        />
      </div>
      <div style={{ overflowY: 'auto' }}>
        <table className='table table-striped table-hover'>
          <thead>
            <tr>
              <th scope='col'>Full Name</th>
              <th scope='col'>Klika hub</th>
              <th scope='col'>Department</th>
              <th scope='col'>Position</th>
              {useRenderComponentWithValidRole({
                roles,
                allowedRoles: [RoleEnum.Admin, RoleEnum.EEM, RoleEnum.Marketing],
                children: (
                  <>
                    <th scope='col'></th>
                    <th scope='col'></th>
                  </>
                ),
              })}
            </tr>
          </thead>
          <tbody>
            {data?.filteredItems &&
              data?.filteredItems?.map((employee, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`/singleEmployee/${employee?.id}`}>
                      {employee?.fullName}
                    </Link>{' '}
                  </td>
                  <td>{employee?.klikaOffice}</td>
                  <td>{employee?.department}</td>
                  <td>{employee?.position}</td>
                  {useRenderComponentWithValidRole({
                    roles,
                    allowedRoles: [RoleEnum.Admin, RoleEnum.EEM, RoleEnum.Marketing],
                    children: (
                      <>
                        <td></td>
                        <td>
                          <Link to={`/employees/update/${employee?.id.toString()}`}>
                            <button className='btn btn-outline-primary btn-sm'>
                              <BsPencil />
                            </button>
                          </Link>
                        </td>
                      </>
                    ),
                  })}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        totalItems={data?.totalItems ?? 20}
        itemsPerPage={20}
        onApplyFilterRefetch={onApplyFilterRefetch}
      />
      <ToastContainer autoClose={3000} hideProgressBar className='w-50' />
    </>
  )
}

export default Employees
