import { createSlice } from '@reduxjs/toolkit'

interface PaginationState {
  currentPage: number
  totalPages: number
  itemsPerPage: number
}

const initialState: PaginationState = {
  currentPage: 1,
  totalPages: 0,
  itemsPerPage: 20,
}

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload
    },
  },
})
export const { setCurrentPage, setTotalPages, setItemsPerPage } = paginationSlice.actions

export default paginationSlice.reducer
