import React, { FC, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import 'react-datepicker/dist/react-datepicker.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  FormInput,
  FormSelect,
  FormDatePicker,
  FormCheckbox,
} from '../../../components/formComponents'
// --- DevTool test react hooks form ---
// import { DevTool } from '@hookform/devtools'
import { EmploymentDetails } from 'models/employmentDetails.model'
import { useLazyGetEmployeesNameQuery } from 'services/queries/api'
import { convertDateToISOString } from 'utils/convertDateToISOString'
import DefaultButton from 'components/DefaultButton'
import { EmploymentDropdownData } from 'models/dropdownData.model'
import { ADWarning } from 'enums/ADWarning'
// --- Validations yup
// import { yupResolver } from '@hookform/resolvers/yup'
// import userUpdateFormSchema from '../validation/schemas/UserUpdateFormSchema'

interface EmployeeFormProps {
  employee: EmploymentDetails
  onSubmit: (data: any) => void
  dropdownData: EmploymentDropdownData | undefined
}

const EmploymentDetailsForm: FC<EmployeeFormProps> = ({ employee, onSubmit, dropdownData }) => {
  const form = useForm<EmploymentDetails>({
    defaultValues: {
      id: employee.id,
      departmentId: employee.departmentId,
      divisionId: employee.divisionId,
      positionId: employee.positionId,
      engineeringTypeId: employee.engineeringTypeId,
      managerExternalUserId: employee.managerExternalUserId,
      employeeExperienceManagerId: employee.employeeExperienceManagerId,
      klikaOfficeId: employee.klikaOfficeId,
      employeeHireDate: employee.employeeHireDate,
      endDate: employee.endDate,
      employmentType: employee.employmentType,
      companyEmail: employee.companyEmail,
      companyPhoneNumber: employee.companyPhoneNumber,
      isEmployeeEditDone: employee.isEmployeeEditDone,
    },
    // Use yup validations - userUpdateFormSchema
    // resolver: yupResolver(userUpdateFormSchema),
    mode: 'onBlur',
  })
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty, isValid, dirtyFields },
  } = form
  const selectedDepartment = watch('departmentId')

  const filteredDivisions = dropdownData?.divisions.filter(
      (division) => (division.parentId) === (selectedDepartment),
  )

  useEffect(() => {
    if (filteredDivisions?.length === 0) setValue('divisionId', null)
    if (selectedDepartment !== dropdownData?.departments?.find(x=>x.label === 'Engineering')?.value) setValue('engineeringTypeId', null)
  }, [selectedDepartment, setValue, filteredDivisions])

  const [getEmployeesName, { data: employeesName }] = useLazyGetEmployeesNameQuery()

  useEffect(() => {
    if (!employeesName || employeesName.length === 0) {
      getEmployeesName()
    }
  }, [getEmployeesName, employeesName])

  const employeeOptions = (employeesName || []).map((employee: any) => ({
    label: `${employee.firstName} ${employee.lastName}`,
    value: employee.id,
  }))

  const onSubmitForm = (data: EmploymentDetails) => {
    data.employeeHireDate = convertDateToISOString(data?.employeeHireDate)
    data.endDate = convertDateToISOString(data?.endDate)
    onSubmit(data)
  }
  const [isEditEmployeeDoneChecked, setIsEditEmployeeDoneChecked] = useState(false)
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEditEmployeeDoneChecked(event.target.checked)
    setValue('isEmployeeEditDone', !isEditEmployeeDoneChecked)
  }

  return (
    <div className='row justify-content-left'>
      <div className='col-md-12 mt-3 mb-1 p-3'>
        <h4>Employment details</h4>
      </div>
      <hr className='mb-5 ml-0 mr-0' />
      <form className='col-6 form-max-width' onSubmit={handleSubmit(onSubmitForm)} noValidate>
        <div className='row pl-3 pr-3 mb-2'>
          <FormSelect
            label='Department'
            id='departmentId'
            control={control}
            options={dropdownData?.departments}
            onChange={(value) => {
              setValue('departmentId', value === '' ? null : (value.toString()))
              setValue('divisionId', null)
            }}
            error={errors.departmentId}
            className='col-md-6'
            showWarning={dirtyFields.departmentId}
            tooltip={ADWarning}
          />
          <FormSelect
            label='Division'
            id='divisionId'
            control={control}
            options={filteredDivisions}
            disabled={filteredDivisions?.length === 0}
            error={errors.divisionId}
            onChange={(value) => setValue('divisionId', value === '' ? null : (value.toString()))}
            className='col-md-6'
          />
        </div>
        {selectedDepartment === dropdownData?.departments?.find(x=>x.label === 'Engineering')?.value && (
          <FormSelect
            label='Engineering type'
            id='engineeringTypeId'
            control={control}
            options={dropdownData?.engineeringTypes}
            error={errors.engineeringTypeId}
            className='col-md-12 mt-2'
          />
        )}
        <FormSelect
          label='Positions'
          id='positionId'
          control={control}
          options={dropdownData?.positions}
          error={errors.positionId}
          className='col-md-12 mt-2'
          showWarning={dirtyFields.positionId}
          tooltip={ADWarning}
        />
        <FormSelect
          label='Direct Supervisor'
          id='managerExternalUserId'
          control={control}
          options={employeeOptions}
          error={errors.managerExternalUserId}
          className='col-md-12 mt-2'
        />
        <FormSelect
          label='Experience manager'
          id='employeeExperienceManagerId'
          control={control}
          options={employeeOptions}
          error={errors.employeeExperienceManagerId}
          className='col-md-12 mt-2'
        />
        <FormSelect
          label='Klika office'
          id='klikaOfficeId'
          control={control}
          options={dropdownData?.klikaOffices}
          error={errors.klikaOfficeId}
          className='col-md-12 mt-2'
        />
        <div className='row pl-3 pr-3 mb-2'>
          <FormDatePicker
            label='Hire date'
            id='employeeHireDate'
            control={control}
            error={errors.employeeHireDate}
            dateFormat='dd/MM/yyyy'
            className='col-md-4 mt-2'
          />
          <FormDatePicker
            label='End date'
            id='endDate'
            control={control}
            error={errors.endDate}
            dateFormat='dd/MM/yyyy'
            className='col-md-4 mt-2'
          />
        </div>
        <FormSelect
          label='Employment type'
          id='employmentType'
          control={control}
          options={dropdownData?.employmentTypes}
          error={errors.employmentType}
          className='col-md-12 mt-2'
        />
        <div className='col-md-12 row justify-content-left mt-5 ml-0  mb-2'>
          <h5>Employee contact information</h5>
        </div>
        <div className='row pl-3 pr-3 mb-2'>
          <FormInput
            label='Company email'
            id='companyEmail'
            register={register}
            error={errors.companyEmail}
            className='col-md-6'
            disabled={true}
          />
          <FormInput
            label='Company phone number'
            id='companyPhoneNumber'
            register={register}
            error={errors.companyPhoneNumber}
            className='col-md-6'
            type='tel'
          />
        </div>
        <div className='row mt-3 mb-1 p-3'>
          <div className='col-md-7 p-0'>
            <FormCheckbox
              id='isEmployeeEditDone'
              label='Employee edit done?'
              onChange={handleCheckboxChange}
              isChecked={isEditEmployeeDoneChecked}
            />
          </div>
          <div className='col-md-12 d-flex justify-content-end'>
            <DefaultButton
              type='submit'
              // disabled={!isDirty || !isValid}
              color='outline-primary'
            >
              Save
            </DefaultButton>
          </div>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </div>
  )
}

export default EmploymentDetailsForm
