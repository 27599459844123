export class TeamRole {
  id!: number
  name!: string | undefined
  rules!: {
    viewDetails: boolean
    cud: boolean
  }

  constructor(id: number, name?: string) {
    this.id = id
    this.name = name
  }
}
