import React from 'react'
import DatePicker from 'react-datepicker'
import { Controller } from 'react-hook-form'

interface Props {
  label: string
  id: string
  control: any
  error?: any
  dateFormat: any
  className?: string
  required?: boolean
  rules?: any
}

const FormDatePicker: React.FC<Props> = ({
  label,
  id,
  control,
  error = null,
  dateFormat,
  className = 'col-md-4',
  required = false,
  rules = {}
}) => {
  return (
    <div className={className}>
      <label htmlFor={id} className='form-label'>
        {label} {required && <span className='text-danger'>*</span>}
      </label>
      <div className='input-group date customDatePickerWidth'>
        <Controller
          control={control}
          name={id}
          rules={rules}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              className='form-control'
              id={id}
              selected={value ? new Date(value) : null}
              onChange={onChange}
              dateFormat={dateFormat}
            />
          )}
        />
      </div>
      {error && <p className='text-danger'>{error.message}</p>}
    </div>
  )
}

export default FormDatePicker
