import { PublicClientApplication } from '@azure/msal-browser'
import { type MiddlewareAPI, type Middleware, isRejectedWithValue } from '@reduxjs/toolkit'
import { msalConfig } from 'config/authConfig'

export const rtkQueryErrorHandlingMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => async (action) => {
    const msalInstance = new PublicClientApplication(msalConfig)

    if (isRejectedWithValue(action)) {
      if (action.payload.status === 401) {
        window.location.href = '/'
        localStorage.clear()
      }
    }

    return next(action)
  }
