import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Employee } from 'models/employee.model'
import { Client } from 'models/client.model'
import { Project } from 'models/project.model'
import { NewProject } from 'models/newProject.model'
import { Team } from 'models/team.model'
import { PropertyFilter, EmployeeDropdown, DropdownItem, EmployeeNamesExternalIdsDropdown } from 'interfaces/interfaces'
import { EmployeesList } from 'models/employeesList.model'
import { NewTeam } from 'models/newTeam.model'
import { DropdownData } from 'models/dropdownData.model'
import { EmployeeBasic } from 'models/employeeBasic.model'
import { EmployeeChild } from 'models/employeeChild.model'
import { ClientWithProjects } from 'models/clientWithProjects.model'
import { EmployeeNode } from 'models/employeeNode.model'
import { Department } from 'models/department.model'
import { Division } from 'models/division.model'
import { Assignment } from 'models/assignment.model'
import { NewDivision} from '../../models/newDivision.model';


export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    credentials: 'include',
    prepareHeaders: async (headers: any) => {
      headers.set('Content-Type', 'application/json')
      return headers
    },
  }),
  tagTypes: ['Employees', 'Employee', 'Team', 'Teams', 'Clients', 'Bench','Department', 'Division'],
  endpoints: (builder) => ({
    getEmployeesFiltered: builder.query<EmployeesList, PropertyFilter>({
      query: (filter) => ({
        url: 'api/employees',
        params: new URLSearchParams({
          filter: JSON.stringify(filter.filter),
          pageNumber: filter.pageNumber.toString(),
          pageSize: filter.pageSize.toString(),
          sortBy: filter.sortBy || '',
          sortAscending: filter.sortAscending.toString(),
        }),
      }),
      providesTags: ['Employees'],
    }),
    getTeams: builder.query<Team[], void>({
      query: () => '/api/teams',
      providesTags: ['Teams'],
    }),
    getEmployeesName: builder.query<EmployeeDropdown[], void>({
      query: () => 'api/employees/getemployeesname',
      providesTags: ['Employees'],
    }),
    getEmployeesNamesAndExternalIds: builder.query<EmployeeNamesExternalIdsDropdown[], void>({
      query: () => 'api/employees/namesandexternalids',
      providesTags: ['Employees'],
    }),
    getEmployee: builder.query({
      query: (id) => `api/employees/id/${id}`,
      providesTags: ['Employee'],
    }),
    getEmployeeHierarchyNode: builder.query({
      query: (id) => `api/employees/hierarchygraphnode/${id}`,
      providesTags: ['Employee'],
    }),
    getEmployeeByAzureId: builder.query({
      query: (id) => `api/employees/azure/${id}`,
    }),
    getDepartments: builder.query<Department[], void>({
      query: () => 'api/departments/',
    }),
    getDepartmentById: builder.query<Department, void>({
      query: (id) => `api/departments/${id}`,
    }),
      createDepartment: builder.mutation<Department, Partial<Department>>({
  query: (department) => ({
    url: 'api/departments',
    method: 'POST',
    body: JSON.stringify(department),
  }),
  invalidatesTags: ['Teams'],
}),
    updateDepartment: builder.mutation<Department, Partial<Department>>({
  query: (department) => ({
    url: 'api/departments',
    method: 'PUT',
    body: department,
  }),
  invalidatesTags: ['Department'],
}),
    deleteDepartment: builder.mutation({
          query: (id) => ({
              url: `api/departments/${id}`,
              method: 'DELETE',
          }),
        invalidatesTags: ['Department'],

    }),
    getDivisions: builder.query<Division[], void>({
      query: () => 'api/divisions',
    }),
    getDivision: builder.query({
      query: (id) => `api/divisions/${id}`,
    }),

      createDivision: builder.mutation<NewDivision, Partial<NewDivision>>({
          query: (project) => ({
              url: 'api/divisions',
              method: 'POST',
              body: JSON.stringify(project),
          }),
      }),
      updateDivision: builder.mutation<Division, Partial<Division>>({
          query: (division) => ({
              url: 'api/divisions',
              method: 'PUT',
              body: division,
          }),
          invalidatesTags: ['Division'],
      }),
      deleteDivision: builder.mutation({
          query: (id) => ({
              url: `api/divisions/${id}`,
              method: 'DELETE',
          }),
          invalidatesTags: ['Division'],
      }),
    getBenchEmployees: builder.query({
      query: (params) => ({
        url: 'api/employees/getBenchEmployees',
        method: 'GET',
        params,
      }),
      providesTags: ['Bench'],
    }),
    getProjects: builder.query<Project[], void>({
      query: () => 'api/projects',
    }),
    getBenchProjects: builder.query<Project[], void>({
      query: () => 'api/projects/getBenchProjects',
    }),
    getProjectsWithTeams: builder.query<any, void>({
      query: () => 'api/projects/getProjectsWithTeams',
    }),
    deleteProject: builder.mutation({
      query: (id) => ({
        url: `api/projects/${id}`,
        method: 'DELETE',
      }),
    }),
    createProject: builder.mutation<NewProject, Partial<NewProject>>({
      query: (project) => ({
        url: 'api/projects',
        method: 'POST',
        body: JSON.stringify(project),
      }),
    }),
    updateProject: builder.mutation<NewProject, Partial<NewProject>>({
      query: (project) => ({
        url: 'api/projects',
        method: 'PUT',
        body: project,
      }),
    }),
    getProject: builder.query({
      query: (id) => `api/projects/${id}`,
    }),
    getClients: builder.query<Client[], void>({
      query: () => 'api/clients',
      providesTags: ['Clients'],
    }),
    getClientsWithProjects: builder.query<ClientWithProjects[], void>({
      query: () => 'api/clients/getClientsWithProjects',
    }),
    getClient: builder.query({
      query: (id) => `api/clients/${id}`,
    }),
    deleteClient: builder.mutation({
      query: (id) => ({
        url: `api/clients/${id}`,
        method: 'DELETE',
      }),
    }),
    createClient: builder.mutation<Client, Partial<Client>>({
      query: (client) => ({
        url: 'api/clients',
        method: 'POST',
        body: JSON.stringify(client),
      }),
    }),
    updateClient: builder.mutation<Client, Partial<Client>>({
      query: (client) => ({
        url: 'api/clients',
        method: 'PUT',
        body: client,
      }),
    }),
    createEmployee: builder.mutation<Employee, Partial<Employee>>({
      query: (employee) => ({
        url: 'api/employees',
        method: 'POST',
        body: JSON.stringify(employee),
      }),
      invalidatesTags: ['Employees'],
    }),
    updateEmployee: builder.mutation<Employee, Partial<Employee>>({
      query: (employee) => ({
        url: 'api/employees',
        method: 'PUT',
        body: JSON.stringify(employee),
      }),
      invalidatesTags: ['Employees', 'Employee'],
    }),
    updateEmployeeNew: builder.mutation<any, Partial<any>>({
      query: (employee) => ({
        url: 'api/employees/personaldata',
        method: 'PATCH',
        body: JSON.stringify(employee),
      }),
      invalidatesTags: ['Employees', 'Employee'],
    }),
    updateEmployeeEmpAccount: builder.mutation<any, Partial<any>>({
      query: (employee) => ({
        url: 'api/employees/empaccount',
        method: 'PATCH',
        body: JSON.stringify(employee),
      }),
      invalidatesTags: ['Employees', 'Employee'],
    }),
    updateEmploymentData: builder.mutation<any, Partial<any>>({
      query: (employee) => ({
        url: 'api/employees/employmentdata',
        method: 'PATCH',
        body: JSON.stringify(employee),
      }),
      invalidatesTags: ['Employees', 'Employee'],
    }),
    deleteTeam: builder.mutation({
      query: (id) => ({
        url: `api/teams/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Teams'],
    }),
    createTeam: builder.mutation<NewTeam, Partial<NewTeam>>({
      query: (team) => ({
        url: 'api/teams',
        method: 'POST',
        body: JSON.stringify(team),
      }),
      invalidatesTags: ['Teams'],
    }),
    updateTeam: builder.mutation<Team, Partial<Team>>({
      query: (team) => ({
        url: 'api/teams',
        method: 'PUT',
        body: team,
      }),
      invalidatesTags: ['Teams', 'Team', 'Clients', 'Bench', 'Employee'],
    }),
    updateBenchAssignments: builder.mutation<any, Partial<any>>({
      query: (data) => ({
        url: 'api/assignments/updateBenchAssignment',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Teams']
    }),
    getTeam: builder.query<Team, string>({
      query: (id) => `api/teams/${id}`,
      providesTags: ['Team'],
    }),
    getEmployeePhoto: builder.query({
      query: (id) => `api/employees/photo/${id}`,
    }),
    getEmployeeDropdownData: builder.query<DropdownData, void>({
      query: () => '/api/employees/getdropdowndata',
    }),
    addChildToEmployee: builder.mutation<EmployeeChild, Partial<EmployeeChild>>({
      query: (child) => ({
        url: 'api/employees/children',
        method: 'POST',
        body: JSON.stringify(child),
      }),
    }),
    getEmployeesNamePosition: builder.query<EmployeeBasic[], void>({
      query: () => 'api/employees/getemployeesnameposition',
      providesTags: ['Employees'],
    }),
    getEmployeePhotoByAzureId: builder.query({
      query: (id) => `api/employees/photo/azure/${id}`,
    }),
    getTeamsFilter: builder.query({
      query: (params) => ({
        url: 'api/teams/getAllTeamsFilter',
        method: 'GET',
        params,
      }),
      providesTags: ['Teams'],
    }),
    getTeamRoles: builder.query<DropdownItem[], void>({
      query: () => 'api/teams/getteamroles',
    }),
    getEmployeesNodeInfo: builder.query<EmployeeNode[], void>({
      query: () => 'api/employees/getemployeesnodeinfo',
      providesTags: ['Employees'],
    }),
    getEEMNamesAndExternalIds: builder.query<EmployeeNamesExternalIdsDropdown[], void>({
      query: () => 'api/employees/eems',
    }),
    deleteAssignment: builder.mutation({
      query: (assignment) => ({
        url: 'api/assignments',
        method: 'DELETE',
        body: assignment,
        invalidatesTags: ['Employees', 'Employee', 'Teams'],
      }),
    }),
    createAssignment: builder.mutation<Assignment, Partial<Assignment>>({
      query: (assignment) => ({
        url: 'api/assignments',
        method: 'POST',
        body: assignment,
        invalidatesTags: ['Employees', 'Employee', 'Teams'],
      }),
    }),
    updateAssignment: builder.mutation<Assignment, Partial<Assignment>>({
      query: (assignment) => ({
        url: 'api/assignments',
        method: 'PUT',
        body: assignment,
        invalidatesTags: ['Employees', 'Employee', 'Teams'],
      }),
    }),
  }),
})

export const {
  useGetEmployeesFilteredQuery,
  useLazyGetEmployeesNameQuery,
  useGetEmployeesNamesAndExternalIdsQuery,
  useGetEmployeeQuery,
  useGetEmployeeHierarchyNodeQuery,
  useLazyGetEmployeeQuery,
  useGetEmployeeByAzureIdQuery,
  useGetClientsQuery,
  useGetClientsWithProjectsQuery,
  useGetBenchProjectsQuery,
  useGetClientQuery,
  useDeleteClientMutation,
  useCreateClientMutation,
  useUpdateClientMutation,
  useGetTeamsQuery,
  useGetProjectsQuery,
  useGetProjectsWithTeamsQuery,
  useGetBenchEmployeesQuery,
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  useGetProjectQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useUpdateEmployeeNewMutation,
  useUpdateEmployeeEmpAccountMutation,
  useUpdateEmploymentDataMutation,
  useCreateTeamMutation,
  useDeleteTeamMutation,
  useUpdateTeamMutation,
  useGetTeamQuery,
  useGetEmployeePhotoQuery,
  useGetEmployeeDropdownDataQuery,
  useAddChildToEmployeeMutation,
  useGetEmployeesNamePositionQuery,
  useUpdateBenchAssignmentsMutation,
  useGetEmployeePhotoByAzureIdQuery,
  useGetTeamsFilterQuery,
  useGetTeamRolesQuery,
  useGetEmployeesNodeInfoQuery,
  useGetDepartmentsQuery,
  useGetDepartmentByIdQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
  useGetDivisionsQuery,
  useCreateDivisionMutation,
  useUpdateDivisionMutation,
  useDeleteDivisionMutation,
  useGetDivisionQuery,
  useGetEEMNamesAndExternalIdsQuery,
  useCreateAssignmentMutation,
  useDeleteAssignmentMutation,
  useUpdateAssignmentMutation
} = api
