export class ProfileImage {
    azureId?: string
    image?: File

    constructor(profileImage?: any) {
        if (profileImage) {
            Object.assign(this, profileImage)
        }
    }
}
