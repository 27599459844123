import React, { useState, useRef } from 'react';
import { BsTrash, BsPencil } from 'react-icons/bs';
import { MessageTypeEnum } from 'enums/MessageTypeEnum';
import { useRenderComponentWithValidRole } from 'hooks/useRenderComponentWithValidRole';
import { useSelector } from 'react-redux';
import './departments.scss';
import CreateDepartmentModal from './CreateDepartmentModal';
import UpdateDepartmentModal from './UpdateDepartmentModal';
import ModalShowMessage from '../../components/ModalShowMessage';
import {
  useGetDepartmentsQuery,
  useGetDivisionsQuery,
  useDeleteDepartmentMutation,
  useDeleteDivisionMutation,
} from 'services/queries/api';
import { RoleEnum } from 'enums/RoleEnum';

const Departments: React.FC = () => {
  const { data: departments, refetch: refetchDepartment } = useGetDepartmentsQuery();
  const { data: divisions, refetch: refetchDivision } = useGetDivisionsQuery();
  const roles = useSelector((state: any) => state.token.value);
  const [showNewDepartmentModal, setShowNewDepartmentModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<number>(0);
  const [deleteDepartmentMutation, { isSuccess, isError }] = useDeleteDepartmentMutation();
  const [deleteDivisionMutation] = useDeleteDivisionMutation();
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false);
  const idRef = useRef<string>('');
  const messageRef = useRef<string>();
  const messageTypeRef = useRef<MessageTypeEnum>(MessageTypeEnum.information);

  const transformTableData: any = [];

  departments?.map((department: any) => {
    transformTableData.push({ ...department, type: 'department' });
    divisions?.filter((division) => division?.departmentActual.id === department.id).map((division) =>
        transformTableData.push({ ...division, type: 'division' })
    );
  });

  const handleEdit = (id: number) => {
    setSelectedItemId(id);
    setShowUpdateModal(true);
  };

  const handleDelete = (id: any) => {
    messageRef.current = 'Are you sure you want to delete item?';
    idRef.current = id;
    messageTypeRef.current = MessageTypeEnum.question;
    setIsMessageBoxOpen(true);
  };

  const handleConfirmation = async () => {
    setIsMessageBoxOpen(false);
    try {
      const item = transformTableData.find((item: any) => item.id === idRef.current);
      if (item) {
        if (item.type === 'department') {
          await deleteDepartmentMutation(idRef.current);
          if (isError) {
            messageRef.current = 'Cannot delete department with existing divisions.';
            messageTypeRef.current = MessageTypeEnum.information;
            setIsMessageBoxOpen(true);
            refetchDepartment();
          } else {
            refetchDepartment();
          }
        } else if (item.type === 'division') {
          await deleteDivisionMutation(idRef.current);
          refetchDivision();
          refetchDepartment();
        }
      }
    } catch (error: any) {
      messageRef.current = error.data.message;
      messageTypeRef.current = MessageTypeEnum.information;
      setIsMessageBoxOpen(true);
      refetchDepartment();
      refetchDivision();
      console.log(error);
    }
  };

  const closeMessageBox = () => {
    setIsMessageBoxOpen(false);
  };

  return (
      <>
        <div className='d-flex mb-3 flex-row align-items-center flex-wrap'>
          <div className='mr-auto p-2'>
            <div className='page-header'>
              <h3>Departments</h3>
            </div>
          </div>
          <div>
            {useRenderComponentWithValidRole({
              roles,
              allowedRoles: [RoleEnum.Admin],
              children: (
                  <div>
                    <button
                        className='btn btn-outline-primary btn-sm mr-2'
                        onClick={() => setShowNewDepartmentModal(true)}
                    >
                      Create new
                    </button>
                  </div>
              ),
            })}
            {showNewDepartmentModal && (
                <CreateDepartmentModal onClose={() => { setShowNewDepartmentModal(false); refetchDepartment(); refetchDivision(); }} />
            )}
          </div>
        </div>

        <div style={{ overflowY: 'auto' }}>
          <table className='table table-striped table-hover'>
            <thead>
            <tr>
              <th scope='col'> Department</th>
            </tr>
            </thead>
            <tbody>
            {transformTableData?.length === 0 ? (
                <tr>
                  <td colSpan={10} className='text-center'>
                    No data available.
                  </td>
                </tr>
            ) : (
                transformTableData?.map((row: any, index: any) => (
                    <tr key={index + 1}>
                      <td>
                        <div className={row.type == 'department' ? 'departments-container' : 'departments-container division-row'}>{row?.name}</div>
                      </td>
                      {useRenderComponentWithValidRole({
                        roles,
                        allowedRoles: [RoleEnum.Admin],
                        children: (
                            <>
                              <td className='d-flex justify-content-end'>
                                <button
                                    className='btn btn-outline-primary mr-2'
                                    onClick={() => {
                                      handleEdit(row.id);
                                    }}
                                >
                                  <BsPencil />
                                </button>
                                <button
                                    className='btn btn-outline-danger'
                                    onClick={() => handleDelete(row.id)}
                                >
                                  <BsTrash />
                                </button>
                              </td>
                            </>
                        ),
                      })}
                    </tr>
                ))
            )}
            </tbody>
          </table>
        </div>

        {showUpdateModal && (
            <UpdateDepartmentModal
                onClose={() => {
                  setShowUpdateModal(false);
                  refetchDepartment();
                  refetchDivision();
                }}
                itemId={selectedItemId}
                isDepartment={transformTableData.find((item: any) => item.id === selectedItemId)?.type === 'department'}
            />
        )}
        {isMessageBoxOpen && (
            <ModalShowMessage
                onClose={closeMessageBox}
                showModal={isMessageBoxOpen}
                message={messageRef.current}
                onChange={handleConfirmation}
                messageType={messageTypeRef.current}
            />

        )}
      </>
  );
};

export default Departments;
