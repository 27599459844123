import React from 'react';
import { Employee } from 'models/employee.model'
import styles from './EmploymentInformation.scss';
import { formatDate, formatEmploymentType } from 'utils/formatters';
export interface EmploymentInformationProps {
  profile: Employee;
}
export function EmploymentInformation({ profile }: EmploymentInformationProps) {
  return (
    <div className="row">
      <div className="col-12">
        <h4 className='header'>Employment Information</h4>
      </div>
      <div className="col">
        <h5>Type of employment</h5> {formatEmploymentType(profile.employmentType)}
        <h5>Start of employment</h5> {formatDate(profile?.employeeHireDate ?? '')}
        <h5>End of employment</h5> {profile?.endDate != null ? formatDate(profile?.endDate) : '-'}
        <h5>Klika office</h5> {profile?.klikaOffice}
      </div>
      <div className="col">
        <h5>Department</h5> {profile?.department}
        <h5>Division</h5> {profile?.division}
        <h5>Position</h5> {profile?.position}
      </div>
    </div>
  )
}
