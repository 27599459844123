import React, { useState, useEffect } from 'react'
import { useGetClientQuery, useUpdateClientMutation } from 'services/queries/api'
import { useParams, Link } from 'react-router-dom'
import { Client } from 'models/client.model'

const EditClient: React.FC = () => {
  const { clientId } = useParams()
  const { data: client } = useGetClientQuery(clientId)
  const [updateClient, { isLoading, isSuccess, isError }] = useUpdateClientMutation()
  const [clientData, setClientData] = useState<Client>(client)
  const [showMessage, setShowMessage] = useState(true)

  useEffect(() => {
    if (client) {
      setClientData(client)
    }
  }, [client])

  if (isSuccess || isError) {
    setTimeout(() => {
      setShowMessage(false)
    }, 1500)
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    setClientData((prevData) => ({
      ...prevData,
    }))

    try {
      await updateClient(clientData).unwrap()
      setTimeout(() => {
        window.location.href = '/clients'
      }, 1500)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target

    const value = e.target.value
    setClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div className='container'>
          <h4>Edit Client</h4>
          <div className='col-xl-8'>
            <br />
            <div className='card mb-4'>
              <div className='card-header'>Client details</div>
              <div className='card-body'>
                <div className='mb-2'>
                  <label className='small mb-1'>
                    Name: <span className='text-danger'>*</span>
                    <input
                      name='name'
                      className='info-field bg-light-gray p-2 pl-3'
                      type='text'
                      defaultValue={client?.name}
                      onChange={handleChange}
                    />
                  </label>
                  <br />
                  <label className='small mb-1'>
                    Num of employees:
                    <input
                      name='name'
                      className='info-field bg-light-gray p-2 pl-3'
                      type='text'
                      defaultValue={client?.numberOfEmployees}
                      disabled
                    />
                  </label>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Link to='/clients' className='btn btn-secondary' style={{ marginRight: 20 }}>
                Cancel
              </Link>
              <button
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                className='btn btn-primary'
                type='submit'
                disabled={isLoading}
              >
                {isLoading ? 'Applying...' : 'Apply'}
              </button>
            </div>
            {isSuccess && showMessage && (
              <div className='alert alert-success mt-3' role='alert'>
                Client has been successfully edited!
              </div>
            )}
            {isError && showMessage && (
              <div className='alert alert-danger mt-3' role='alert'>
                Error editing client. Please try again.
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

export default EditClient
