import { configureStore } from '@reduxjs/toolkit'
import userSliceReducer from './slices/userSlice'
import tokenSliceReducer from './slices/tokenSlice'
import paginationSliceReducer from './slices/paginationSlice'
import { authApi } from './queries/apiAuth'
import { api } from './queries/api'
import { rtkQueryErrorHandlingMiddleware } from 'middleware/rtkQueryErrorHandlingMiddleware'

export const store = configureStore({
  reducer: {
    user: userSliceReducer,
    token: tokenSliceReducer,
    pagination: paginationSliceReducer,
    [api.reducerPath]: api.reducer,
    [authApi.reducerPath]: authApi.reducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      api.middleware,
      rtkQueryErrorHandlingMiddleware,
    ]),
})
