import { ExportTypeEnum } from 'enums/ExportTypeEnum'
import React, { useState } from 'react'
import BootstrapButton from 'react-bootstrap/Button'
import { FaFileExcel } from 'react-icons/fa'

interface Props {
  exportType: ExportTypeEnum,
  ids: string[] | undefined
}

export const ExportButton: React.FC<Props> = ({ exportType, ids }) => {
  const [isDisabled, setIsDisabled] = useState(false)

  const handleExport = () => {
    setIsDisabled(true)
    const baseUrl = process.env.REACT_APP_BASE_URL
    // Create headers object with 'Accept' header set to 'application/octet-stream'
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')

    // Make a request to the backend API
    fetch(`${baseUrl}api/export`, {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      body: JSON.stringify({ 'exportType': exportType, 'ids': ids })
    })
      .then((response) => {
        // Extract the file name from the response headers
        const contentDisposition = response.headers.get('Content-Disposition')
        const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/)
        const fileName = fileNameMatch ? fileNameMatch[1] : `${ExportTypeEnum[exportType]}.xlsx`
        return response.blob().then((blob) => ({ blob, fileName }))
      })
      .then(({ blob, fileName }) => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob)

        // Create a link element and simulate a click event
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()

        // Clean up the created URL and link element
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
        setIsDisabled(false)
      })
      .catch((error) => {
        console.error('Error exporting data:', error)
        setIsDisabled(false)
      })
  }

  return (
    <BootstrapButton
      disabled={isDisabled}
      variant='outline-success'
      size='sm'
      onClick={handleExport}
    >
      <FaFileExcel /> Export to Excel
    </BootstrapButton>
  )
}
