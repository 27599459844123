import React, {MutableRefObject, useRef, useState} from 'react'
import { InputGroup, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import DefaultButton from '../DefaultButton';
import './FileInput.scss'

interface Props {
    label: string
    id: string
    setValue: any
    error?: any
    required?: boolean
    className?: string
    disabled?: boolean
    showWarning?: boolean
    tooltip?: string
    tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left'
    placeholder?: string
    photoUrl?:string
}

const ImageInput: React.FC<Props> = ({
                                         id,
                                         setValue,
                                         error = null,
                                         className = 'col-md-12 mb-3',
                                         disabled = false,
                                         showWarning = false,
                                         tooltip,
                                         tooltipPlacement = 'right',
                                         placeholder,
                                         photoUrl
                                     }) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null)
    const buttonRef = useRef<any>(null);
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {

            const file = e.target.files[0];
            const url = URL.createObjectURL(file)
            setSelectedImage(url)

            setValue(id,e.target.files[0])
        }
    }
    const handleRemoveClick = () => {
        setValue(id, undefined)
        setSelectedImage(null);
    };

    return (
        <div className={className}>
            <InputGroup hasValidation className='input-group'>
                {tooltip && showWarning ? (
                    <OverlayTrigger
                        key={tooltipPlacement}
                        placement={tooltipPlacement}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip className='ml-2' id={`tooltip-${tooltipPlacement}`}>
                                {tooltip}
                            </Tooltip>
                        }
                    >
                        <Form.Control
                            type='file'
                            ref={buttonRef}
                            // accept='image/*'
                            isInvalid={showWarning}
                            className='custom-input-color file-container'
                            disabled={disabled}
                            id={id}
                            placeholder={placeholder}
                            onChange={handleImageChange}
                        />
                    </OverlayTrigger>
                ) : (
                    <>
                        <Form.Control
                            type='file'
                            ref={buttonRef}
                            // accept='image/*'
                            isInvalid={showWarning}
                            className='custom-input-color file-container'
                            disabled={disabled}
                            id={id}
                            placeholder={placeholder}
                            onChange={handleImageChange}
                        />
                        <DefaultButton
                            color='outline-primary'
                            onClick={()=> {
                                if(buttonRef && buttonRef.current) {
                                    buttonRef.current?.click()

                                }
                            }}

                        >
                            <i className='fa fa-plus'></i> Add profile Image
                        </DefaultButton></>
                )}
            </InputGroup>
            <div className='image-container'>
                {(selectedImage||photoUrl)&&
                    <div className='image-component'>
                        <img src={selectedImage || 'data:image/png;base64,' + photoUrl} alt='Selected' className='image-preview'/>
                        <DefaultButton
                            color='outline-danger'
                            onClick={()=> {
                                handleRemoveClick()
                            }
                            }

                        >
                            <i className='fa fa-minus'></i> Remove profile image
                        </DefaultButton>
                    </div>
                }
                {error && <p className='text-danger'>{error.message}</p>}
            </div>
        </div>
    )
}

export default ImageInput
