import React, { useEffect, useState } from 'react'
import {
  useCreateTeamMutation,
  useGetProjectsQuery,
  useGetClientsQuery,
  useCreateProjectMutation,
} from 'services/queries/api'
import { NewTeam } from 'models/newTeam.model'
import { Link, useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { Client } from 'models/client.model'
import { Project } from 'models/project.model'
import { NewProject } from 'models/newProject.model'

interface CreateProjectModalProps {
  onClose: () => void
  refetch: () => void
}

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({ onClose, refetch }) => {
  const { data: clients } = useGetClientsQuery()
  const [createProject, { isLoading, isSuccess, isError }] = useCreateProjectMutation()
  const [projectData, setProjectData] = useState<NewProject>({ name: '' } as NewProject)
  const [showMessage, setShowMessage] = useState(true)
  const [selectedClient, setSelectedClient] = useState<Client | null>(null)
  const [isLinkDisabled, setIsLinkDisabled] = useState(true)

  useEffect(() => {
    // Check if all required fields are selected
    if (selectedClient) {
      setIsLinkDisabled(false)
    } else {
      setIsLinkDisabled(true)
    }
  }, [selectedClient])

  if (isSuccess || isError) {
    setTimeout(() => {
      setShowMessage(false)
    }, 1500)
  }
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      const updatedProjectData: NewProject = {
        ...projectData,
        clientId: selectedClient?.id || '',
      }
      const createdProject = await createProject(updatedProjectData).unwrap()
      onClose()
      refetch()
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setProjectData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleClientChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedClientId = e.target.value
    const selectedClient =
      clients?.find((client) => client.id.toString() === selectedClientId) || null
    setSelectedClient(selectedClient)
  }

  return (
    <Modal show={true} onHide={onClose} centered className="centered-modal">
      <Modal.Header>
        <Modal.Title>Create a new Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='p-1'>
          <div className='mb-1' style={{ display: 'flex', justifyContent: 'center' }}>
            <select
              style={{width:'100%', borderRadius:'5px'}}
              className='info-field bg-light-gray p-2 pl-3 mb-3'
              name='clientName'
              onChange={(e) => handleClientChange(e)}
              value={selectedClient?.id || ''}
              required
            >
              <option value='' disabled>
                Select a Client
              </option>
              {clients?.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
          </div>

          <div className='mb-1' style={{ display: 'flex', justifyContent: 'center' }}>
              <input
                  style={{width:'100%', borderRadius:'5px'}}
                  className='info-field bg-light-gray p-2 pl-3 mb-3'
                  type='text'
                  name='name'
                  placeholder='Project name'
                  onChange={handleChange}
                  required
              />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', borderRadius:'5px' }}>
            <button
              className='btn btn-primary'
              type='submit'
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? 'Creating...' : 'Create'}
            </button>
            <Link
              to='/projects'
              className='btn btn-secondary'
              style={{ marginLeft: 20, borderRadius:'5px' }}
              onClick={onClose}
            >
              Cancel
            </Link>
          </div>
          {isSuccess && showMessage && (
            <div className='alert alert-success mt-3' role='alert'>
              Project has been successfully created!
            </div>
          )}
          {isError && showMessage && (
            <div className='alert alert-danger mt-3' role='alert'>
              Error creating project. Please try again.
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CreateProjectModal
