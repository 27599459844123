import { useMsal } from '@azure/msal-react'

import OrganizationalChart from './components/OrgChart'
import { useGetClientsQuery, useGetProjectsQuery, useGetTeamsFilterQuery } from 'services/queries/api'
import { Entity } from './entity.model'
import { Team } from 'models/team.model'
import { Project } from 'models/project.model'
import { useEffect, useState } from 'react'

function OrgChart() {
  const { instance } = useMsal()

  const { data: clients } = useGetClientsQuery()
  const { data: projects } = useGetProjectsQuery()
  const { data: teams } = useGetTeamsFilterQuery({
    selectedClient: '',
    selectedProject: '',
  })

  const [renderChart, setRenderChart] = useState(false);

  useEffect(() => {
    // Add a delay (in ms) before rendering the chart
    const timeoutId = setTimeout(() => {
      setRenderChart(true);
    }, 750);

    // Cleanup the timeout when the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);

  // add root and load clients first
  const entitiesArray: Entity[] = [
    {
      id: '1',
      parentId: '',
      name: 'Klika',
      positionName: '',
      type: 'root'
    },
  ]

  clients?.forEach((client) => {
    const newEntity: Entity = {
      id: client.id.toString(),
      parentId: '1',
      name: client.name,
      positionName: '',
      type: 'client',
    }
    entitiesArray.push(newEntity)
  })

  // load projects
  entitiesArray.forEach((entity) => {
    if (entity.type === 'client') {
      const clientProjects: Project[] | undefined = projects?.filter(
        (project) => project.client.id.toString() === entity.id,
      )
      clientProjects?.forEach((clientProject) => {
        const newEntity: Entity = {
          id: clientProject.id.toString(),
          parentId: entity.id,
          name: clientProject.name,
          positionName: '',
          type: 'project',
        }
        entitiesArray.push(newEntity)
      })
    }
  })

  // load teams and team members
  entitiesArray.forEach((entity) => {
    if (entity.type === 'project') {
      const projectTeams: Team[] | undefined = teams?.filter(
        (team: { project: { id: { toString: () => string | undefined } } }) => team.project.id.toString() === entity.id,
      )
      projectTeams?.forEach((projectTeam) => {
        const newEntity: Entity = {
          id: projectTeam.id.toString(),
          parentId: entity.id,
          name: projectTeam.name,
          positionName: '',
          type: 'team',
        }
        entitiesArray.push(newEntity)

        projectTeam.assignments.forEach((assignment) => {
          const newEmployee: Entity = {
            id: assignment?.employeeId?.toString(),
            parentId: projectTeam.id,
            name: assignment.name,
            positionName: assignment.teamRole.name,
            type: 'employee',
          }
          entitiesArray.push(newEmployee)
        })
      })
    }
  })

  return (
    <>
      <h3 style={{ marginTop: '-2.5rem' }}>Teams topology</h3>
      {renderChart && <OrganizationalChart data={entitiesArray} />}
    </>
  )
}
export default OrgChart
