import React from 'react';
import {Employee} from 'models/employee.model'


import './ProfileCard.scss';
import {Link} from 'react-router-dom';
import {useGetBenchEmployeesQuery} from '../../../../services/queries/api';
import {useRenderComponentWithValidRole} from '../../../../hooks/useRenderComponentWithValidRole';
import {RoleEnum} from '../../../../enums/RoleEnum';
import {useSelector} from 'react-redux';
import {EmployeeProjectInfo} from '../../../../models/employeeProjectInfo';

export interface ProfileCardProps {
    profile: Employee;
    showEditButton: boolean
}

export function ProfileCard({ profile, showEditButton }: ProfileCardProps) {
    const roles = useSelector((state: any) => state.token.value)
    const {data:benchEmployees} = useGetBenchEmployeesQuery('')
    const benchEmployee = benchEmployees?.find((emp:EmployeeProjectInfo) => emp.id === profile.id)
    return(
        <div className="row">
            <div className="col-4">
                <img
                    className='img-account-profile'
                    src={profile?.photoUrl ? 'data:image/png;base64,' + profile.photoUrl : 'https://bootdey.com/img/Content/avatar/avatar7.png'}
                    alt=''
                />
            </div>
            <div className="col">
                <h3>{profile?.name}</h3>
                <p>{profile?.position}{profile?.division ? ', ' : ''} {profile?.department}</p>
                <p className='no-user-select'><i className="far fa-envelope"></i> <span>{profile?.companyEmail}</span> · <i
                    className="fas fa-phone"></i> <span>{profile?.personalPhoneNumber}</span> · <i
                    className="fab fa-slack"></i> <span>{profile?.getSlackHandle()}</span></p>
                <i>{profile.yearsOfEmploymentInKlika} years in Klika, member of {profile.assignments?.length} teams</i>
                <div className='mt-2'>
                    {useRenderComponentWithValidRole({
                        roles,
                        allowedRoles: [RoleEnum.Admin,RoleEnum.EEM],
                        children: (
                            benchEmployee!=null && <div><i className="fas fa-couch"></i> On bench </div>
                        ),
                    })}
                </div>
            </div>
            {showEditButton && (
                <Link to={`/employees/update/${profile?.id}`}>
                    <button className='btn btn-outline-primary btn-sm print-hidden' style={{position: 'relative'}}>
                        Edit Employee
                    </button>
                </Link>
            )}
        </div>
    )
}
