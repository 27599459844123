export enum RoleEnum {
  Admin = 'Admin',
  Marketing = 'Marketing',
  User = 'User',
  EEM = 'EEM',
}
export const roleOptions: { [key: number]: string } = {
  1: 'Admin',
  2: 'Marketing',
  3: 'EEM',
  4: 'User',
}
