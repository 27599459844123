import React, { FC, useRef, useState, useEffect } from 'react';
import { Employee } from '../../../models/employee.model';
import { UpdateTeamsOverview } from './UpdateTeamsOverview';
import { BsPlusSquare } from 'react-icons/bs';
import { Assignment, UpdateAssignment } from 'models/assignment.model';
import { MessageTypeEnum } from 'enums/MessageTypeEnum';
import ModalShowMessage from 'components/ModalShowMessage';
import AssignEmployeeToTeamForm from '../../../components/AssignEmployeeToTeamForm';
import { useDeleteAssignmentMutation, useGetEmployeeQuery, useGetTeamRolesQuery } from 'services/queries/api';
import { Action } from 'enums/ActionEnum';

interface EmployeeTeamsFormProps {
    employee: Employee
}


const EmployeeTeamsForm: FC<EmployeeTeamsFormProps> = ({ employee }) => {
    const { data: currentEmployee, refetch } = useGetEmployeeQuery(employee.id)
    const [showAssignmentModal, setShowAssignmentModal] = useState(false)
    const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false)
    const messageRef = useRef<string>()
    const messageTypeRef = useRef<MessageTypeEnum>(MessageTypeEnum.information)
    const [deleteAssignment] = useDeleteAssignmentMutation()
    const { data: teamRoles } = useGetTeamRolesQuery()
    const [action, setAction] = useState<Action>(Action.CREATE)
    const assignmentRef = useRef<Assignment>()

    const handleDelete = async () => {
        try {
            await deleteAssignment(assignmentRef.current).unwrap()
            refetch()
        }
        catch (error) {
            console.log(error)
        }
    }
    const callDelete = (deletedAssignment: Assignment) => {
        messageRef.current = 'Are you sure you want to unassign this employee?'
        assignmentRef.current = deletedAssignment
        messageTypeRef.current = MessageTypeEnum.question
        setIsMessageBoxOpen(true)
    }

    const closeMessageBox = () => {
        setIsMessageBoxOpen(false)
    }

    const handleEdit = async (assign: Assignment) => {
        setAction(Action.UPDATE)
        assignmentRef.current = assign
        setShowAssignmentModal(true)
    }

    return (
        <>
            {isMessageBoxOpen && (
                <ModalShowMessage
                    onClose={closeMessageBox}
                    showModal={isMessageBoxOpen}
                    message={messageRef.current}
                    onChange={handleDelete}
                    messageType={messageTypeRef.current}
                />
            )}
            <div>
                <button
                    className='btn btn-outline-primary btn-sm mr-2 float-right'
                    onClick={() => { setAction(Action.CREATE); setShowAssignmentModal(true) }}
                >
                    <BsPlusSquare /> Assign Employee
                </button>
                {showAssignmentModal && (
                    <AssignEmployeeToTeamForm
                        action={action}
                        onClose={() => { setShowAssignmentModal(false); refetch() }}
                        employeeId={employee.id}
                        showModal={showAssignmentModal}
                        teamRoles={teamRoles}
                        assignment={action == Action.CREATE ? { employeeId: employee.id } as UpdateAssignment :
                            { ...assignmentRef.current as Assignment, teamRoleId: assignmentRef.current?.teamRole.id }}
                    />
                )}
                {<div className='container'>
                    {currentEmployee.assignments &&
                        <UpdateTeamsOverview assignments={currentEmployee.assignments} handleDelete={callDelete} handleEditAssignment={handleEdit} />
                    }
                </div>}
            </div>
        </>
    )

}

export default EmployeeTeamsForm
