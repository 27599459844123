import React, { useState, useEffect, useRef } from 'react'
import { useGetTeamQuery, useUpdateTeamMutation, useGetProjectsQuery, useGetTeamRolesQuery, useDeleteAssignmentMutation } from 'services/queries/api'
import { useParams, useNavigate } from 'react-router-dom'
import { Assignment, UpdateAssignment } from 'models/assignment.model'
import { BsTrash, BsPencil, BsPlusSquare } from 'react-icons/bs'
import { Team } from 'models/team.model'
import ModalShowMessage from 'components/ModalShowMessage'
import { MessageTypeEnum } from 'enums/MessageTypeEnum'
import { displayTeamName } from 'utils/helpers'
import { Action } from 'enums/ActionEnum';
import AssignEmployeeToTeamForm from 'components/AssignEmployeeToTeamForm'
import UpdateTeamModal from './UpdateTeamModal'

const UpdateTeam: React.FC = () => {
  const { teamId } = useParams()
  const { data: team, refetch } = useGetTeamQuery(teamId ?? '')
  const { data: teamRoles } = useGetTeamRolesQuery()
  const [updateTeam, { isLoading, isSuccess, isError }] = useUpdateTeamMutation()
  const [teamData, setTeamData] = useState({ ...team })
  const [showMessage, setShowMessage] = useState(true)
  const assignmentRef = useRef<Assignment>()
  const navigate = useNavigate()
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false)
  const empIdAssignmentRef = useRef<string>('')
  const messageRef = useRef<string>()
  const messageTypeRef = useRef<MessageTypeEnum>(MessageTypeEnum.information)
  const [action, setAction] = useState<Action>(Action.CREATE)
  const [showAssignmentModal, setShowAssignmentModal] = useState(false)
  const [isUpdateTeamModalOpen, setIsUpdateTeamModalOpen] = useState(false);
  const { data: projects } = useGetProjectsQuery()
  const [deleteAssignment] = useDeleteAssignmentMutation()

  useEffect(() => {
    if (team) {
      const updatedData: Team = {
        ...team,
        project: team.project,
        projectId: team.project?.id,
        assignments: team.assignments,
      }
      setTeamData(updatedData)
    }
  }, [team])

  const handleChange = (e: any) => {
    const { name } = e.target

    const value = e.target.value
    setTeamData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleDelete = async (assignment: any) => {
    try {
      messageRef.current = 'Are you sure you want unassign selected employee?'
      empIdAssignmentRef.current = assignment.id
      assignmentRef.current = assignment
      messageTypeRef.current = MessageTypeEnum.question
      setIsMessageBoxOpen(true)
    } catch (error) {
      console.log(error)
    }
  }

  const callDelete = async () => {
    try {
      await deleteAssignment(assignmentRef.current).unwrap()
      refetch()
    } catch (error) {
      console.log(error)
    }
  }

  const closeMessageBox = () => {
    setIsMessageBoxOpen(false)
  }

  if (isSuccess || isError) {
    setTimeout(() => {
      setShowMessage(false)
    }, 1500)
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    setTeamData((prevData) => ({
      ...prevData,
    }))
    teamData.project = undefined

    try {
      await updateTeam(teamData).unwrap()
      setTimeout(() => {
        navigate('/teams')
      }, 1500)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      {isMessageBoxOpen && (
        <ModalShowMessage
          onClose={closeMessageBox}
          showModal={isMessageBoxOpen}
          message={messageRef.current}
          onChange={callDelete}
          messageType={messageTypeRef.current}
        />
      )}
      {showAssignmentModal && (
        <AssignEmployeeToTeamForm
          action={action}
          onClose={() => { setShowAssignmentModal(false); refetch() }}
          teamId={team?.id}
          showModal={showAssignmentModal}
          teamRoles={teamRoles}
          assignment={action == Action.CREATE ? { teamId: team?.id } as UpdateAssignment :
            { ...assignmentRef.current as Assignment, teamRoleId: assignmentRef.current?.teamRole.id }}
        />
      )}
      <form onSubmit={handleSubmit}>
        <div>
          <div className='container'>
            <h4>{displayTeamName(team?.project?.client?.name, team?.project?.name, team?.name)} overview</h4>
            <div className='col-xl-15'>
              <br />
              <div className='card mb-4'>
                <div className='card-body'>
                  <div className='mb-2'>
                    <button
                      className='btn btn-outline-primary ml-2 float-left'
                      type='button'
                      onClick={() => { setAction(Action.CREATE); setShowAssignmentModal(true) }}
                    >
                      Assign employee
                    </button>

                    <button
                      className='btn btn-outline-primary ml-2 float-left'
                      type='button'
                      onClick={() => setIsUpdateTeamModalOpen(true)}
                    >
                      Edit team
                    </button>
                    {isUpdateTeamModalOpen && (
                      <UpdateTeamModal
                        team={team}
                        projects={projects}
                        isOpen={isUpdateTeamModalOpen}
                        onClose={() => setIsUpdateTeamModalOpen(false)}
                      />
                    )}

                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-12 mb-4'>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='card-title mt-3'>
                        <h6 className='text-center'>
                          Team Members
                        </h6>
                      </div>
                      <div style={{ overflowY: 'auto' }}>
                        <table className='table table-striped table-hover custom-table'>
                          <thead>
                            <tr>
                              <th scope='col'>Employee name</th>
                              <th scope='col'>Team role</th>
                              <th scope='col'>Team member since</th>
                              <th scope='col'></th>
                            </tr>
                          </thead>
                          <tbody>
                            {teamData?.assignments
                              ?.filter((a) => a.isDeleted == false)
                              ?.map((asgn) => (
                                <tr key={asgn?.employeeId}>
                                  <td>{asgn?.name}</td>
                                  <td>{asgn?.teamRole.name}</td>
                                  <td>
                                    {asgn?.startDate
                                      ? new Date(asgn?.startDate).toLocaleDateString()
                                      : ''}
                                  </td>
                                  <td className='d-flex justify-content-end'>
                                    <button
                                      className='btn btn-outline-primary mr-2'
                                      type='button'
                                      onClick={() => {
                                        setAction(Action.UPDATE);
                                        assignmentRef.current = asgn;
                                        setShowAssignmentModal(true)
                                      }}
                                    >
                                      <BsPencil />
                                    </button>
                                    <button
                                      className='btn btn-outline-danger'
                                      type='button'
                                      onClick={() => handleDelete(asgn)}
                                    >
                                      <BsTrash />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default UpdateTeam
