import React, { FC, useState } from 'react'
import PersonalInformationForm from './components/PersonalInformationForm'
import {
  useGetEmployeeQuery,
  useUpdateEmployeeNewMutation,
  useGetEmployeeDropdownDataQuery,
  useUpdateEmployeeEmpAccountMutation,
  useUpdateEmploymentDataMutation,
} from 'services/queries/api'
import { useParams } from 'react-router-dom'
import Loader from 'components/Loader'
import { Tabs, Tab } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import EmploymentDetailsForm from './components/EmploymentDetailsForm'
import EmpAccountForm from './components/EmpAccountForm'
import TeamsForm from './components/TeamsForm';
import {ProfileImage} from '../../models/profileImage.model';
import {useUpdateEmployeeProfileImageMutation} from '../../services/queries/apiFormData';

enum TabKeys {
  PersonalInformation = '0',
  EmploymentDetails = '1',
  Teams = '2',
  EmpAccount = '3',
}
const UpdateEmployee: FC = () => {
  const { employeeId } = useParams()
  const { data: employee } = useGetEmployeeQuery(employeeId)
  const { data: dropdownData } = useGetEmployeeDropdownDataQuery()
  const [key, setKey] = useState(TabKeys.PersonalInformation)
  const [updateEmployee] = useUpdateEmployeeNewMutation()
  const [updateEmpAccount] = useUpdateEmployeeEmpAccountMutation()
  const [updateEmployment] = useUpdateEmploymentDataMutation()
  const [updateEmployeeProfileImage] = useUpdateEmployeeProfileImageMutation()

  const updateEmployeeData = async (data: any) => {
    try {
      switch (key) {
        case TabKeys.PersonalInformation:
          if(data.profileImage) {
            const image= new ProfileImage()
            image.azureId = data.externalUserId
            image.image = data.profileImage
            await updateEmployeeProfileImage(image);
          }
          await updateEmployee(data)
          break
        case TabKeys.EmploymentDetails:
          await updateEmployment(data)
          break
        case TabKeys.EmpAccount:
          await updateEmpAccount(data)
          break
        default:
          throw new Error('Invalid tab key')
      }
      toast.success('Your changes have been successfully updated!')
    } catch (error) {
      toast.error('Save error')
    }
  }
  if (!employee) {
    return <Loader />
  }
  return (
    <div className='container-fluid'>
      <div className='row justify-left-left mt-4 mb-5 ml-0'>
        <h3>
          {' '}
          <i className='fa fa-user mr-2'></i>
          Update employee
        </h3>
      </div>
      <Tabs
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k: any) => setKey(k)}
        className='mb-3'
      >
        <Tab eventKey={TabKeys.PersonalInformation} title='Personal Information'>
          <PersonalInformationForm
            employee={employee}
            onSubmit={updateEmployeeData}
            dropdownData={dropdownData?.personalData}
          />
        </Tab>
        <Tab eventKey={TabKeys.EmploymentDetails} title='Employment details'>
          <EmploymentDetailsForm
            employee={employee}
            onSubmit={updateEmployeeData}
            dropdownData={dropdownData?.employmentData}
          />
        </Tab>
        <Tab eventKey={TabKeys.Teams} title='Teams'>
          <TeamsForm
            employee={employee}
          />
        </Tab>
        <Tab eventKey={TabKeys.EmpAccount} title='EMP account'>
          <EmpAccountForm employee={employee} onSubmit={updateEmployeeData} roles={dropdownData?.roles} />
        </Tab>
      </Tabs>
      <ToastContainer autoClose={2000} className='mt-5' />
    </div>
  )
}
export default UpdateEmployee
