import { Project } from 'models/project.model';
import { Team } from 'models/team.model';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useUpdateTeamMutation } from 'services/queries/api';

const UpdateTeamModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    team: Team | undefined;
    projects: Project[] | undefined;
}> = ({ isOpen, onClose, team, projects }) => {
    const [formData, setFormData] = useState({ ...team });

    const [updateTeam] = useUpdateTeamMutation();

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleProjectSelect = (projectId: string) => {
        const selectedProject = projects?.find((project) => project.id === projectId);
        setFormData((prevData) => ({
            ...prevData,
            project: selectedProject,
        }));
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setFormData((prevData) => ({
            ...prevData,
        }));
        formData.project = undefined;

        try {
            await updateTeam(formData).unwrap();
            toast.success('Your changes have been successfully updated!')
            onClose()
        } catch (error) {
            console.log(error);
            toast.error('Edit error')
        }
    };

    return (
        <Modal show={isOpen} onHide={onClose} style={{ justifyContent: 'center', marginTop: '200px' }}>
            <Modal.Header>
                <Modal.Title>Edit team</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='container'>
                    <div className='row mb-3'>
                        <div className='col'>
                            <label className='small mb-1'>
                                Name: <span className='text-danger'>*</span>
                            </label>
                            <input
                                name='name'
                                className='info-field bg-light-gray p-2 pl-3 w-100'
                                type='text'
                                defaultValue={team?.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className='col'>
                            <label className='small mb-1'>
                                Project: <span className='text-danger'>*</span>
                            </label>
                            <select
                                className='form-select form-hub-select bg-light-gray p-2 pl-3 w-100'
                                name='projectId'
                                value={formData.project?.id || ''}
                                onChange={(e) => {
                                    handleChange(e);
                                    handleProjectSelect(e.target.value);
                                }}
                                required
                            >
                                {projects &&
                                    projects?.map((project) => (
                                        <option key={project?.id} value={project?.id}>
                                            {project?.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex justify-content-end mr-3'>
                    <div className='mr-4'>
                        <Button
                            type='button'
                            color='outline-secondary'
                            onClick={onClose}
                        >
                            Close
                        </Button>
                    </div>
                    <Button
                        type='submit'
                        color='outline-primary'
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </div>
            </Modal.Footer>
            <ToastContainer autoClose={2000} className='mt-5' />
        </Modal >
    )
};
export default UpdateTeamModal
