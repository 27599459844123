export function formatTShirtSize(size: number): string {
  const sizes = [
    'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'
  ]
  return sizes[size]
}

export function formatShoeSize(size: number): string {
  const sizes = [
    '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45','46','47','48'
  ]
  return sizes[size]
}

export function formatGender(gender: number): string {
  const genders = [
    'Male', 'Female'
  ]
  return genders[gender]
}

export function formatDate(date: string): string {
  return date.split('T')[0].split('-').reverse().join('.')
}

export function formatEmploymentType(type: number): string {
  const types = [
    'Full time employee', 'Part time employee', 'Consultant'
  ]
  return types[type]
}

export function formatAddress(street: string, city: string, country: string) {
  const address = `${street}\n${city}\n${country}`;
  return address
}

export function formatEmergencyContact(name: string, relation: string, phone: string) {
  const contactNameRelation = name + ', ' + relation
  const contact = `${contactNameRelation}\n${phone}`;
  return contact
}
