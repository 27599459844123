export class EmployeeChild {
  id?: string | undefined
  employeeId!: string | undefined
  birthYear!: number
  sex!: string

  rules!: {
    viewDetails: boolean
    cud: boolean
  }

  constructor(employeeChild?: any) {
    if (employeeChild) {
      Object.assign(this, employeeChild)
    }
  }
}
