import { Outlet } from 'react-router-dom'

const Content: React.FC = () => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-xl-12'>
          <div className='card shadow mb-2 content-card'>
            <div className='card-body content'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content
