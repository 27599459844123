import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import React from 'react';

const filter = createFilterOptions<DropdownOption>();


interface DropdownOption {
  inputValue?: string;
  label: string;
  id?: string;
  object?: any;
}

interface AutocompleteInputProps {
  items: DropdownOption[];
  selectedItem: DropdownOption | null;
  onItemSelect: React.Dispatch<React.SetStateAction<DropdownOption | null>>;
  inputFieldName: string;
  id: string;
}

export default function AutocompleteInput({ items, selectedItem, onItemSelect, inputFieldName, id }: AutocompleteInputProps) {
  return (
    <Autocomplete
      value={selectedItem}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          onItemSelect({
            label: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          onItemSelect({
            label: newValue.inputValue,
          });
        } else {
          onItemSelect(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option.label);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: `Create ${inputFieldName.toLowerCase()} ${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      aria-required
      clearOnBlur
      size='small'
      handleHomeEndKeys
      id={id}
      options={items}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      sx={{ width: 450 }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label={inputFieldName} />
      )}
    />
  );
}