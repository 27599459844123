import { EmployeeChild } from './employeeChild.model'
import { Assignment } from './assignment.model'

export class Employee {
  //
  // Deprecated model
  //
  id!: string
  fullName!: string
  surname!: string
  sexId!: number
  phoneNumber?: string
  email?: string
  division?: string
  startDate!: string
  endDate?: string
  emergencyContact!: string
  birthDate!: string
  dateOfBirth?: string
  qualifications?: string
  positionId!: number
  addressOfPermanentResidence!: string
  cityOfPermanentResidence!: string
  addressOfActualResidence!: string
  cityOfActualResidence!: string
  previousContractDate?: string
  previousContractNumber?: string
  currentContractDate?: string
  currentContractNumber?: string
  contractType?: string
  probationPeriodDate?: string
  yearsOfEmploymentInKlika?: number
  currentPositionInCompanyId!: number
  previousPositionInCompanyId?: number
  departmentActualId!: string
  divisionActualId!: string
  typeOfPosition?: number
  managerExternalUserId?: string
  employeeExperienceManagerId?: string
  projects!: string[]
  previousProject?: string
  lastFeedbackDate?: string
  upcomingFeedbackDate?: string
  sizeOfShirt?: number
  sizeOfShoe?: number
  hasKids?: boolean
  externalUserId?: string
  photoUrl?: string
  department!: string
  position!: string
  roles?: number
  //
  // Current model
  //
  companyEmail?: string
  tShirtSize?: number
  shoesSize?: number
  gender?: number
  personalPhoneNumber?: string
  personalEmailAddress?: string
  klikaOffice!: string
  klikaOfficeId!: number
  firstName!: string
  lastName!: string

  employmentType = 0
  employeeHireDate?: string
  assignments?: Assignment[]

  jmbg!: string
  nameOfOneParent!: string
  legalResidenceAddressName!: string
  legalResidenceAddressCity!: string
  legalResidenceAddressCountry!: string
  currentAddressName!: string
  currentAddressCity!: string
  currentAddressCountry!: string
  emergencyContactFullName!: string
  emergencyContactRelationToEmployee!: string
  emergencyContactPhoneNumber!: string
  levelOfEducation!: string

  employeeChildren?: EmployeeChild[]
  rules!: {
    viewDetails: boolean
    cud: boolean
  }


  constructor(employee?: any) {
    if (employee) {
      Object.assign(this, employee)
      this.employeeChildren = (employee.employeeChildren || []).map(
        (child: any) => new EmployeeChild(child),
      )
    } else {
      this.employeeChildren = []
    }
  }

  getSlackHandle(): string {
    return `@${this.companyEmail?.split('@')[0] || ''}`
  }

  get name() {
    return `${this.firstName} ${this.lastName}`
  }

}


export function getFullName(firstName?: string, lastName?: string) {
  return firstName + ' ' + lastName;
}
