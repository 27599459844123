import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


export const formDataApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        credentials: 'include',
        prepareHeaders: async (headers: any) => {
            // headers.set('encType', 'multipart/form-data')
            return headers
        },
    }),
    tagTypes: ['ProfileImage'],
    endpoints: (builder) => ({
        updateEmployeeProfileImage: builder.mutation<any, Partial<any>>({
            query: (profileImage:any) => {
                const bodyFormData = new FormData()
                bodyFormData.append('profileImage', profileImage.image)
                bodyFormData.append('azureId', profileImage.azureId)
                return { url: 'api/employees/photo',
                    method: 'POST',
                    formData:true,
                    body: bodyFormData }
            },
            invalidatesTags: ['ProfileImage'],
        })

    })})

export const {
    useUpdateEmployeeProfileImageMutation
} = formDataApi
