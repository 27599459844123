import { PublicClientApplication } from '@azure/msal-browser'
import { loginApiRequest, msalConfig } from 'config/authConfig'
import moment from 'moment'

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
  return JSON.parse(jsonPayload)
}

export const scheduleLogout = async () => {
  const msalInstance = new PublicClientApplication(msalConfig)

  try {
    const accounts = msalInstance.getAllAccounts()
    const account = accounts[0]
    const msalResponse = await msalInstance.acquireTokenSilent({
      ...loginApiRequest,
      account: account,
      tokenQueryParameters: {
        cacheLookup: 'AccessToken',
      },
      forceRefresh: false,
    })

    const token = msalResponse.accessToken
    const decodedToken = parseJwt(token)
    const tokenExpiresAt = decodedToken.exp
    const currentTimestamp = Date.now() / 1000 // Convert to seconds

    // Schedule the function call 3 seconds before token expiration
    const timeRemaining = tokenExpiresAt - currentTimestamp
    setTimeout(() => {
      window.location.href = '/'
      localStorage.clear()
    }, (timeRemaining - 1) * 1000) // Convert to milliseconds
  } catch (error) {
    console.error('Error acquiring access token:', error)
  }
}

export const displayDate = (date: string | null) => {
  return date ? moment(date).format('DD/MM/YYYY') : ''
}

export const displayTeamName = (client: string | undefined, project: string | undefined, team: string | undefined) => {
  if (client == project && client == team)
    return `${client}`
  if (client == project || project == team)
    return `${client} / ${team}`
  else
    return `${client} / ${project} / ${team}`
}

export const isEndDateValid = (startDate: Date, endDate: Date) => {
  if (startDate == undefined || endDate == undefined) {
    return true;
  }
  return moment(startDate) < moment(endDate);
};
