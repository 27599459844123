/* eslint-disable react/prop-types */
import FilterComponent from 'components/filters/FilterComponent'
import { DropdownItem, FilterValues, PropertyConfig, PropertyFilter } from 'interfaces/interfaces'
import { useEffect } from 'react'
import { useGetEmployeeDropdownDataQuery, useGetClientsQuery, useLazyGetEmployeesNameQuery, useGetEEMNamesAndExternalIdsQuery } from 'services/queries/api'
interface ComponentProps {
  propertyFilter: PropertyFilter
  setPropertyFilter: React.Dispatch<React.SetStateAction<PropertyFilter>>
  onApplyFilterRefetch: () => void
}

const EmployeesFilter: React.FC<ComponentProps> = ({
  propertyFilter,
  setPropertyFilter,
  onApplyFilterRefetch,
}) => {
  const { data: dropDownData } = useGetEmployeeDropdownDataQuery()
  const { data: clients } = useGetClientsQuery()
  const [getEmployeesName, { data: employeesName }] = useLazyGetEmployeesNameQuery()
  const { data: eems } = useGetEEMNamesAndExternalIdsQuery()

  useEffect(() => {
    if (!employeesName || employeesName.length === 0) {
      getEmployeesName()
    }
  }, [getEmployeesName, employeesName])

  const employeeOptions = (employeesName || []).map((employee: any) => ({
    label: `${employee.firstName} ${employee.lastName}`,
    value: employee.id,
  })) as DropdownItem[]

  const eemsOptions = (eems || []).map((employee: any) => ({
    label: `${employee.firstName} ${employee.lastName}`,
    value: employee.externalUserId
  })) as DropdownItem[]

  const properties: PropertyConfig[] = [
    {
      propertyLabel: 'Full Name',
      name: 'FullName',
      useTextField: true,
      isAdvanced: false,
      isVisible: true
    },
    {
      propertyLabel: 'Klika office',
      name: 'KlikaOfficeId',
      useDropdown: true,
      dropdownOptions: dropDownData?.employmentData?.klikaOffices as DropdownItem[],
      isAdvanced: false,
      isVisible: true,
    },
    {
      propertyLabel: 'Department',
      name: 'DepartmentId',
      useDropdown: true,
      dropdownOptions: dropDownData?.employmentData.departments as DropdownItem[],
      isAdvanced: false,
      isVisible: true,
    },
    {
      propertyLabel: 'Client',
      name: 'Clients',
      useDropdown: true,
      dropdownOptions: clients?.map((client) => {
        const dropdowns: DropdownItem = {
          value: client.id,
          label: client.name,
        };
        return dropdowns;
      }),
      isAdvanced: false,
      isVisible: true,
    },

    {
      propertyLabel: 'Employment date from',
      name: 'EmployeeHireDateFrom',
      useDatePicker: true,
      isAdvanced: true,
      isVisible: false,
    },
    {
      propertyLabel: 'Employment date to',
      name: 'EmployeeHireDateTo',
      useDatePicker: true,
      isAdvanced: true,
      isVisible: false,
    },
    {
      propertyLabel: 'Manager',
      name: 'ManagerExternalUserId',
      useDropdown: true,
      dropdownOptions: employeeOptions,
      isAdvanced: true,
      isVisible: false,
    },
    {
      propertyLabel: 'EEM',
      name: 'EmployeeExperienceManagerId',
      useDropdown: true,
      dropdownOptions: eemsOptions,
      isAdvanced: true,
      isVisible: false,
    },
    {
      propertyLabel: 'Edit employee done',
      name: 'IsEmployeeEditDone',
      useDropdown: true,
      dropdownOptions: [{ value: true, label: 'Yes' }, { value: false, label: 'No' }] as DropdownItem[],
      isAdvanced: true,
      isVisible: false,
    },

  ]

  const handlePropertyFilterChange = (filter: FilterValues) => {
    setPropertyFilter({ ...propertyFilter, filter: filter })
    onApplyFilterRefetch()
  }

  return (
    <>
      <div className='d-flex flex-column'>
        <FilterComponent properties={properties} onApplyFilter={handlePropertyFilterChange} />
      </div>
    </>
  )
}

export default EmployeesFilter